import { Select } from "antd";
import { forwardRef, useState, useImperativeHandle, useMemo } from "react";

const { Option } = Select;

type Role = {
  id: number;
  name: string;
  type_id: number;
};

const ProjectCompanyRoleSelectCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, value, context } = props;
  const { companyTypes = [] } = context;
  const [selectValue, setSelectValue] = useState(value);

  const companyRoles = useMemo(() => {
    return companyTypes?.user_types;
  }, [companyTypes]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });

  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      // notFoundContent={loading ? "" : notFoundContent}
      onChange={(id) => {
        setSelectValue(() => id);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
      loading={!companyRoles}
    >
      {companyRoles &&
        companyRoles?.map((role: Role) => (
          <Option key={role.id} value={role.id}>
            {role.name}
          </Option>
        ))}
    </Select>
  );
});

export default ProjectCompanyRoleSelectCellEditor;
