import {
  DocumentNode,
  gql,
  QueryHookOptions,
  useMutation,
  useQuery,
  useSubscription,
  WatchQueryFetchPolicy
} from "@apollo/client";
import { GRAPHQL_API_URL } from "config/config";
import { ProjectContext } from "context/ProjectProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CIQClient, { HTTPClientResponse } from "services/ciq-client";
import ErrorManager from "utils/cumulative-error-message/error-manager";
import { getGQL } from "utils/utils";

export const useCIQMutation = (
  mutationDef: any,
  config: any = {}
): [
  (payload: { variables: any }, options?: any) => Promise<any>,
  { data: any; loading: boolean; error: any }
] => {
  const [mutateFunction, { data, loading, error }] = useMutation(
    mutationDef,
    config
  );

  const customMutateFunc: any = async (
    payload: { variables: any },
    options?: any
  ) => {
    const { showError = true } = options || {};
    let response: any = null;
    try {
      response = await mutateFunction(payload);

      if (response?.errors) {
        const errorMsg = ErrorManager().handleError(
          response?.errors[0],
          showError
        );

        return {
          success: false,
          error: response?.errors[0],
          errorMsg,
          response
        };
      }

      return {
        success: true,
        data: response.data,
        response
      };
    } catch (ex: any) {
      console.log("useCIQMutation error: ", ex);
      const errorMsg = ErrorManager().handleError(ex);

      ErrorManager().handleError(error);

      return {
        success: false,
        error: ex,
        errorMsg,
        response
      };
    }
  };
  return [customMutateFunc, { data, loading, error }];
};

export const useCIQQuery = <TData = any>(
  queryDef: DocumentNode | string,
  params: QueryHookOptions<TData, any> = {}
): {
  data: TData | undefined;
  error: any;
  loading: any;
  refetch: any;
} => {
  const options = {
    skip: false,
    fetchPolicy: "network-only" as WatchQueryFetchPolicy,
    ...params
  };

  const query =
    typeof queryDef === "string" ? gql(`query ${queryDef}`) : queryDef;

  const { data, error, loading, refetch } = useQuery(query, options);

  useEffect(() => {
    if (!error) return;
    ErrorManager().handleError(error);
  }, [error]);

  return {
    data,
    error,
    loading,
    refetch
  };
};

export const useCIQSubscription = <TData = any>(
  queryDef: any,
  params?: {
    client?: any;
    variables?: any;
    skip?: boolean;
  }
): {
  data: TData;
  error: any;
  loading: any;
} => {
  const { client, variables, skip = false } = params || {};

  const { data, error, loading } = useSubscription(queryDef, {
    variables,
    client,
    skip
  });

  useEffect(() => {
    if (!error) return;
    console.log("useCIQSubscription error: ", error);
    ErrorManager().handleError(error);
  }, [error]);

  return {
    data,
    error,
    loading
  };
};

export const useCIQQuerySubscription = <TData = any>(
  GQLQueryString: any,
  params: {
    client?: any;
    variables?: any;
    skip?: boolean;
  }
): {
  data: TData;
  error: any;
  loading: any;
} => {
  const { query: queryDef, sub: subDef } = getGQL(GQLQueryString);
  const { client, variables, skip = false } = params;

  const { data, error, loading, subscribeToMore } = useQuery(queryDef, {
    fetchPolicy: "cache-and-network",
    variables,
    client,
    skip
  });

  useEffect(() => {
    if (!data) return () => {};
    subscribeToMore({
      document: subDef,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return { ...subscriptionData.data };
      }
    });
    return () => {};
  }, [data, subDef, subscribeToMore, variables]);

  useEffect(() => {
    if (!error) return;
    console.log("useCIQQuerySubscription error: ", error);
    ErrorManager().handleError(error);
  }, [error]);

  return {
    data,
    error,
    loading
  };
};

export const useCIQLazyQuery = (
  GQLQuery: any,
  params?: {
    token?: string;
  }
): [(variables?: any) => Promise<HTTPClientResponse>, { loading: boolean }] => {
  const { token: paramToken } = params || {};

  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = paramToken || tokenRetrievalState?.token;
  const query = GQLQuery.loc.source.body;

  const [loading, setLoading] = useState(false);

  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }, [token]);

  const lazyQuery = useCallback(
    async (variables?: any) => {
      setLoading(true);
      const request = CIQClient.post({
        url: GRAPHQL_API_URL,
        data: { query, variables: variables || {} },
        config
      });
      request.finally(() => {
        setLoading(false);
      });
      return request;
    },
    [config, query]
  );

  return [lazyQuery, { loading }];
};
