import { IconSearch } from "@tabler/icons-react";
import { ColDef, GridOptions, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Input } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { DateFilter, DateUtils } from "utils/dateutils";
import {
  UpcomingMaterialReportDetailsCellRenderer,
  dueDateCellRenderer,
  gcRepresentativeCellRenderer,
  gcRepresentativeCellRendererWithCustomEdit,
  materialDueDateHeaderComponent,
  riskCellRenderer,
  specSectionRenderer
} from "components/cell-renders";
import { useCiqMaterialRiskReportList } from "pages/risk-report/hooks/risk-report";
import { QUERY_MATERIAL_RISK_REPORT } from "services/graphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { downloadStringAsCSVFile } from "utils/utils";
import { InnerIdLinkMaterialRenderer } from "../components/cellRenderer";
import { getMaterialFormattedValues } from "./export-csv-util";

type ReportFilterType = {
  filter: number;
  groupby: string;
  sortBy: string;
};

function UpcomingMaterialsComponent(props: any) {
  const {
    reportFilter,
    setOpenFilterForm,
    projectDetails,
    projectParticipants
  } = props;

  const {
    gqlClientForProject,
    columnHeaders: { materialHeaderMap },
    eventLogs
  } = useContext(ProjectContext);

  const gridRef = useRef<AgGridReact<any>>(null);

  const [gridSearchtext, setGridSearchtext] = useState("");
  const [numberOfMaterials, setNumberOfMaterials] = useState<number>(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [isGridReady, setGridReady] = useState(false);
  const [expandedRowCount, setExpandedRowCount] = useState(0);

  const [masterData, setMasterData] =
    useState<Array<{ name: string; id: string; callRecords: Array<any> }>>();

  const [gridData, setGridData] = useState<{
    materials?: any;
  }>({
    materials: null
  });

  const { data: materialRiskData } = useCiqMaterialRiskReportList({
    gqlClientForProject,
    variables: {
      where: {}
    }
  });

  const [getLazyUpcomingMaterialData] = useLazyQuery(
    QUERY_MATERIAL_RISK_REPORT,
    {
      client: gqlClientForProject,
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (materialRiskData) {
      setGridData({
        materials: materialRiskData
          ? materialRiskData?.material_cumulative_risk_report_func
          : []
      });
    }
  }, [materialRiskData]);

  const deleteMaterialsFromList = useCallback(
    (ids: Array<string>) => {
      const items = gridData.materials ? [...gridData.materials] : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setGridData({
        materials: items
      });
    },
    [gridData.materials]
  );

  const getAndUpdateMaterialList = useCallback(
    async (materialIds: Array<string>) => {
      if (!materialIds.length) return;
      const variables = { where: { id: { _in: materialIds } } };

      const res = await getLazyUpcomingMaterialData({
        variables,
        fetchPolicy: "network-only"
      });
      if (res.data) {
        const updatedmaterialList =
          res.data.material_cumulative_risk_report_func;

        const items = [...gridData.materials] as Array<any>;
        updatedmaterialList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setGridData({
          materials: items
        });
      }
    },
    [getLazyUpcomingMaterialData, gridData.materials]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const conditionFormaterialRiskLog = (log: TAuditChangeLog) =>
        // log.info.computation === true &&
        log?.info?.material_ids && log?.info?.material_ids?.length > 0;

      if (eventLogs.some(conditionFormaterialRiskLog)) {
        const auditLogs = eventLogs.filter(
          (log: TAuditChangeLog) => log.info.material_ids
        ) as Array<TAuditChangeLog>;

        const deletedIds = auditLogs
          .filter(
            (log) =>
              log.change_type === "delete" && log.data_source === "materials"
          )
          .map((s: TAuditChangeLog) => s.info.material_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteMaterialsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter(
            (log) =>
              log.change_type === "update" &&
              (log.data_source === "date_block" ||
                log.data_source === "material")
          ) // update create import
          .map((s) => s.info.material_ids) as any;

        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateMaterialList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    eventLogs,
    deleteMaterialsFromList,
    getAndUpdateMaterialList,
    gridData.materials
  ]);

  const expandCollapseAll = useCallback(() => {
    const isExpand = expandedRowCount !== totalNumberOfRows; // tmpExpandedItemIds.length > 0;

    gridRef.current!.api.forEachNode((node) => {
      node.setExpanded(isExpand);
    });

    setTimeout(() => {
      if (isExpand)
        gridRef.current!.api.forEachDetailGridInfo((gridInfo) => {
          gridInfo.api?.forEachNode((node) => {
            node.setExpanded(isExpand);
          });
        });
    }, 100);
    if (isExpand) setExpandedRowCount(totalNumberOfRows);
    else setExpandedRowCount(0);
    gridRef.current!.api.onGroupExpandedOrCollapsed();
  }, [expandedRowCount, totalNumberOfRows]);

  const onUpdateExpandedRow = () => {
    let count = 0;
    gridRef.current?.api?.forEachNode((node) => {
      if (node.expanded) count = 1 + count;
    });
    gridRef.current?.api?.forEachDetailGridInfo((gridInfo) => {
      gridInfo.api?.forEachNode((node) => {
        if (node.expanded) count = 1 + count;
      });
    });
    setExpandedRowCount(count);
  };

  const updateGridView = (e: ReportFilterType, list: Array<any>) => {
    const dateForFilter = DateUtils.dateTimeObj(e.filter);

    const masterD: Array<{
      name: string;
      id: string;
      callRecords: Array<any>;
    }> = [];

    const groupByItem: any =
      e.groupby === "GroupBy_GCR"
        ? {
            id: "gc_representative",
            firstName: "gc_representative_first_name",
            lastName: "gc_representative_last_name"
          }
        : {
            name: "trade_partner_name"
          };
    let materialCount = 0;
    list?.forEach((data: any) => {
      if (
        data.date_block_materials[0]?.due_date &&
        DateUtils.dateTimeObj(data.date_block_materials[0]?.due_date).diff(
          dateForFilter,
          "days"
        ) <= 0
      ) {
        materialCount += 1;
        const id = data[groupByItem.id]
          ? data[groupByItem.id]
          : data[groupByItem.name];

        const d = masterD.find((x) => x.id === id);
        if (d) {
          d.callRecords.push(data);
        } else {
          let name = data[groupByItem.name];
          if (!name) {
            const unknown =
              e.groupby === "GroupBy_RC"
                ? "Unassigned Responsible Contractor"
                : "Unassigned GC Representative";
            name = data[groupByItem.firstName]
              ? `${data[groupByItem.firstName]} ${data[groupByItem.lastName]}`
              : unknown;
          }

          masterD.push({
            name,
            id,
            callRecords: [data]
          });
        }
      }
    });
    setNumberOfMaterials(materialCount);
    setMasterData(masterD.sort((a, b) => a.name.localeCompare(b.name)));
    setTotalNumberOfRows(materialCount + masterD.length);
  };

  const gridContext: any = useMemo(() => {
    return {
      projectDetails,
      materialHeaderMap,
      projectParticipants
    };
  }, [projectDetails, materialHeaderMap, projectParticipants]);

  const detailCellRenderer = useMemo(() => {
    return UpcomingMaterialReportDetailsCellRenderer;
  }, []);

  const defaultColDef: {} = useMemo(() => {
    return {
      menuTabs: [],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: true,
      flex: 1
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "name",
        cellRenderer: "agGroupCellRenderer",
        valueGetter: ({ data }) => `${data.name} (${data.callRecords.length})`
      }
    ],
    []
  );

  const detailCellRendererParams = useMemo<{
    detailGridOptions: GridOptions;
  }>(() => {
    return {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        context: gridContext,
        masterDetail: true,
        detailCellRenderer,
        detailRowAutoHeight: true,
        rowHeight: 50,
        defaultColDef: {
          sortable: true,
          filter: "agSetColumnFilter",
          menuTabs: ["filterMenuTab"],
          resizable: true
        },
        onGridReady(gridApi) {
          if (!reportFilter) return;
          gridApi.columnApi.applyColumnState({
            state: [
              {
                colId: reportFilter.sortBy,
                sort: "asc"
              }
            ]
          });
          gridApi.api?.sizeColumnsToFit();
        },
        onRowGroupOpened() {
          onUpdateExpandedRow();
        },
        columnDefs: [
          {
            colId: "material_id",
            field: "material_id",
            headerName: materialHeaderMap?.material_id?.toUpperCase() || "",
            headerTooltip: materialHeaderMap?.material_id?.toUpperCase() || "",
            width: 150,
            minWidth: 80,
            maxWidth: 200,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              innerRenderer: InnerIdLinkMaterialRenderer
            }
          },
          {
            colId: "custom_spec_section",
            field: "custom_spec_section",
            headerName: materialHeaderMap.spec_section_no?.toUpperCase() || "",
            headerTooltip:
              materialHeaderMap.spec_section_no?.toUpperCase() || "",
            filter: "agSetColumnFilter",
            suppressMenu: false,
            menuTabs: ["filterMenuTab"],
            sortable: true,
            filterParams: {
              valueFormatter: (params: ValueFormatterParams) => {
                try {
                  return params.value.split("-")[0].trim();
                } catch {
                  return params.value;
                }
              }
            },
            cellRenderer: specSectionRenderer,
            cellRendererParams: {
              specNoField: "spec_section_no",
              specNameField: "spec_section_name"
            },
            valueGetter: (params: any) => {
              try {
                let specSectionStr = params.data.spec_section_no || "";
                if (params.data.spec_section_name) {
                  specSectionStr += ` - ${params.data.spec_section_name}`;
                }
                return specSectionStr;
              } catch (ex) {
                return "";
              }
            },
            comparator: (valueA: string | null, valueB: string | null) => {
              if (!valueA) return 1;
              if (!valueB) return -1;
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            },
            tooltipValueGetter: (params) => params.value
          },
          {
            field: "name",
            colId: "name",
            headerName: materialHeaderMap?.name?.toUpperCase() || "",
            headerTooltip: materialHeaderMap?.name?.toUpperCase() || "",
            tooltipField: "name"
          },
          {
            field: "material_tag",
            colId: "material_tag",
            tooltipField: "material_tag",
            headerName: materialHeaderMap?.tag_name?.toUpperCase() || "",
            headerTooltip: materialHeaderMap?.tag_name?.toUpperCase() || ""
          },
          {
            field: "status",
            colId: "status",
            valueGetter: ({ data }) => {
              if (
                data?.date_block_materials?.length > 0 &&
                data?.date_block_materials[0]?.milestone_state
              ) {
                return data?.date_block_materials[0]?.milestone_state || "";
              }
              return "";
            },
            tooltipValueGetter: (params) => params.value,
            headerName: materialHeaderMap?.status?.toUpperCase() || "",
            headerTooltip: materialHeaderMap?.status?.toUpperCase() || ""
          },
          {
            field: "trade_partner_name",
            colId: "trade_partner_name",
            headerName: materialHeaderMap?.trade_partner?.toUpperCase() || "",
            headerTooltip:
              materialHeaderMap?.trade_partner?.toUpperCase() || "",
            tooltipField: "trade_partner_name",
            hide: reportFilter.groupby === "GroupBy_RC"
          },
          {
            colId: "gc_representative",
            headerName:
              materialHeaderMap?.gc_representative?.toUpperCase() || "",
            headerTooltip:
              materialHeaderMap?.trade_partner?.toUpperCase() || "",
            cellRenderer: (params: any) => {
              const data = gcRepresentativeCellRendererWithCustomEdit(params);
              return data.cellMarkup;
            },
            cellRendererParams: {
              userInput: {
                id: "gc_representative",
                firstName: "gc_representative_first_name",
                lastName: "gc_representative_last_name",
                statusKey: "gc_representative_status"
              }
            },
            valueGetter: (params: any) => {
              const data = gcRepresentativeCellRenderer(params);
              return data.fullName;
            },
            tooltipValueGetter: (params: any) => params.value,
            hide: reportFilter.groupby === "GroupBy_GCR"
          },
          {
            colId: "dueDate",
            headerName: materialHeaderMap?.due_date?.toUpperCase() || "",
            headerTooltip: materialHeaderMap?.due_date?.toUpperCase() || "",
            headerComponentParams: materialDueDateHeaderComponent,
            cellRenderer: dueDateCellRenderer,
            comparator: DateFilter.comparator,
            valueGetter: ({ data }) => {
              if (!data || data.date_block_materials.length === 0) return "";
              return data.date_block_materials[0]?.due_date
                ? DateUtils.format(data.date_block_materials[0]?.due_date)
                : "";
            },
            tooltipValueGetter: ({ data }: any) => {
              const value = data.date_block_materials[0]?.due_date;
              if (!value) return "";
              return DateUtils.format(value);
            },
            filterValueGetter: ({ data }: any) => {
              const value = data.date_block_materials[0]?.due_date;
              if (!value) return "";
              return DateUtils.format(value);
            }
          },
          {
            colId: "risk_level",
            field: "risk_level",
            headerName: "RISK",
            hide: true,
            cellRenderer: riskCellRenderer
          },
          {
            colId: "risk_assessment",
            headerName: "RISK ASSESSMENT",
            hide: true,
            valueGetter: ({ data }: any) =>
              data.date_block_materials.length
                ? data.date_block_materials[0].risk_assessment
                : ""
          },
          {
            colId: "linked_submittals",
            field: "material_submitttal_links",
            headerName: "LINKED SUBMITTALS",
            hide: true
          },
          {
            colId: "linked_tasks",
            field: "material_schedule_links",
            headerName: "IMPACTED ACTIVITIES",
            hide: true
          },
          {
            colId: "RC_GroupBy",
            field: "responsible_contractor_id",
            rowGroup: false,
            hide: true,
            valueGetter: (param) => param.data.responsible_contractor_name,
            // eslint-disable-next-line react/no-unstable-nested-components
            cellRenderer: ({ data }: any) => (
              <div className="flex uppercase font-semibold">
                {data.responsible_contractor_name}
              </div>
            )
          },
          {
            colId: "BIC_GroupBy",
            headerName:
              materialHeaderMap?.responsible_contractor?.toUpperCase() || "",
            field: "responsible_contractor_name",
            rowGroup: false,
            hide: true,
            valueGetter: ({ data }) => {
              if (!data) return null;
              let fullName = "";
              let userOrg = "";

              if (data.assignee_user_id) {
                fullName = `${data.assignee_first_name} ${data.assignee_last_name}`;
                userOrg = data.assignee_org;
              }
              if (data.assignee_unregistered) {
                fullName = data.assignee_unregistered;
                userOrg = data.assignee_unregistered_org;
              }
              return `${fullName} (${userOrg})`;
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            cellRenderer: ({ value }: any) => (
              <div className="uppercase font-medium">{value}</div>
            )
          }
        ] as ColDef[]
      },

      // get the rows for each Detail Grid
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.callRecords);
      }
    };
  }, [detailCellRenderer, gridContext, materialHeaderMap, reportFilter]);

  useEffect(() => {
    gridRef.current?.api?.forEachDetailGridInfo((gridInfo) => {
      gridInfo.api?.setQuickFilter(gridSearchtext);
    });
    gridRef.current?.api?.onFilterChanged();
  }, [gridSearchtext]);

  useEffect(() => {
    if (gridData.materials) {
      updateGridView(reportFilter, gridData.materials);
    }
  }, [reportFilter, gridData.materials]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isGridReady]);

  useEffect(() => {
    gridRef.current?.api?.refreshCells({ force: true });
  }, [gridData.materials, projectParticipants]);

  useEffect(() => {
    if (gridData.materials) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: ["gc_representative"]
        });
      }, 0);
    }
  }, [gridData.materials]);

  const getParamsForCSVExport = useCallback(
    ({ skipColumnHeaders, detailGridInfo, firstTableParams }: any) => {
      const additionalColumns = [
        "trade_partner_name",
        "BIC_GroupBy",
        "risk_level",
        "risk_assessment",
        "linked_materials",
        "linked_submittals",
        "linked_tasks",
        "gc_representative"
      ];

      const allgridCols = detailGridInfo?.columnApi?.getAllGridColumns();
      // console.log("allgridCols ", allgridCols);
      const columnKeysData = firstTableParams?.columnKeys
        ? firstTableParams?.columnKeys
        : allgridCols
            ?.filter((col: any) => {
              const colId = col.getColId();
              return col.isVisible() || additionalColumns?.includes(colId);
            })
            .map((col: any) => col.getColId());

      return {
        columnKeys: columnKeysData,
        processCellCallback(params: any): string {
          return getMaterialFormattedValues(params);
        },
        skipColumnHeaders
      };
    },
    []
  );

  const exportLogAsCSV = useCallback(() => {
    let csvOpString = "";
    let firstTableParams: any = null;
    gridRef.current?.api?.forEachDetailGridInfo(
      (detailGridInfo, index: number) => {
        const getDataParams = getParamsForCSVExport({
          skipColumnHeaders: index > 0,
          detailGridInfo,
          firstTableParams
        });

        if (index === 0) firstTableParams = getDataParams;

        const gridDataAsCsv = detailGridInfo?.api?.getDataAsCsv(getDataParams);
        csvOpString += index > 0 ? `\n${gridDataAsCsv}` : gridDataAsCsv;
      }
    );

    downloadStringAsCSVFile(
      csvOpString,
      `Upcoming Materials_${projectDetails?.name}.csv`
    );
  }, [getParamsForCSVExport, projectDetails?.name]);

  return (
    <div className="flex flex-col h-full">
      <div className="w-full flex justify-between py-1">
        <div className="font-medium flex uppercase items-center">
          {numberOfMaterials} Upcoming Materials
        </div>
        <div className="flex space-x-3 items-center">
          <Input
            type="text"
            placeholder="Search Materials"
            prefix={<IconSearch size={14} />}
            onChange={(event) => {
              setGridSearchtext(event.target.value);
            }}
            allowClear
          />
          <Button onClick={() => setOpenFilterForm(true)}>
            Run New Report
          </Button>
          <Button
            onClick={() => {
              expandCollapseAll();
            }}
            className="w-[110px]"
            disabled={!masterData || !masterData.length}
          >
            {expandedRowCount === totalNumberOfRows ? (
              <span>Collapse All</span>
            ) : (
              <span>Expand All</span>
            )}
          </Button>
          <Button
            onClick={() => {
              gridRef.current!.api.forEachNode((node) => {
                node.setExpanded(true);
              });

              setTimeout(() => {
                exportLogAsCSV();
              }, 500);
            }}
            disabled={!masterData || !masterData.length}
          >
            Export as CSV
          </Button>
        </div>
      </div>
      <div className="grow ag-theme-alpine pt-1">
        <AgGridReact
          ref={gridRef}
          className="ag-theme-alpine"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          headerHeight={0}
          rowData={masterData}
          suppressContextMenu
          onGridReady={(gridApi) => {
            setGridReady(true);
            gridApi.api.sizeColumnsToFit();
          }}
          masterDetail
          detailCellRendererParams={detailCellRendererParams}
          onRowGroupOpened={() => {
            onUpdateExpandedRow();
          }}
          keepDetailRows
          detailRowAutoHeight
          isExternalFilterPresent={() => {
            return true;
          }}
          doesExternalFilterPass={(rowNode) => {
            const { detailNode } = rowNode;
            if (detailNode) {
              const displayedCount =
                detailNode.detailGridInfo?.api?.getDisplayedRowCount();
              if (!displayedCount) return false;
            }
            return true;
          }}
        />
      </div>
    </div>
  );
}

export default UpcomingMaterialsComponent;
