import { v4 as uuidV4 } from "uuid";
import { TDateBlockType } from "./models";

export const transformSubmittalDateBlock = (inputDateBlock: any) => {
  const dateBlock: TDateBlockType = { mileStones: [] };

  dateBlock.id = inputDateBlock.id;
  dateBlock.submittal_id = inputDateBlock.submittal_id;
  dateBlock.manual_entry = !!inputDateBlock.manual_entry_date;
  dateBlock.manual_entry_date = inputDateBlock.manual_entry_date;
  dateBlock.float = inputDateBlock.float;
  for (let index = 1; index <= 10; index += 1) {
    if (!inputDateBlock[`name_milestone_${index}`]) break;
    dateBlock.mileStones.push({
      key: uuidV4(),
      sequence_no: index,
      name_milestone: inputDateBlock[`name_milestone_${index}`],
      actual: inputDateBlock[`actual_milestone_${index}`],
      actualId: `actual_milestone_${index}`,
      planned: inputDateBlock[`planned_milestone_${index}`],
      plannedID: `planned_milestone_${index}`,
      name_offset: inputDateBlock[`name_offset_${index}`],
      offset: inputDateBlock[`offset_${index}`],
      offsetID: `offset_${index}`,
      projected: inputDateBlock[`projected_milestone_${index}`],
      projectedId: `projected_milestone_${index}`
    });
  }

  return dateBlock;
};

export const transformMaterialDateBlock = (inputDateBlock: any) => {
  const dateBlock: TDateBlockType = { mileStones: [] };

  dateBlock.id = inputDateBlock.id;
  dateBlock.material_id = inputDateBlock.material_id;
  dateBlock.manual_entry = !!inputDateBlock.manual_entry_date;
  dateBlock.manual_entry_date = inputDateBlock.manual_entry_date;
  dateBlock.float = inputDateBlock.float;
  dateBlock.wf_override_reason = inputDateBlock.wf_override_reason;
  for (let index = 1; index <= 10; index += 1) {
    if (!inputDateBlock[`name_milestone_${index}`]) break;
    dateBlock.mileStones.push({
      key: uuidV4(),
      sequence_no: index,
      name_milestone: inputDateBlock[`name_milestone_${index}`],
      actual: inputDateBlock[`actual_milestone_${index}`],
      actualId: `actual_milestone_${index}`,
      planned: inputDateBlock[`planned_milestone_${index}`],
      plannedID: `planned_milestone_${index}`,
      name_offset: inputDateBlock[`name_offset_${index}`],
      offset: inputDateBlock[`offset_${index}`],
      offsetID: `offset_${index}`,
      projected: inputDateBlock[`projected_milestone_${index}`],
      projectedId: `projected_milestone_${index}`
    });
  }

  return dateBlock;
};
