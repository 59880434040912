/* eslint-disable no-nested-ternary */
import { LinkOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import DateBlockDynamicUIPanel from "components/date-block/full-date-block";
import ErrorBoundary from "components/error-boundary";
import AsterixIcon from "components/svg-icons/asterix-icon";
import { ProjectContext } from "context/ProjectProvider";
import { useQuerySubscription } from "hooks/common";
import { SubmittalLog } from "models/submittal-log";
import ASubmittalTitleHeader from "partner-app/components/submittal-title-header";
import { useMemo, useState, useContext } from "react";
import { useHistory, useParams } from "react-router";
import {
  getGQL,
  isProjectInIntegrationMode,
  matchUserProjectRole
} from "utils/utils";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import { GoverningTaskType } from "components/date-block/models";
import { SUBMITTAL_REVISIONS } from "services/graphQL/ciq-queries";
import { EUserTypes } from "../../../../constants";

type Props = {
  submittalData: SubmittalLog;
};
function ASubmittalDetailPage(props: Props) {
  const { submittalData } = props;
  const history = useHistory();
  const { projectId, submittalId } = useParams() as any;
  const {
    gqlClientForProject,
    tokenContents,
    columnHeaders: { submittalHeaderMap },
    projectDetails
  } = useContext(ProjectContext);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const { projectParticipants: WFProjectParticipants } = useProjectParticipants(
    isIntegrationMode || !isLoggedInUserGC
  );
  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode || !isLoggedInUserGC
  );

  const { query: qSubmittalRev, sub: sSubmittalRev } = useMemo(() => {
    return getGQL(SUBMITTAL_REVISIONS);
  }, []);

  const { data: dataSubmittalRevisions } = useQuerySubscription(
    qSubmittalRev,
    sSubmittalRev,
    {
      variables: { id: submittalId },
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const drivingMaterialIndex = useMemo(() => {
    if (
      !submittalData?.submittal_material_links ||
      !submittalData?.submittal_material_links.length
    )
      return 0;
    const idx = submittalData?.submittal_material_links?.findIndex(
      (l) => l.driving_material === true
    );
    return idx !== -1 ? idx : 0;
  }, [submittalData?.submittal_material_links]);
  const [materialDB, setMaterialDB] = useState(
    submittalData?.submittal_material_links.length > 0
      ? submittalData.submittal_material_links[drivingMaterialIndex].material
      : undefined
  );

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  const materialGoverningActivity = useMemo(() => {
    if (materialDB && materialDB.material_schedule_links) {
      const linkedTask = materialDB.material_schedule_links.find(
        (e: any) => e.driving_task
      );
      if (linkedTask)
        return {
          ...linkedTask.gantt_task,
          isLinkedEndDate: linkedTask.linked_to_end_date
        } as GoverningTaskType;
    }
    return {} as GoverningTaskType;
  }, [materialDB]);

  const submittalDateBlockTitleDiv = (
    <div className="uppercase text-xs font-bold tracking-wider h-8 flex items-center">
      SUBMITTAL SCHEDULE{" "}
      <Button
        type="link"
        icon={<LinkOutlined />}
        className="border-0 w-[20px] h-[20px] mx-2"
        title="Add Linking"
        disabled
        onClick={() => {
          history.push(
            `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData.id}`
          );
        }}
      />
    </div>
  );
  const materialDateBlockTitleDiv = useMemo(
    () => (
      <div className="flex items-center tracking-wider h-8 justify-between">
        <div className="flex items-center text-xs font-bold">
          MATERIAL:
          <Tooltip
            title={
              materialDB?.implicit
                ? `Material for Submittal ${submittalData.submittal_id}`
                : materialDB?.name?.length > 20
                ? materialDB.name
                : ""
            }
          >
            <div>
              <Select
                className="ml-1"
                style={{
                  minWidth: "330px",
                  maxWidth: "330px"
                }}
                defaultValue={drivingMaterialIndex}
                onChange={(index) => {
                  setMaterialDB(
                    submittalData.submittal_material_links[index].material
                  );
                }}
                optionLabelProp="label"
                options={submittalData.submittal_material_links
                  .filter((l: any) => l.material)
                  .map((l: any, index: number) => {
                    if (l.material?.implicit)
                      return {
                        label: (
                          <div className="flex items-center max-w-full pr-1 overflow-hidden">
                            {l.driving_material ? (
                              <div className="flex items-center mr-1 ">
                                <AsterixIcon />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="grow max-w-full">
                              <div className="max-w-full truncate">
                                {`Material for Submittal ${submittalData.submittal_id}`}
                              </div>
                            </div>
                          </div>
                        ),
                        value: index
                      };
                    return {
                      label: (
                        <div className="flex items-center max-w-full pr-1 overflow-hidden">
                          {l.driving_material ? (
                            <div className="flex items-center mr-1">
                              <AsterixIcon />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="grow max-w-full">
                            <div className="max-w-full truncate">
                              {l.material?.name}
                            </div>
                          </div>
                        </div>
                      ),
                      value: index
                    };
                  })}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    ),
    [
      drivingMaterialIndex,
      materialDB?.implicit,
      materialDB.name,
      submittalData.submittal_id,
      submittalData.submittal_material_links
    ]
  );

  return (
    <div>
      <div className="grid grid-cols-12 p-1 bg-white">
        <div className="col-span-12">
          <ASubmittalTitleHeader
            submittalHeaderMap={submittalHeaderMap}
            submittalData={submittalData}
            isIntegrationMode={isIntegrationMode}
            projectParticipants={projectParticipants}
            dataSubmittalRevisions={dataSubmittalRevisions}
          />
        </div>
        <div className="col-span-12 ">
          <ErrorBoundary>
            <div className="col-span-12 bg-white date-block-row justify-center p-6 overflow-x-auto">
              <DateBlockDynamicUIPanel
                submittal={submittalData}
                material={
                  submittalData?.material_tracking ? materialDB : undefined
                }
                submittalTitle={submittalDateBlockTitleDiv}
                materialTitle={materialDateBlockTitleDiv}
                noMaterialDateBlockDiv={undefined}
                noSubmittalDateBlockDiv={null}
                governingTaskOfSubmittal={governingActivity}
                governingTaskOfMaterial={materialGoverningActivity}
                disableDB
                componentLocation="SubmittalDetail"
              />
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
export default ASubmittalDetailPage;
