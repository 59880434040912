import { ImpactVisualisationProps } from "pages/schedule/schedule-page/compare-schedule-2/model";
import { EWorkflowStatusDataBlock } from "../../constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export type TNewDateBlock = {
  actual_milestone_1: string;
  actual_milestone_2: string;
  actual_milestone_3: string;
  actual_milestone_4: string;
  actual_milestone_5: string;
  actual_milestone_6: string;
  actual_milestone_7: string;
  actual_milestone_8: string;
  actual_milestone_9: string;
  actual_milestone_10: string;
  name_milestone_1: string;
  name_milestone_2: string;
  name_milestone_3: string;
  name_milestone_4: string;
  name_milestone_5: string;
  name_milestone_6: string;
  name_milestone_7: string;
  name_milestone_8: string;
  name_milestone_9: string;
  name_milestone_10: string;
  name_offset_1: string;
  name_offset_2: string;
  name_offset_3: string;
  name_offset_4: string;
  name_offset_5: string;
  name_offset_6: string;
  name_offset_7: string;
  name_offset_8: string;
  name_offset_9: string;
  name_offset_10: string;
  offset_1: number;
  offset_2: number;
  offset_3: number;
  offset_4: number;
  offset_5: number;
  offset_6: number;
  offset_7: number;
  offset_8: number;
  offset_9: number;
  offset_10: number;
  planned_milestone_1: string;
  planned_milestone_2: string;
  planned_milestone_3: string;
  planned_milestone_4: string;
  planned_milestone_5: string;
  planned_milestone_6: string;
  planned_milestone_7: string;
  planned_milestone_8: string;
  planned_milestone_9: string;
  planned_milestone_10: string;

  projected_milestone_1: string;
  projected_milestone_2: string;
  projected_milestone_3: string;
  projected_milestone_4: string;
  projected_milestone_5: string;
  projected_milestone_6: string;
  projected_milestone_7: string;
  projected_milestone_8: string;
  projected_milestone_9: string;
  projected_milestone_10: string;
  projected_next_milestone_date: string;

  project_id: string;
  submittal_id: string;
  material_id: string;
  id: string;
  final_deadline_date: string;
  planned_last_milestone_date: string;
  planned_next_milestone_date: string;
  projected_last_milestone_date: string;
  today_date: string;
  actual_float: number;
  float: number;
  current_delay: number;
  effective_float: number;
  manual_entry_date: string;
  low_risk_threshold: number;
  high_risk_threshold: number;
  override_project_threshold: boolean;

  risk_assessment: string;
  risk_level: string;
  workflow_template_id: string;
  milestone_state: string;
  workflow_status: EWorkflowStatusDataBlock;
};

export type TMileStone = {
  key: string;
  sequence_no: number;
  name_milestone: string;
  actual: string | null;
  actualId: string;
  planned: string | null;
  plannedID: string;
  name_offset: string;
  offset: number;
  offsetID: string;
  projected: string | null;
  projectedId: string;
};

export type TDateBlockType = {
  mileStones: Array<TMileStone>;
  manual_entry?: boolean;
  manual_entry_date?: string;
  project_id?: string;
  submittal_id?: string;
  material_id?: string;
  id?: string;
  float?: number;
  wf_override_reason?: string;
};

export type TEnableActualReleaseDate = {
  enable: boolean;
  message: string;
  isAnySubmittalStarted: boolean;
};

export type ImpactVisualisationDetailProps = ImpactVisualisationProps & {
  isPlannedOrActualDateAvailable: boolean;
};

export type GoverningTaskType = {
  id: string;
  text: string;
  isLinkedEndDate: boolean;
};

export enum RiskLevelType {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH"
}

export type DateBlockInfoAction = {
  step: string;
  action: string;
  step_name: string;
};
export type DateBlockInfoType = {
  feature_id: number;
  date_block_action: Array<DateBlockInfoAction>;
};

export type TProjectTemplateMilestones = {
  id: string;
  sequence_no: number;
  name: string;
  milestone_action: string;
};

export type TProjectWorkflowTemplates = {
  id: string;
  name: string;
  default: boolean;
  additional_offset: boolean;
  project_template_milestones: Array<TProjectTemplateMilestones>;
};
