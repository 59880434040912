export enum IntegrationType {
  AUTODESK = 1,
  PROCORE = 2,
  CMIC = 3,
  EXTERNAL = 4,
  BIM360 = 5
}

export const activeSourceTypes = ["AUTODESK", "PROCORE", "BIM360"];

export function simplifySubscriptionIntegrationConfigs(configs: any) {
  if (!configs) return null;
  return configs.map((config: any) => {
    const properties: any = {};
    config.integration_config.integration_config_properties.forEach(
      (prop: any) => {
        properties[prop.property_name] = prop.property_value;
      }
    );

    const systemIntgConfigProperties: any = {};
    config?.system_integration_config?.integration_config?.integration_config_properties?.forEach(
      (prop: any) => {
        systemIntgConfigProperties[prop.property_name] = prop.property_value;
      }
    );

    return {
      id: config.id,
      config_id: config.config_id,
      active: config.active,
      subscription_id: config.subscription_id,
      properties,
      integration_config_id: config.integration_config.id,
      integration: {
        system: config?.system_integration_config.integration?.system,
        availability_status:
          config?.system_integration_config?.integration?.availability_status,
        id: config?.system_integration_config?.integration?.id
      },
      systemIntgConfigProperties
    };
  });
}

export function simplifyProjectIntegrationConfigs(configs: any) {
  if (!configs) return null;
  return configs.map((config: any) => {
    const properties: any = {};
    config.integration_config.integration_config_properties.forEach(
      (prop: any) => {
        properties[prop.property_name] = prop.property_value;
      }
    );
    const subscriptionProperties: any = {};
    config.subscription_integration_config.integration_config.integration_config_properties.forEach(
      (prop: any) => {
        subscriptionProperties[prop.property_name] = prop.property_value;
      }
    );

    const systemIntgConfigProperties: any = {};
    config?.system_integration_config?.integration_config?.integration_config_properties?.forEach(
      (prop: any) => {
        systemIntgConfigProperties[prop.property_name] = prop.property_value;
      }
    );

    return {
      id: config.id,
      config_id: config.config_id,
      active: config.active,
      subscription_id: config.subscription_integration_config_id,
      properties,
      subscriptionProperties,
      integration_config_id: config.integration_config.id,
      integration: {
        system: config?.system_integration_config?.integration?.system,
        availability_status:
          config?.system_integration_config?.integration?.availability_status,
        id: config?.system_integration_config?.integration?.id
      },
      systemIntgConfigProperties
    };
  });
}

export function simplifySystemIntegrationConfigs(originalArray: any) {
  if (!originalArray) return null;
  return originalArray.map((config: any) => {
    const optimizedConfig: any = {
      system: config?.integration?.system,
      integration_config_properties: {}
    };

    config.integration_config.integration_config_properties.forEach(
      (property: any) => {
        optimizedConfig.integration_config_properties[property.property_name] =
          property.property_value;
      }
    );

    return optimizedConfig;
  });
}

export const getIntegrationSystemAUthorizerDetails = (
  propertiesObject: any
) => {
  const name =
    propertiesObject.AUTHORIZER_PROCORE_NAME ||
    propertiesObject.SUBSCRIPTION_AUTHORIZER_AUTODESK_NAME;
  const email =
    propertiesObject.AUTHORIZER_PROCORE_EMAIL ||
    propertiesObject.SUBSCRIPTION_AUTHORIZER_AUTODESK_EMAIL;
  return { name, email };
};

export const getCIQAUthorizerDetails = (propertiesObject: any) => {
  const name =
    propertiesObject.AUTHORIZER_CIQ_NAME ||
    propertiesObject.SUBSCRIPTION_AUTHORIZER_CIQ_NAME ||
    "";
  const email =
    propertiesObject.AUTHORIZER_CIQ_EMAIL ||
    propertiesObject.SUBSCRIPTION_AUTHORIZER_CIQ_EMAIL ||
    "";
  return { name, email };
};
