import { EditOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Popover,
  Typography,
  message
} from "antd";
import {
  ProjectContext,
  ProjectSettingType,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  MUTATION_INSERT_SUBMITTAL,
  MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP,
  MUTATION_REDISTRIBUTE_SUBMITTAL
} from "services/graphQL/mutations";
import { QUERY_GET_SUBMITTALL_DISTRIBUTION_LIST } from "services/graphQL/queries";
import SubmittalMicroVisualiser from "components/submittal-details/submittal-micro-viz";
import { CopyIcon } from "components/svg-icons";
import { CloseAndDistributeSubmittal } from "components/close-and-distribute";
import { DateUtils } from "utils/dateutils";
import ErrorBoundary from "components/error-boundary";
import SubmittalMicroVizDateBlock from "components/submittal-details/submittal-microviz-dateblock";
import Tooltip from "antd/es/tooltip";
import {
  ESubmittalStatus,
  ErrorMessages,
  ProjectPermissionEnum
} from "../../constants";
import "./submittal-title-panel.scss";

const { Paragraph } = Typography;
export default function SubmittalTitlePanel(props: {
  revisionDropDown: JSX.Element | null;
  projectId: string;
  projectDetails: ProjectSettingType | undefined;
  submittalData: any;
  editableFields: any;
  updateSubmittalTitle: any;
  tabIndex: string;
  isIntegrationMode: boolean;
  projectParticipants: any;
  dataSubmittalRevisions: any;
  dataSubmittalByPk: any;
}) {
  const {
    projectId,
    revisionDropDown,
    projectDetails,
    submittalData,
    editableFields,
    updateSubmittalTitle,
    tabIndex,
    isIntegrationMode,
    projectParticipants,
    dataSubmittalRevisions,
    dataSubmittalByPk
  } = props;

  const [visible, setVisible] = useState(false);
  const [copiedName, setCopyName] = useState<string>(
    `${submittalData?.title} (copy)`
  );
  const {
    gqlClientForProject,
    tokenContents,
    columnHeaders: { submittalHeaderMap }
  } = useContext(ProjectContext);

  const cannotCreateSubmittal = isPermissionNotGrantted(
    ProjectPermissionEnum.CreateSubmittal,
    tokenContents?.role!
  );

  const cannotRespondToWorkflow: boolean = useMemo(() => {
    return isPermissionNotGrantted(
      ProjectPermissionEnum.SubmittalWorkflow,
      tokenContents?.role!
    );
  }, [tokenContents]);

  useEffect(() => {
    setCopyName(`${submittalData?.title} (copy)`);
  }, [submittalData, tabIndex]);

  const [distrubutionListQuery] = useLazyQuery(
    QUERY_GET_SUBMITTALL_DISTRIBUTION_LIST,
    {
      client: gqlClientForProject
    }
  );

  const [addSubmittal] = useMutation<any>(MUTATION_INSERT_SUBMITTAL, {
    client: gqlClientForProject
  });

  const [insertDLToSubmittal] = useMutation<any>(
    MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const [redistributeSubmittal] = useMutation<any>(
    MUTATION_REDISTRIBUTE_SUBMITTAL,
    {
      client: gqlClientForProject
    }
  );

  const [submittalRedistributeUsers, setSubmittalRedistributeUsers] = useState(
    []
  );
  const [openDistributeModel, setOpenDistributeSubmittalModel] =
    useState(false);

  const [submittalRedistributing, setSubmittalRedistributed] = useState(false);

  const distributedDate =
    submittalData?.date_block_submittals &&
    submittalData?.date_block_submittals.length
      ? submittalData?.date_block_submittals[0]?.actual_milestone_5
      : null;

  const [isIncludeLinks, setInduleLinks] = useState({
    materials: false,
    activities: false
  });

  const onDuplicateSubmittal = async (title: string) => {
    const duplicateSubmittal = {
      title: title.trim(),
      spec_no: submittalData.spec_no,
      spec_name: submittalData.spec_name,
      type: submittalData.type,
      responsible_contractor: submittalData.responsible_contractor,
      submitter: submittalData.submitter,
      gc_reviewer: submittalData.gc_reviewer,
      design_reviewer: submittalData.design_reviewer
    } as any;

    if (isIncludeLinks.activities) {
      duplicateSubmittal.submittal_schedule_links = {
        data: submittalData.submittal_schedule_links.map((s: any) => ({
          linked_to_end_date: s.linked_to_end_date,
          task_id: s.task_id
        }))
      };
    }

    if (isIncludeLinks.materials) {
      duplicateSubmittal.submittal_material_links = {
        data: submittalData.submittal_material_links.map((s: any) => ({
          material_id: s.material_id
        }))
      };
    }

    try {
      const addSubmittalResponse = await addSubmittal({
        variables: { submittal: duplicateSubmittal }
      });

      if (addSubmittalResponse?.data) {
        const newSubmittalId =
          addSubmittalResponse?.data?.insert_submittals_one?.id;
        const responseDistributionList = await distrubutionListQuery({
          variables: {
            where: { submittal_id: { _eq: submittalData.id } }
          }
        });

        if (responseDistributionList.data) {
          if (
            responseDistributionList.data.submittal_distribution_list.length > 0
          ) {
            const newDL =
              responseDistributionList.data.submittal_distribution_list.map(
                (x: any) => ({
                  dl_id: x.dl_id,
                  user_id: x.user_id,
                  submittal_id: newSubmittalId
                })
              );

            await insertDLToSubmittal({ variables: { data: newDL } });
          }
        }
        const messageText = (
          <div>
            Duplicate Submittal created. Click
            <span className="mx-1">
              <a
                href={`/project/${projectId}/submittals/${newSubmittalId}`}
                className="underline"
              >
                here
              </a>
            </span>
            to view details.
          </div>
        );
        message.success(messageText, 10);
      }

      if (addSubmittalResponse?.errors) {
        message.error(addSubmittalResponse?.errors[0].message);
      }
    } catch (ex) {
      console.log("ex ", ex);
      message.error("An error occured");
    }
  };

  const submittalMaterialLinks = useMemo(() => {
    return submittalData?.submittal_material_links?.filter(
      (material: any) => !material.implicit
    );
  }, [submittalData?.submittal_material_links]);

  const content = submittalData && (
    <div className="flex flex-col text-xs w-72 space-y-2">
      <div className="flex text-xs">
        Provide Submittal Name for the duplicate.
      </div>
      <div className="flex items-center">
        <div className="font-semibold pr-1 w-14">Name : </div>
        <Input
          type="text"
          className="grow"
          value={copiedName}
          onChange={(env) => {
            const name = env.target.value;
            setCopyName(name);
          }}
        />
      </div>
      <div className="flex flex-col text-xs pt-4 pb-1 space-y-2">
        <div>Do you want to copy the links from original ?</div>
        <div className="flex justify-between">
          <Tooltip
            title={
              submittalMaterialLinks < 1 ? ErrorMessages.NoLinksPresent : null
            }
          >
            <Checkbox
              className="text-xs"
              checked={
                submittalMaterialLinks.length > 0 && isIncludeLinks.materials
              }
              disabled={submittalMaterialLinks.length < 1}
              onChange={(event) => {
                setInduleLinks((pre) => ({
                  ...pre,
                  materials: event.target.checked
                }));
              }}
            >
              Linked Materials
            </Checkbox>
          </Tooltip>
          <Tooltip
            title={
              submittalData.submittal_schedule_links.length < 1
                ? ErrorMessages.NoLinksPresent
                : null
            }
          >
            <Checkbox
              className="text-xs"
              checked={
                submittalData.submittal_schedule_links.length > 0 &&
                isIncludeLinks.activities
              }
              disabled={submittalData.submittal_schedule_links.length < 1}
              onChange={(event) => {
                setInduleLinks((pre) => ({
                  ...pre,
                  activities: event.target.checked
                }));
              }}
            >
              Linked Activities
            </Checkbox>
          </Tooltip>
        </div>
      </div>
      <Divider className="py-1" />

      <div className="flex justify-end space-x-2 text-xs">
        <Button
          onClick={() => {
            setVisible(!visible);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={copiedName === ""}
          onClick={() => {
            setVisible(!visible);
            onDuplicateSubmittal(copiedName);
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );

  const redistributeClosedSubmittal = async () => {
    setSubmittalRedistributed(true);
    const responseData: any = await redistributeSubmittal({
      variables: {
        submittalId: submittalData.id,
        toAddresses: submittalRedistributeUsers
      }
    });
    setSubmittalRedistributed(false);
    if (responseData?.data?.redistribute_submittal?.message) {
      message.success("Submittal redistributed successfully.");
    } else {
      message.error("Failed to redistribute submittal.");
    }
  };

  return (
    <div className="w-full flex bg-white px-5 py-3 items-center">
      <div className="grow flex items-center space-x-10">
        <Paragraph className="!m-0 text-two !font-normal ">
          {projectDetails?.spec_section && submittalData?.spec_no
            ? `${submittalData?.spec_no} - ${submittalData?.submittal_id || ""}`
            : `${submittalData?.submittal_id || ""}`}
        </Paragraph>
        <Paragraph
          className="!my-0 !font-normal text-two !text-black max-w-xs truncate entity-title-panel-title"
          editable={
            !isIntegrationMode &&
            !cannotRespondToWorkflow &&
            editableFields.includes("title")
              ? {
                  icon: <EditOutlined className="ml-2 text-color-two" />,
                  tooltip: null,
                  onChange: (e) => updateSubmittalTitle(e),
                  maxLength: 1000
                }
              : false
          }
          title={submittalData?.title}
        >
          {submittalData?.title}
        </Paragraph>
        <div>
          {submittalHeaderMap?.revision}: {submittalData?.revision}
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        {isIntegrationMode && (
          <div className="w-[260px]">
            {dataSubmittalRevisions && (
              <ErrorBoundary>
                <SubmittalMicroVizDateBlock
                  submittalData={dataSubmittalByPk}
                  projectParticipants={projectParticipants}
                  submittalRevisions={
                    dataSubmittalRevisions?.submittals_by_pk
                      ?.submittal_revisions
                  }
                  selectedRevision={submittalData?.revision}
                />
              </ErrorBoundary>
            )}
          </div>
        )}
        {!isIntegrationMode && (
          <div className="w-[260px]">
            <ErrorBoundary>
              <SubmittalMicroVisualiser
                design_reviewer_id={
                  submittalData?.design_reviewer ||
                  submittalData?.design_reviewer_unregistered
                }
                gc_reviewer_id={submittalData?.gc_reviewer}
                submittal_history={
                  submittalData?.submittal_status_history_details
                }
                vendorInfo={{
                  designReviewerCompanyName:
                    submittalData?.design_reviewer_vendor_unregistered,
                  responsibleContractorCompanyName:
                    submittalData?.responsible_contractor_vendor?.name
                }}
              />
            </ErrorBoundary>
          </div>
        )}
        {!isIntegrationMode &&
          submittalData?.workflow_status === ESubmittalStatus.DONE && (
            <div className="flex space-x-2 items-center">
              <div className="font-semibold">
                Submittal Distributed: {DateUtils.format(distributedDate)}
              </div>
              <div>
                <Button
                  onClick={() => {
                    setOpenDistributeSubmittalModel(true);
                  }}
                  loading={submittalRedistributing}
                  disabled={submittalRedistributing}
                >
                  Redistribute
                </Button>
                <Modal
                  title="Redistribute Submittal"
                  width={500}
                  open={openDistributeModel}
                  okButtonProps={{
                    disabled: submittalRedistributeUsers?.length === 0
                  }}
                  onOk={() => {
                    if (submittalRedistributeUsers?.length > 0) {
                      setOpenDistributeSubmittalModel(false);
                      redistributeClosedSubmittal();
                    }
                  }}
                  onCancel={() => {
                    setOpenDistributeSubmittalModel(false);
                  }}
                  okText="Redistribute"
                  destroyOnClose
                >
                  <CloseAndDistributeSubmittal
                    submittalData={submittalData}
                    submittalWorkflowResponses={[]}
                    commentVal=""
                    redistributeSubmittalToUsers={(selectedusers: any) => {
                      setSubmittalRedistributeUsers(selectedusers);
                    }}
                    submittalViewState={{
                      status: submittalData?.workflow_status,
                      userType: ""
                    }}
                  />
                </Modal>
              </div>
            </div>
          )}

        {!isIntegrationMode && (
          <Popover
            open={!cannotCreateSubmittal && visible}
            placement="bottomRight"
            content={content}
            trigger="click"
            onOpenChange={() => {
              setVisible(!visible);
            }}
            destroyTooltipOnHide
          >
            <div>
              <Button
                className="flex space-x-2 items-center"
                disabled={cannotCreateSubmittal}
              >
                Duplicate
                <CopyIcon />
              </Button>
            </div>
          </Popover>
        )}
        {revisionDropDown}
      </div>
    </div>
  );
}
