import { createContext, useCallback, useEffect, useState } from "react";
import { getAccessToken, heartbeatAPI } from "services/auth";
import jwtDecode from "jwt-decode";
import { useQuery } from "@apollo/client";
import { QUERY_PRODUCT_ENTITLEMENTS } from "services/graphQL/queries";

export enum RoleSubscriptionEnum {
  none = 0,
  subscription_viewer,
  subscription_editor,
  subscription_admin
}

type ContextType = {
  subscriptionRole: RoleSubscriptionEnum;
  subscriptionId: string | undefined;
  isFeatureFlagEnabled: (feature: string) => Boolean;
};

export const SubscriptionContext = createContext<ContextType>({
  subscriptionRole: RoleSubscriptionEnum.none,
  subscriptionId: undefined,
  isFeatureFlagEnabled: () => false
});
export interface SubscriptionProviderProps {
  children: React.ReactNode;
}

function SubscriptionProvider({ children }: SubscriptionProviderProps) {
  const token = getAccessToken();

  const { data: productEntitlementsData } = useQuery<{
    get_product_entitlements: Array<{
      feature: string;
      enabled: boolean;
    }>;
  }>(QUERY_PRODUCT_ENTITLEMENTS);

  const isFeatureFlagEnabled = useCallback(
    (feature: string) => {
      if (productEntitlementsData?.get_product_entitlements) {
        return productEntitlementsData?.get_product_entitlements.some(
          (f) => f.enabled && f.feature === feature
        );
      }
      return false;
    },
    [productEntitlementsData?.get_product_entitlements]
  );

  const [contextState, setContextState] = useState<ContextType>({
    subscriptionRole: RoleSubscriptionEnum.none,
    subscriptionId: undefined,
    isFeatureFlagEnabled
  });

  useEffect(() => {
    if (productEntitlementsData) {
      setContextState((pre) => ({
        ...pre,
        isFeatureFlagEnabled
      }));
    }
  }, [isFeatureFlagEnabled, productEntitlementsData]);

  useEffect(() => {
    if (!token) return;
    const jwt: any = jwtDecode(token);
    if (!jwt || !jwt["https://hasura.io/jwt/claims"]) return;

    const role = jwt["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
    const subscriptionId =
      jwt["https://hasura.io/jwt/claims"]["x-hasura-subscription-id"];
    let subscriptionRole = RoleSubscriptionEnum.none;

    switch (role) {
      case "subscription_admin":
        subscriptionRole = RoleSubscriptionEnum.subscription_admin;
        break;
      case "subscription_editor":
        subscriptionRole = RoleSubscriptionEnum.subscription_editor;
        break;
      case "subscription_viewer":
        subscriptionRole = RoleSubscriptionEnum.subscription_viewer;
        break;
      default:
        subscriptionRole = RoleSubscriptionEnum.none;
        break;
    }
    setContextState((pre) => ({ ...pre, subscriptionRole, subscriptionId }));

    const HEARTBEAT_INTERVAL_TIME_MILISECOND = 1000 * 60 * 5; // 5 min
    setInterval(heartbeatAPI, HEARTBEAT_INTERVAL_TIME_MILISECOND);
  }, [token]);

  return (
    <SubscriptionContext.Provider value={contextState}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export default SubscriptionProvider;
