import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useState } from "react";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";
import { Select } from "antd";

function FeatureSpecSectionDropdown(props: {
  disabled?: boolean;
  selectedIds?: any;
  onBlur: (ids: string[]) => void;
}) {
  const { onBlur, disabled, selectedIds } = props;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);

  const [localSelectedIds, setLocalSelectedIds] = useState<string[]>([]);

  const { data: specSections, refetch: refetchSpecs } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (!selectedIds || !specSections) return;
    setLocalSelectedIds(structuredClone(selectedIds));
  }, [selectedIds, specSections]);

  useEffect(() => {
    const hasInsertEvent = eventLogs.some(
      (log) =>
        log.data_source === "spec_sections" && log.change_type === "insert"
    );
    if (hasInsertEvent) {
      refetchSpecs();
    }
  }, [eventLogs, refetchSpecs]);

  return (
    <Select
      showSearch
      mode="multiple"
      disabled={disabled}
      value={localSelectedIds}
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      options={specSections?.spec_sections.map((spec) => ({
        value: spec.id,
        label: spec.title
          ? `${spec.section_number} - ${spec.title}`
          : spec.section_number
      }))}
      onChange={(ids) => {
        setLocalSelectedIds(ids);
      }}
      onBlur={() => {
        onBlur(localSelectedIds);
      }}
      removeIcon={null}
      showArrow
    />
  );
}

export default FeatureSpecSectionDropdown;
