import InfoIcon from "components/svg-icons/info-icon";
import { IMaterialGroup } from "../type-definitions";
import { MaterialListItem } from "../widgets";

function NoAssigneeRhsContent({
  title,
  group
}: {
  title: string;
  group: IMaterialGroup;
}) {
  const { allowedToSendRequestItems, WFStartedItems } = group.materialsByStatus;

  const materialList = allowedToSendRequestItems?.length
    ? allowedToSendRequestItems
    : WFStartedItems;

  return (
    <div className="space-y-6">
      <div className="text-sm font-semibold">{title}</div>
      {materialList?.length && (
        <div>
          <div className="flex items-start space-x-1">
            <div className="form-field-heading-2">
              Excluded from selection ({materialList.length})
            </div>
            <div
              className="danger-text flex items-center"
              data-testid="info-icon"
            >
              <InfoIcon />
            </div>
          </div>
          <div className="mt-1 container-bg-color px-3 pt-3 pb-3.5 ">
            {materialList.map((material, index) => (
              <MaterialListItem
                material={material}
                index={index}
                key={material.id}
                additionalClassName="border-0 border-b border-solid border-color-1"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default NoAssigneeRhsContent;
