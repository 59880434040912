import { Input, Form, Button, message } from "antd";

import { restrictInputToNumbers } from "utils/inpututils";
import { Dispatch, SetStateAction, useMemo } from "react";
import { IMaterial, UpdateLeadTimeRequestData } from "./type-definitions";
import { updateLeadTimeRequestDetails } from "./services";

function MaterialDurationsSection({
  material,
  token,
  submittingState,
  onSubmitSuccess
}: {
  material: IMaterial;
  token: string;
  submittingState: [boolean, Dispatch<SetStateAction<boolean>>];
  onSubmitSuccess: () => void;
}) {
  const [submitting, setSubmitting] = submittingState;

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  const initialValues = useMemo(() => {
    return material.offsets.reduce((acc: any, offset) => {
      acc[offset.id] = offset.value;
      return acc;
    }, {});
  }, [material.offsets]);

  const hasFormValuesChanged = useMemo(() => {
    if (!formValues) return false;
    return Object.keys(formValues).some(
      (key) => Number(formValues[key]) !== Number(initialValues[key])
    );
  }, [formValues, initialValues]);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    // console.log(values);

    const data: UpdateLeadTimeRequestData = [
      {
        estimate_invite_feature_id: material.estimate_invite_feature_id,
        material_id: material.id,
        offsets: material.offsets.map((offset) => ({
          id: offset.id,
          value: Number(values[offset.id])
        }))
      }
    ];

    if (!token) {
      console.log("Update request token not found");
      return;
    }
    const response = await updateLeadTimeRequestDetails(token, data);
    // console.log("response ", response);

    if (response.success) {
      message.success("Lead time updated successfully");
      onSubmitSuccess();
    } else {
      message.error("Failed to update lead time");
    }
    setSubmitting(false);
  };

  return (
    <div className="p-5">
      <div className="text-sm font-semibold text-color-1">Enter Lead Time</div>
      <div className="w-full xl:w-1/2 mt-3">
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          {material?.offsets
            ?.sort((a, b) => a.order - b.order)
            .map((offset) => (
              <Form.Item
                label={offset.name}
                name={offset.id}
                rules={[
                  {
                    required: true,
                    message: "Please enter lead time in numbers only."
                  }
                ]}
                key={offset.id}
              >
                <Input
                  type="number"
                  suffix="Days"
                  placeholder="0"
                  min={0}
                  onKeyDown={restrictInputToNumbers}
                  tabIndex={0}
                />
              </Form.Item>
            ))}
          <div className="flex justify-end">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={submitting}
              disabled={!hasFormValuesChanged}
              title={
                !hasFormValuesChanged ? "Change values to submit lead time" : ""
              }
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default MaterialDurationsSection;
