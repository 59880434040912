/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useQuery, useSubscription } from "@apollo/client";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useState, useEffect } from "react";
import {
  QUERY_GANTT_TASKS_LIST,
  QUERY_PROJECT_MATERIALS,
  QUERY_SUBMITTALS_LINKING_TABLE
} from "services/graphQL/queries";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import LinkIcon from "components/svg-icons/link-icon";
import { Dropdown, Button, Tooltip } from "antd";
import { GridLoadingIndicator } from "components/widgets";
import { SUBSCRIPTION_MATERIAL_LINKING_BY_ID } from "services/graphQL/subscriptions";
import ErrorBoundary from "components/error-boundary";
import { useHistory, useLocation, useParams } from "react-router";
import MaterialIcon from "components/svg-icons/material-icon";
import GoverningLegend from "components/governing-legend";
import RedirectIcon from "components/svg-icons/redirect-icon";
import { Link } from "react-router-dom";
import { mergedStrings } from "utils/utils";
import {
  MaterialType,
  ScheduleLinkingType,
  ScheduleTaskType,
  SubmittalLinkedType,
  SubmittalType
} from "./models";
import MaterialActivityLinking from "./materialActivityLinking";
import MaterialSubmittalLinking from "./materialSubmittalLinking";
import { ProjectPermissionEnum } from "../../constants";

function MaterialLinkedTab() {
  const { search } = useLocation();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);
  const [materialList, setMaterialList] = useState([] as Array<MaterialType>);
  const [selectedMaterialId, setSelectedMaterialId] = useState<string>("");
  const [selectedMaterial, setSelectedMaterial] = useState<
    MaterialType | undefined
  >(undefined);

  const [materialSearch, setMaterialSearch] = useState("");
  const [activityList, setActivityList] = useState(
    [] as Array<ScheduleTaskType>
  );
  const [submittalList, setSubmittalList] = useState<Array<SubmittalType>>([]);
  const [loadingMaterialDetail, setLoadingMaterialDetail] = useState(false);
  const [selectedSubmittal, setSelectedSubmittal] = useState<SubmittalType>();
  const [openDropdown, setOpenDropdown] = useState(false);

  const isPermissionNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const { data: submittalsRaw } = useQuery(QUERY_SUBMITTALS_LINKING_TABLE, {
    client: gqlClientForProject,
    fetchPolicy: "no-cache"
  });
  const { data: projectMaterials, loading: loadingMaterials } = useQuery(
    QUERY_PROJECT_MATERIALS,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: tasksData, refetch: reFetchActivity } = useQuery(
    QUERY_GANTT_TASKS_LIST,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: materialById } = useSubscription(
    SUBSCRIPTION_MATERIAL_LINKING_BY_ID,
    {
      client: gqlClientForProject,
      variables: {
        id: selectedMaterialId
      },
      shouldResubscribe: true,
      skip: !selectedMaterialId,
      onData: () => {
        setLoadingMaterialDetail(false);
      },
      onError: () => {
        setLoadingMaterialDetail(false);
      }
    }
  );

  useEffect(() => {
    setMaterialList(
      projectMaterials?.material?.sort(
        (current: MaterialType, next: MaterialType) => {
          return (+current?.material_id || 0) - (+next?.material_id || 0);
        }
      ) || ([] as Array<MaterialType>)
    );
    const materialId = new URLSearchParams(search).get("materialId");
    if (materialId) {
      const material = projectMaterials?.material.find((item: MaterialType) => {
        return item.id === materialId;
      });
      if (material) {
        setSelectedMaterialId(materialId);
        setLoadingMaterialDetail(true);
      }
    }
  }, [projectMaterials?.material]);

  useEffect(() => {
    setActivityList(
      (tasksData?.gantt_tasks || [])
        .filter((ts: any) => ts.type !== 3 && ts.type !== 4)
        .sort((current: any, next: any) =>
          current?.source_task_id?.localeCompare(next?.source_task_id)
        )
    );
  }, [tasksData]);

  useEffect(() => {
    if (submittalsRaw?.submittals) {
      setSubmittalList(
        submittalsRaw.submittals?.sort(
          (current: SubmittalType, next: SubmittalType) =>
            (+current?.submittal_id || 0) - (+next?.submittal_id || 0)
        )
      );
    }
  }, [submittalsRaw?.submittals]);

  useEffect(() => {
    if (materialById?.material_by_pk) {
      setLoadingMaterialDetail(false);
      const materialObj = materialById.material_by_pk as MaterialType;
      (materialObj?.submittal_material_links || []).sort(
        (current: SubmittalLinkedType, next: SubmittalLinkedType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      (materialObj.material_schedule_links || []).sort(
        (current: ScheduleLinkingType, next: ScheduleLinkingType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      setMaterialList(
        materialList.map((value: MaterialType) => {
          if (value.id === materialObj.id) {
            return materialObj;
          }
          return value;
        })
      );
      setSelectedMaterial(materialObj);
      const selectedSubmittalObj = materialObj.submittal_material_links.find(
        (item: SubmittalLinkedType) => {
          return item.submittal.id === selectedSubmittal?.id;
        }
      );
      if (selectedSubmittalObj) {
        setSelectedSubmittal(selectedSubmittalObj.submittal);
      } else if (materialObj.submittal_material_links.length > 0) {
        setSelectedSubmittal(materialObj.submittal_material_links[0].submittal);
      } else {
        setSelectedSubmittal(undefined);
      }
    }
  }, [materialById?.material_by_pk]);

  const menu = () => {
    if (loadingMaterials) {
      return (
        <div className="linking-container--nav__loading">
          <LoadingOutlined />
        </div>
      );
    }
    if (materialList.length === 0) {
      return (
        <div className="linking-container--nav__loading">
          No Material Available
        </div>
      );
    }
    return (
      <div className="linking-container--nav__dropdown">
        <div className="linking-container--nav__dropdown--search">
          <Search
            className="linking-search"
            placeholder="Search Material Here"
            value={materialSearch}
            onChange={(e: any) => setMaterialSearch(e.currentTarget.value)}
          />
        </div>
        {materialList
          .filter((item) => {
            const label =
              `${item.material_id} ${item?.name}`.toLocaleLowerCase();
            return label.includes(materialSearch.toLocaleLowerCase());
          })
          .map((item) => (
            <div
              className="linking-container--nav__option flex items-center justify-between"
              key={item.id}
              onClick={() => {
                setOpenDropdown(false);
                if (selectedMaterialId === item.id) {
                  return;
                }
                history.push(
                  `/project/${projectId}/schedule/material-linking?&materialId=${item.id}`
                );
                setSelectedMaterialId(item.id);
                setLoadingMaterialDetail(true);
                setSelectedMaterial(undefined);
              }}
            >
              {item?.spec_section_no || item?.spec_section_name ? (
                <Tooltip
                  title={
                    <div className="space-y-1 py-1">
                      <div className="text-xs">
                        {mergedStrings([
                          item?.spec_section_no || "",
                          item?.spec_section_name || ""
                        ])}
                      </div>
                      <div>{mergedStrings([item?.name || ""])}</div>
                    </div>
                  }
                >
                  <span className="items-center w-full truncate">
                    {mergedStrings([item?.material_id || "", item?.name || ""])}
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={mergedStrings([
                    item?.material_id || "",
                    item?.name || ""
                  ])}
                >
                  <span className="items-center w-full truncate">
                    {mergedStrings([item?.material_id || "", item?.name || ""])}
                  </span>
                </Tooltip>
              )}

              {item?.material_schedule_links.length ? <LinkIcon /> : ""}
            </div>
          ))}
      </div>
    );
  };
  const getSelectedMaterialLabel = () => {
    const selectedMaterialObj = materialList.find((material: MaterialType) => {
      return material.id === selectedMaterialId;
    });
    if (selectedMaterialObj) {
      return (
        <div className="flex items-center linking-container--nav__dropdown--selected">
          {selectedMaterial?.spec_section_no ||
          selectedMaterial?.spec_section_name ? (
            <Tooltip
              title={
                <div className="space-y-1 py-1">
                  <div className="text-xs">
                    {mergedStrings([
                      selectedMaterialObj?.spec_section_no || "",
                      selectedMaterialObj?.spec_section_name || ""
                    ])}
                  </div>
                  <div>{mergedStrings([selectedMaterialObj?.name || ""])}</div>
                </div>
              }
            >
              <span className="flex items-center">
                <span className="mr-2">{selectedMaterialObj.material_id}</span>
                <span className="flex-grow truncate">
                  {selectedMaterialObj.name}
                </span>
              </span>
            </Tooltip>
          ) : (
            <span className="flex items-center">
              <span className="mr-2">{selectedMaterialObj.material_id}</span>
              <span className="flex-grow truncate">
                {selectedMaterialObj.name}
              </span>
            </span>
          )}

          {selectedMaterialObj?.material_schedule_links.length ? (
            <div className="flex items-center">
              <LinkIcon />
              <DownOutlined className="ml-1" />
            </div>
          ) : (
            <div className="flex items-center">
              <DownOutlined className="ml-4" />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="flex items-center linking-select-empty">
        Select a Material
        <DownOutlined className="ml-4" />
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <div className="linking-page flex flex-col text-xs">
        <div
          className={`linking-container ${
            selectedMaterial ? "" : "linking-container--empty"
          }`}
        >
          <div className="linking-container--nav justify-between">
            <div className="flex items-center">
              <div className="flex items-center">
                <MaterialIcon className="mr-2" />
                <h3>MATERIAL</h3>
              </div>

              <Dropdown
                overlay={menu()}
                className="mx-4 linking-container--nav__select"
                trigger={["click"]}
                open={openDropdown}
                onOpenChange={(open) => {
                  setOpenDropdown(open);
                }}
              >
                <Button>{getSelectedMaterialLabel()}</Button>
              </Dropdown>
              {selectedMaterial ? (
                <Tooltip title="Open Material Detail">
                  <Link
                    className="flex linking-container--nav__redirectlinkIcon"
                    to={`/project/${projectId}/materials/${selectedMaterialId}`}
                    target="_blank"
                  >
                    <RedirectIcon />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <GoverningLegend />
          </div>
          {selectedMaterial ? (
            <div className="linking-group">
              <div className="linking-group--left show-material">
                <MaterialActivityLinking
                  activityList={activityList}
                  material={selectedMaterial}
                  reFetchActivity={reFetchActivity}
                  canEdit={!isPermissionNotGrant}
                />
                <MaterialSubmittalLinking
                  submittalList={submittalList}
                  material={selectedMaterial}
                  selectedId={selectedSubmittal?.id}
                  onSelect={(submittal) => {
                    setSelectedSubmittal(submittal);
                  }}
                  canEdit={!isPermissionNotGrant}
                />
              </div>
              <div className="linking-group--right" id="linkingOptionContainer">
                <div className="linking-group--right--nav">
                  <h2>Instructions</h2>
                </div>
                <div className="linking-group--right--content">
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 1:</b>{" "}
                      <span className="grow">
                        Choose a Material from the dropdown menu.
                      </span>
                    </p>
                  </div>
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 2:</b>{" "}
                      <span className="grow">
                        Click &quot;Associate Activity&quot; to link an Activity
                        and selected Material. To link a Submittal and the
                        selected Material, click &quot;Associate
                        Submittal&quot;.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : loadingMaterialDetail ? (
            <div className="linking-loading--container">
              <div>
                <GridLoadingIndicator />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default MaterialLinkedTab;
