import SuccessCheckIcon from "components/svg-icons/success-icon";
import { Typography } from "antd";
import { EstimateStatus, IMaterial } from "./type-definitions";

const { Paragraph } = Typography;

function mergeSpecSectionNoandName(
  specSectionNo: string | null,
  specSectionName: string | null
) {
  if (!specSectionNo || !specSectionName) return "-";
  if (specSectionNo === null || specSectionNo === "") return specSectionName;
  if (specSectionName === null || specSectionName === "") return specSectionNo;
  return `${specSectionNo} - ${specSectionName}`;
}

function MaterialDetailsSection({ material }: { material: IMaterial }) {
  return (
    <div className="pt-5 px-4 lg:px-7 pb-20">
      <div className="text-sm font-semibold text-color-1">Material Details</div>
      <div className="mt-3.5">
        <div className="flex items-start justify-start space-x-5">
          <div className="text-base text-color-4 font-semibold">
            {material.name}
          </div>
          <div className="flex items-center justify-center pt-1">
            {material.estimate_status === EstimateStatus.SUBMITTED && (
              <span title="Lead Time Submitted">
                <SuccessCheckIcon />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 pl-3 lg:pl-6 space-y-6">
        <div>
          <div className="text-color-1">Spec Section</div>
          <div className="text-color-3">
            {mergeSpecSectionNoandName(
              material.spec_section_no,
              material.spec_section_name
            )}
          </div>
        </div>
        <div>
          <div className="text-color-1">Tag</div>
          <div className="text-color-3">{material.material_tag || "-"}</div>
        </div>
        <div>
          <div className="text-color-1">Description</div>
          <div className="text-color-3">
            <Paragraph ellipsis={{ rows: 6, expandable: true, symbol: "more" }}>
              {material.description || "-"}
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialDetailsSection;
