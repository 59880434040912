const UPDATE_FEATURE_INSTANCE_BY_PK = `
mutation UPDATE_FEATURE_INSTANCE_BY_PK($featureId: uuid!, $set: feature_instance_set_input) {
  update_feature_instance_by_pk(
    pk_columns: {id: $featureId}
    _set: $set
) {
    id
  }
}
`;

const UPDATE_FEATURE_BID_PACKAGE_BY_PK = `
mutation UPDATE_FEATURE_BID_PACKAGE_BY_PK($featureId: uuid!, $set: feature_bid_package_set_input) {
  update_feature_bid_package_by_pk(
    pk_columns: {feature_instance_id: $featureId}
    _set: $set
  ) {
    feature_instance_id
  }
}
`;

const UPDATE_FEATURE_INSTANCE_SPEC_SECTION = `mutation UPDATE_FEATURE_INSTANCE_SPEC_SECTION($instance_id: uuid!, $objects: [feature_instance_spec_section_insert_input!]!) {
  delete_feature_instance_spec_section(where: {feature_instance_id: {_eq: $instance_id}}) {
    affected_rows
  }
  insert_feature_instance_spec_section(objects: $objects) {
    affected_rows
  }
}
`;

const UPDATE_FEATURE_COMPANY_PARTICIPANT = `mutation UPDATE_COMPANY_PARTICIPANT($instance_id: uuid!, $company_participation_type_id: Int!,$objects: [feature_company_participant_insert_input!]!) {
  delete_feature_company_participant(
    where: {instance_id: {_eq: $instance_id}, company_participation_type_id: {_eq: $company_participation_type_id}}
  ) {
    affected_rows
  }
  insert_feature_company_participant(objects: $objects) {
    affected_rows
  }
}`;

const UPDATE_FEATURE_COMPANY_PARTICIPANT_WITH_EMPTY_USERS = `
mutation UPDATE_FEATURE_COMPANY_PARTICIPANT_WITH_EMPTY_USERS($instance_id: uuid!, $company_participation_type_id: Int!, $user_participation_type_id:Int!,  $objects: [feature_company_participant_insert_input!]!) {
  delete_feature_company_participant(
    where: {instance_id: {_eq: $instance_id}, company_participation_type_id: {_eq: $company_participation_type_id}}
  ) {
    affected_rows
  }
  delete_feature_user_participant(
    where: {_and: [{instance_id: {_eq: $instance_id}}, {user_participation_type_id: {_eq: $user_participation_type_id}}]}
  ) {
    affected_rows
  }
  insert_feature_company_participant(objects: $objects) {
    affected_rows
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET = `
mutation updateWorkflowOffset($id: uuid!, $set: feature_workflow_instance_offsets_set_input = {}, $workflowInstanceId: uuid!) {
  update_feature_workflow_instance_offsets_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
  update_feature_workflow_instance_by_pk(pk_columns: {id: $workflowInstanceId}, _set: {template_value_overidden: true}) {
    id
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE = `
mutation updateWorkflowMilestone($id: uuid!, $set: feature_workflow_instance_milestones_set_input = {}) {
  update_feature_workflow_instance_milestones_by_pk(
    pk_columns: {id: $id}
    _set: $set
  ) {
    id
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE = `
mutation updateFeatureWorkflowInstance($id: uuid!, $set: feature_workflow_instance_set_input!) {
  update_feature_workflow_instance_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
`;

const MUTATION_CREATE_FEATURE_INSTANCE = `
  mutation CreateFeatureInstancePackage(
    $feature_type: String!
    $feature_instances: [json!]
  ) {
    insert_feature_instances_multi(
      feature_type: $feature_type
      feature_instances: $feature_instances
    ) {
      ids
      message
    }
  }
`;

const MUTATION_UPDATE_FEATURE_USER_PARTICIPANT = `
  mutation updateFeatureUserParticipant($featureInstanceId: uuid!, $object: feature_user_participant_insert_input!, $user_participation_type_id: Int!) {
    delete_feature_user_participant(where: {_and: [{instance_id: {_eq: $featureInstanceId}}, {user_participation_type_id: {_eq: $user_participation_type_id}}]}) {
      affected_rows
    }
    insert_feature_user_participant_one(object: $object) {
      user_id
    }
}
`;

const UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE = `
  mutation UpdateFeatureInstanceWorkflowTemplate($featureInstanceId: uuid!, $workflowTemplateId: uuid!) {
    update_feature_instance_workflow_template(feature_instance_id: $featureInstanceId, workflow_template_id: $workflowTemplateId) {
      message
      updated
    }
  }
`;

const UPDATE_BID_PACKAGE_DETAILS_MULTI = `
 mutation UpdateBidPackages($featureInstanceIds: [uuid!]!, $bidPackage: feature_bid_package_input, $workFlowTemplateId: uuid, $participants: [feature_user_participant_input!], $offsetData: feature_offset_data_input) {
    update_bidpackages(featureInstanceIds: $featureInstanceIds, bidPackage: $bidPackage, workFlowTemplateId: $workFlowTemplateId, participants: $participants, offsetData: $offsetData) {
      message
    }
  }
`;

export {
  UPDATE_FEATURE_BID_PACKAGE_BY_PK,
  UPDATE_FEATURE_INSTANCE_BY_PK,
  UPDATE_FEATURE_INSTANCE_SPEC_SECTION,
  UPDATE_FEATURE_COMPANY_PARTICIPANT,
  UPDATE_FEATURE_COMPANY_PARTICIPANT_WITH_EMPTY_USERS,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  MUTATION_CREATE_FEATURE_INSTANCE,
  MUTATION_UPDATE_FEATURE_USER_PARTICIPANT,
  UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE,
  UPDATE_BID_PACKAGE_DETAILS_MULTI
};
