import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from "@apollo/client";
import { Button, Form, Input, message, Modal, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ErrorMessages, Regex } from "constants/index";
import { useUserProfileInfo } from "hooks/users";
import { useContext, useEffect, useState } from "react";
import { getUser } from "services/auth";
import {
  SUBSCRIPTION_COMPANY_LIST,
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_USERS_ACTIVE
} from "services/graphQL/subscriptions";
import { MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION } from "services/graphQL/mutations";
import {
  QUERY_GET_USER_TYPE_BY_KEY,
  QUERY_USER_TYPE
} from "services/graphQL/queries";
import { ProjectContext } from "context/ProjectProvider";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Role } from "./invite-subscription-user";

const { Option } = Select;

function InviteProjectUser({
  modelTitle,
  setDrawerOpen,
  showDrawerOpen,
  existingUsers,
  vendorData
}: {
  modelTitle: string;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  existingUsers: Array<any>;
  vendorData?: any;
}) {
  const { projectId } = useParams() as any;
  const [form] = Form.useForm();

  const { tokenContents, gqlClientForProject } = useContext(ProjectContext);

  const [allUsers, setfetchedUsers] = useState<any>([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [projectVendors, setProjectVendors] = useState<Array<any>>([]);
  const [currentUser]: [any, any] = useState(getUser());
  const { data: loggedInUserData } = useUserProfileInfo(currentUser.email);
  const [userTypeId, setUserTypeId] = useState<Number>();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const companyFieldValue = Form.useWatch("company", form);

  const [queryUserType] = useLazyQuery(QUERY_GET_USER_TYPE_BY_KEY);
  const { data: userTypes } = useQuery(QUERY_USER_TYPE);

  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    {
      shouldResubscribe: true,
      skip: !gqlClientForProject,
      client: gqlClientForProject
    }
  );

  const { data: usersData, loading } = useSubscription(
    SUBSCRIPTION_USERS_ACTIVE,
    {
      shouldResubscribe: true
    }
  );

  useEffect(() => {
    if (tokenContents) {
      queryUserType({
        variables: { key: tokenContents.userType }
      }).then((res) => {
        if (res.data) {
          setUserTypeId(res.data.user_types[0].id);
        }
      });
    }
  }, [queryUserType, tokenContents]);

  const { data: projectSubscriptionRoles, loading: projectRolesloading } =
    useSubscription(SUBSCRIPTION_PROJECT_ROLES, {
      shouldResubscribe: true,
      variables: {
        where: { type_id: { _eq: userTypeId } }
      },
      skip: !userTypeId,
      client: gqlClientForProject
    });

  const [addUserProjectSubscription] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
    {
      client: gqlClientForProject
    }
  );

  useEffect(() => {
    if (
      companyData &&
      companyData?.subscription_vendors &&
      loggedInUserData?.subscription_users?.length &&
      loggedInUserData?.subscription_users[0]?.subscription
    ) {
      const id =
        loggedInUserData?.subscription_users[0]?.subscription
          ?.default_vendor_id;

      const subscriptionVendors = companyData?.subscription_vendors || [];

      try {
        const vendors = [...subscriptionVendors]
          .filter((x: any) => x.project_vendors.length)
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );
        setProjectVendors(vendors);
        form.setFieldsValue({
          company: id,
          company_type: vendors.find((x) => x.id === id)?.project_vendors?.[0]
            ?.type_id,
          project_role_id: null
        });
      } catch (ex) {
        //
      }
    }
  }, [companyData, form, loggedInUserData?.subscription_users]);

  useEffect(() => {
    if (usersData && existingUsers && companyFieldValue && companyData) {
      const remainingSubscriptionUsers: Array<any> =
        usersData?.subscription_users?.filter(
          (u: any) =>
            u.vendor_id === companyFieldValue &&
            !existingUsers.some((e) => e?.user?.email === u.user.email)
        );

      const selectedCompany = companyData?.subscription_vendors?.find(
        (cmpny: any) => cmpny.id === companyFieldValue
      );

      const remainingPocUsers: Array<any> = selectedCompany?.trade_partner_pocs
        ?.filter((u: any) => !existingUsers.some((e) => e.user.id === u.id))
        .map((usr: any) => {
          return {
            user: {
              id: usr.id,
              email: usr.email,
              first_name: usr.first_name,
              last_name: usr.last_name
            },
            subscription_vendor: { name: selectedCompany?.name }
          };
        });

      setfetchedUsers([
        ...remainingSubscriptionUsers,
        ...(remainingPocUsers || [])
      ]);
    }
  }, [existingUsers, usersData, companyFieldValue, companyData]);

  const resetUsersInfoFields = () => {
    form.resetFields([
      "select_user",
      "first_name",
      "last_name",
      "email",
      "phone",
      "project_role_id"
    ]);
    setIsReadOnly(false);
  };

  const onSendInvite = async (values: any) => {
    setResponseLoading(true);

    const userMap: any = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      subscription_role_id: 3,
      project_role_id: values.project_role_id,
      company_id: values.company
    };
    if (values.phone) {
      userMap.phone = values.phone;
    }

    const requestedUsers = [userMap];
    try {
      const addUserProjectSubscriptionResponse =
        await addUserProjectSubscription({
          variables: { users: requestedUsers, projectId }
        });
      setResponseLoading(false);
      if (
        addUserProjectSubscriptionResponse?.errors &&
        addUserProjectSubscriptionResponse?.errors.length > 0
      ) {
        message.error(addUserProjectSubscriptionResponse.errors[0]?.message);
        return;
      }
      setDrawerOpen(false);

      if (
        addUserProjectSubscriptionResponse?.data?.insert_project_users_multi
          ?.message
      ) {
        message.success(
          addUserProjectSubscriptionResponse?.data?.insert_project_users_multi
            ?.message
        );
      } else {
        message.error("Unable to invite user to project.");
      }
    } catch (ex) {
      console.log(ex);
      message.error(ex);
    } finally {
      setResponseLoading(false);
    }
  };

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="flex-col h-full overflow-y overflow-x-hidden px-3">
        <Form
          preserve={false}
          onFinish={onSendInvite}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <FormItem name="company" label="Company">
            <Select
              loading={companyDataLoading}
              disabled={companyDataLoading}
              onChange={(value) => {
                const companyDetails = projectVendors?.find(
                  (x: any) => x.id === value
                );
                if (companyDetails) {
                  form.setFieldsValue({
                    company_type: companyDetails?.project_vendors?.[0]?.type_id,
                    project_role_id: null
                  });
                } else {
                  form.setFieldsValue({
                    company_type: null,
                    project_role_id: null
                  });
                }
                resetUsersInfoFields();
              }}
              showSearch
              filterOption={(input, option) => {
                const inputMes = input.toString().toLowerCase();
                const label = (option?.label || "").toLowerCase();
                return label.includes(inputMes);
              }}
              options={projectVendors?.map((c) => ({
                value: c.id,
                label: c.name
              }))}
            />
          </FormItem>

          {companyFieldValue && (
            <FormItem name="company_type" label="Role of Company">
              <Select
                loading={projectRolesloading}
                disabled
                onChange={() => {
                  form.setFieldsValue({
                    project_role_id: null
                  });
                }}
              >
                {userTypes &&
                  userTypes.user_types.map((type: Role) => (
                    <Option key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          )}

          {allUsers?.length > 0 && (
            <FormItem label="Select user" name="select_user">
              <Select
                placeholder="Select user"
                className="flex w-full font-normal text-slate-700"
                onChange={(index: any) => {
                  if (index === undefined) return;
                  const userdata = allUsers[index];
                  const fields: any = [
                    {
                      name: "first_name",
                      value: userdata.user.first_name
                    },
                    {
                      name: "last_name",
                      value: userdata.user.last_name
                    },
                    {
                      name: "email",
                      value: userdata.user.email
                    },
                    {
                      name: "phone",
                      value: userdata.user.phone
                    }
                  ];

                  form.setFields(fields);

                  if (Object.keys(userdata).length > 0) {
                    setIsReadOnly(true);
                  } else {
                    setIsReadOnly(false);
                  }
                }}
                showSearch
                allowClear
                onClear={() => resetUsersInfoFields()}
                filterOption={(input, option: any) => {
                  const { user } = allUsers.find(
                    (u: any) => u.user_id === option.key
                  );
                  const inputMes = input.toString().toLowerCase();
                  const userData = [
                    user.email.toLowerCase(),
                    `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`
                  ];
                  return userData.some((text) => text.includes(inputMes));
                }}
                notFoundContent={
                  vendorData ? (
                    <span>
                      No user found. Click <Link to="/users">here</Link> to add
                      users.
                    </span>
                  ) : (
                    <span>No user found, fill the below fields.</span>
                  )
                }
                loading={loading}
              >
                {allUsers?.map((item: any, index: number) => {
                  return (
                    <Option key={item.user_id} value={index}>
                      <div>
                        {item.user.first_name} {item.user.last_name}
                      </div>
                      <div className="text-xs text-slate-500">
                        {item.user.email}
                      </div>
                      <div className="text-xs text-slate-500">
                        {item?.subscription?.organization_subscriptions
                          ?.length > 0 && !item?.subscription_vendor
                          ? item?.subscription?.organization_subscriptions[0]
                              ?.organization?.name
                          : ""}
                        {item?.subscription_vendor?.name && (
                          <span> {item?.subscription_vendor?.name}</span>
                        )}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          )}
          {!vendorData && (
            <div className="flex text-sm text-[#3b3b3b] bg-[#F5F5F5] h-8 items-center -mx-3 pl-3 my-2">
              OR ADD USER DETAILS
            </div>
          )}

          <div>
            <FormItem
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.FirstName
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            <FormItem
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.LastName
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            <FormItem
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailRequired
                },
                {
                  type: "email",
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailInvalid
                },
                {
                  validateTrigger: "onSubmit",
                  validator(_, value: string) {
                    if (value === undefined || !value) return Promise.resolve();
                    const email = value.toLowerCase();
                    if (existingUsers.some((e) => e.user.email === email))
                      return Promise.reject();
                    return Promise.resolve();
                  },
                  message: ErrorMessages.EmailProjectUserExist
                }
              ]}
            >
              <Input
                disabled={
                  form.getFieldValue("email") &&
                  form.getFieldValue("email") !== ""
                }
              />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              rules={[
                {
                  message: ErrorMessages.PhoneNumber,
                  validateTrigger: "onSubmit",
                  pattern: Regex.phoneNumber
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            <FormItem
              name="project_role_id"
              label="Project Role"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.ProjectRole,
                  validator(_, value: string) {
                    if (!value) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                loading={projectRolesloading}
                disabled={!companyFieldValue}
                options={projectSubscriptionRoles?.project_role?.map(
                  (role: Role) => ({ value: role.id, label: role.name })
                )}
              />
            </FormItem>
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              disabled={responseLoading}
              loading={responseLoading}
              type="primary"
            >
              Send Invite
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteProjectUser;
