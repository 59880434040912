import { useMutation, useSubscription } from "@apollo/client";
import { Button, Form, Input, Select, message, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ErrorMessages, EUserTypes } from "constants/index";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { useContext, useEffect, useState } from "react";
import {
  SUBSCRIPTION_COMPANY_LIST,
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES
} from "services/graphQL/subscriptions";
import {
  MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE,
  MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE
} from "services/graphQL/mutations";
import { QUERY_TRADE_USER_TYPE } from "services/graphQL/queries";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import "./invite-acc-user.scss";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { InviteAccUserAction } from "utils/utils";
import { Role } from "./invite-subscription-user";

const { Option } = Select;

function InviteAccUserComponent(props: InviteAccUserAction) {
  const {
    setDrawerOpen,
    showDrawerOpen,
    modelTitle,
    userData,
    isFromProjectUser
  } = props;
  const [form] = Form.useForm();
  const [responseLoading, setResponseLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const { subscriptionRole } = useContext(SubscriptionContext);
  const [projectRoles, setprojectRoles] = useState<Role[]>([]);
  const { gqlClientForProject: gqlClient }: TProjectContext =
    useContext(ProjectContext);

  const { data: projectSubscriptionRoles, loading: projectRolesloading } =
    useSubscription(SUBSCRIPTION_PROJECT_ROLES, {
      shouldResubscribe: true,
      variables: {
        where: { type_id: { _eq: EUserTypes.GENERAL_CONTRACTOR } }
      },
      skip: !gqlClient
    });

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  const [inviteAccSubscriptionUserMutation] = useMutation(
    MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE
  );

  const [inviteAccProjectUserMutation] = useMutation(
    MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
    {
      client: gqlClient
    }
  );

  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    isFromProjectUser
      ? {
          shouldResubscribe: true,
          skip: !gqlClient,
          client: gqlClient
        }
      : { shouldResubscribe: true }
  );
  const [projectVendors, setProjectVendors] = useState<Array<any>>([]);
  const [companies, setCompanies] = useState<any>([]);
  const companyFieldValue = Form.useWatch("company", form);
  const [selectedUserData, setSelectedUserData] = useState(userData);

  const { data: userTypes } = useCIQQuery(QUERY_TRADE_USER_TYPE);

  useEffect(() => {
    if (companyData && companyData?.subscription_vendors) {
      const subscriptionVendors = companyData?.subscription_vendors || [];

      if (isFromProjectUser) {
        const vendors =
          subscriptionVendors.filter(
            (x: any) => x.project_vendors.length > 0
          ) || [];
        setProjectVendors(vendors);
      }

      try {
        const allCompanies = [...subscriptionVendors].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setCompanies(allCompanies);
        if (!isFromProjectUser) setProjectVendors(allCompanies);
      } catch (ex) {
        //
      }
    }
  }, [companyData, isFromProjectUser]);

  useEffect(() => {
    if (projectSubscriptionRoles) {
      setprojectRoles(projectSubscriptionRoles.project_role);
    }
  }, [projectSubscriptionRoles]);

  useEffect(() => {
    if (userSubscriptionRoles) {
      const subscriptionPermission =
        userSubscriptionRoles?.subscription_permission?.filter(
          (role: any) => role.key !== "subscription_none"
        ); // Remove None permission for editor

      if (subscriptionRole <= RoleSubscriptionEnum.subscription_editor) {
        const roles = subscriptionPermission?.filter(
          (role: any) => role.name !== "Admin"
        );
        setUserRoles(roles);
      } else {
        setUserRoles(subscriptionPermission);
      }
    }
  }, [subscriptionRole, userSubscriptionRoles]);

  useEffect(() => {
    const mapObj: any = {
      company: userData?.company_id,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      phone: userData?.phone
    };

    const companyDetails = projectVendors?.find(
      (x: any) => x.id === userData?.company_id
    );

    if (companyDetails) {
      form.setFieldsValue({
        company_type: companyDetails?.project_vendors?.[0]?.type_id,
        project_role_id: null
      });
    } else {
      mapObj.company = undefined;
      form.setFieldsValue({
        company: undefined,
        company_type: null,
        project_role_id: null
      });
    }
    if (isFromProjectUser) {
      mapObj.type = userData?.type;
    } else {
      const userRole = userRoles?.find(
        (role: any) => role?.key === "subscription_viewer"
      );
      mapObj.subscription_permission_id = userRole?.id || "";
    }

    if (projectVendors?.length) {
      setSelectedUserData(mapObj);
      form.setFieldsValue(mapObj);
    }
  }, [form, isFromProjectUser, projectVendors, userData, userRoles]);

  const onSendInvite = async (values: any) => {
    setResponseLoading(true);

    if (isFromProjectUser) {
      const res = await inviteAccProjectUserMutation({
        variables: {
          user_id: userData?.id,
          role_id: values?.role_id,
          invited_user_company_id: companyFieldValue
        }
      });
      if (res.data) {
        message.success("Invited successfully.");
        setDrawerOpen({ showInviteModel: false, inviteUserData: null });
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
      setResponseLoading(false);
      return;
    }

    const res = await inviteAccSubscriptionUserMutation({
      variables: {
        user_id: userData?.id,
        permission_id: values?.subscription_permission_id,
        invited_user_company_id: companyFieldValue
      }
    });
    if (res.data) {
      message.success("Invited successfully.");
      setDrawerOpen({ showInviteModel: false, inviteUserData: null });
    }
    if (res.errors) {
      message.error(res.errors[0].message);
    }
    setResponseLoading(false);
  };

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen({ showInviteModel: false, inviteUserData: null });
      }}
      destroyOnClose
    >
      <div className="flex-col h-full overflow-y overflow-x-hidden px-3 invite-acc-user">
        <Form
          preserve={false}
          onFinish={onSendInvite}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <div>
            <FormItem
              name="company"
              label="Company"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.CompanySelectionRequired,
                  validator(_, value: string) {
                    if (!value) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                loading={companyDataLoading}
                disabled={companyDataLoading || selectedUserData?.company}
                onChange={(value: string) => {
                  const companyDetails = projectVendors?.find(
                    (x: any) => x.id === value
                  );
                  if (companyDetails) {
                    form.setFieldsValue({
                      company_type:
                        companyDetails?.project_vendors?.[0]?.type_id,
                      project_role_id: null
                    });
                  } else {
                    form.setFieldsValue({
                      company_type: null,
                      project_role_id: null
                    });
                  }
                }}
                showSearch
                filterOption={(input, option: any) => {
                  const cmpny: any = companies.find(
                    (c: any) => c.id === option.key
                  );

                  if (!cmpny) return false;
                  const inputMes = input.toString().toLowerCase();
                  const companyDt = [cmpny?.name?.toLowerCase()];
                  return companyDt?.some((text) => text.includes(inputMes));
                }}
              >
                {projectVendors.map((company: any) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            {isFromProjectUser && companyFieldValue ? (
              <FormItem name="company_type" label="Role of Company">
                <Select
                  showArrow={false}
                  loading={projectRolesloading}
                  disabled
                  onChange={() => {
                    form.setFieldsValue({
                      project_role_id: null
                    });
                  }}
                >
                  {userTypes &&
                    userTypes.user_types.map((type: Role) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            ) : null}
            <FormItem name="first_name" label="First Name">
              <Input disabled />
            </FormItem>
            <FormItem name="last_name" label="Last Name">
              <Input disabled />
            </FormItem>
            <FormItem name="email" label="Email">
              <Input disabled />
            </FormItem>
            <FormItem name="phone" label="Phone Number">
              <Input disabled />
            </FormItem>
            {!isFromProjectUser && (
              <FormItem
                name="subscription_permission_id"
                label="Permission"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.SubscriptionRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select style={{ width: "100%" }}>
                  {userRoles?.length > 0 &&
                    userRoles?.map((role: Role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            )}
            {isFromProjectUser && (
              <FormItem
                name="role_id"
                label="Project Role"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.ProjectRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select>
                  {projectRoles.length > 0 &&
                    projectRoles.map((role: Role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              htmlType="submit"
              disabled={responseLoading || !projectVendors.length}
              loading={responseLoading}
              type="primary"
              data-testid="send-invite-btn"
            >
              Send Invite
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteAccUserComponent;
