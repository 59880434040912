import { useHistory, useParams } from "react-router";
import { Tabs } from "antd";
import { useContext, useState } from "react";
import "../../project-setting-menu/module-settings/module-settings.scss";
import { SubscriptionContext } from "context/SubscriptionProvider";
import DemoTextIcon from "components/svg-icons/demo-icon";
import { FeatureTypes } from "entity-app/constants";
import FeatureSubscriptionSettings from "./feature-subscription-settings";

function ModuleSubscriptionSettings() {
  const history = useHistory();
  const { tab2Id } = useParams() as any;

  const { isFeatureFlagEnabled } = useContext(SubscriptionContext);
  const isBuyoutEnabled = isFeatureFlagEnabled("BUYOUT");

  const [tabIndex, setTabIndex] = useState<string>(
    tab2Id || isBuyoutEnabled ? "designpackages" : "materials"
  );
  const tabItems = [
    ...(isBuyoutEnabled
      ? [
          {
            label: (
              <span>
                <DemoTextIcon /> Design Packages
              </span>
            ),
            key: "designpackages",
            children: (
              <FeatureSubscriptionSettings
                featureId={FeatureTypes.DESIGN_PACKAGE}
              />
            )
          },
          {
            label: (
              <span>
                <DemoTextIcon /> Bid Packages
              </span>
            ),
            key: "bidpackages",
            children: (
              <FeatureSubscriptionSettings
                featureId={FeatureTypes.BID_PACKAGE}
              />
            )
          }
        ]
      : []),
    {
      label: "Materials",
      key: "materials",
      children: (
        <FeatureSubscriptionSettings featureId={FeatureTypes.MATERIALS} />
      )
    }
  ];
  return (
    <div className="w-full py-1 h-full module-settings--tabs">
      <Tabs
        onChange={(activeKey) => {
          history.push(`/settings/modules/${activeKey.toString()}`);
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={tabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ModuleSubscriptionSettings;
