import { Button, Collapse, message } from "antd";
import DisplayNameListPopover from "components/bulk-edit-material/display-name-list";
import WorkflowTemplateBulkEditor from "components/workflow-template-bulk-editor";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { FeatureTypes } from "entity-app/constants";
import { gql } from "@apollo/client";
import { UPDATE_BID_PACKAGE_DETAILS_MULTI } from "entity-app/graphQL/ciq-feature-mutations";

const { Panel } = Collapse;

function transformToDateBlockData(feature: any): any {
  const formattedFeature: any = {
    id: feature.id,
    title: feature.title,
    float: feature.workflowInstance.float,
    workflow_status: feature.workflowInstance.workflow_status
  };

  const dbData = feature.workflowInstance?.workflow_instance_milestones.map(
    (milestone: any) => ({
      id: milestone.id,
      name: milestone.name,
      milestone_action: milestone.status,
      offsetName:
        milestone.feature_workflow_instance_offsets_as_src.length > 0
          ? milestone.feature_workflow_instance_offsets_as_src[0].name
          : "",
      sequence_no: milestone.sequence_no,
      value:
        milestone.feature_workflow_instance_offsets_as_src.length > 0
          ? milestone.feature_workflow_instance_offsets_as_src[0].duration
          : 0,
      workflow_template_offset_id:
        milestone.feature_workflow_instance_offsets_as_src.length > 0
          ? milestone.feature_workflow_instance_offsets_as_src[0]
              .project_template_offset.id
          : ""
    })
  );

  formattedFeature.date_block_data = dbData;

  return formattedFeature;
}

function PanelItem(props: {
  template: any;
  featureTypeId: FeatureTypes;
  onSaveSuccess: () => void;
}) {
  const { template, featureTypeId, onSaveSuccess } = props;

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationBulkUpdateBidPackageDetails] = useCIQMutation(
    gql(UPDATE_BID_PACKAGE_DETAILS_MULTI),
    {
      client: gqlClientForProject
    }
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [milestones, setMilestones] = useState<any[]>([]);
  const [float, setFloat] = useState("");

  const featuresData = useMemo(() => {
    return template.features.map((feature: any) =>
      transformToDateBlockData(feature)
    );
  }, [template.features]);

  const incomingSameFloatValueAcrossFeatures = useMemo(() => {
    if (!featuresData.length) return "";
    let hasSameValues = true;
    const firstFloatValue = featuresData[0].float;
    featuresData.forEach((feature: any) => {
      const floatValue = feature.float;
      if (floatValue !== firstFloatValue) {
        hasSameValues = false;
      }
    });
    if (hasSameValues) {
      return firstFloatValue;
    }

    return null;
  }, [featuresData]);

  useEffect(() => {
    setFloat(incomingSameFloatValueAcrossFeatures || "");
  }, [incomingSameFloatValueAcrossFeatures]);

  const incomingDateBlockData = useMemo(() => {
    if (!featuresData.length) return [];
    const DBmilestones = featuresData[0].date_block_data.map(
      (milestone: any, index: number) => {
        let hasSameValues = true;
        const firstFeatureOffsetValue = milestone.value;
        featuresData.forEach((feature: any) => {
          const offsetValue = feature.date_block_data[index].value;
          if (offsetValue !== firstFeatureOffsetValue) {
            hasSameValues = false;
          }
        });

        const milestoneToReturn = { ...milestone };

        if (hasSameValues) {
          milestoneToReturn.value = firstFeatureOffsetValue;
        } else {
          milestoneToReturn.value = null;
        }

        return milestoneToReturn;
      }
    );
    return DBmilestones.sort((a: any, b: any) => a.sequence_no - b.sequence_no);
  }, [featuresData]);

  // console.log("incomingDateBlockData ", incomingDateBlockData);

  useEffect(() => {
    setMilestones(incomingDateBlockData);
  }, [incomingDateBlockData]);

  const onCancelEdit = () => {
    setMilestones(incomingDateBlockData);
    setFloat(incomingSameFloatValueAcrossFeatures || "");
    setIsEditing(false);
  };

  const onSave = async (event: any) => {
    setIsSaving(true);
    event.stopPropagation();

    const filteredMilestones = milestones?.filter(
      (milestone: any) =>
        milestone.workflow_template_offset_id && milestone.value !== null
    );

    const payload: any = {
      featureInstanceIds: template.features.map((feature: any) => feature.id),
      offsetData: {
        offsets: filteredMilestones.map((milestone: any) => ({
          workflow_template_offset_id: milestone.workflow_template_offset_id,
          duration: milestone.value
        })),
        workflow_template_id: template.id,
        float: float || null,
        feature_type_id: featureTypeId
      }
    };

    const updateResponse = await mutationBulkUpdateBidPackageDetails({
      variables: payload
    });

    if (updateResponse.success) {
      onCancelEdit();
      message.success("Durations updated successfully");
      onSaveSuccess();
    }

    setIsSaving(false);
  };

  const panelHeader = (
    <div className="flex items-center justify-between">
      <div className="uppercase !text-gray-600">
        {template.name} (
        <span className="-ml-1">
          <DisplayNameListPopover
            list={template.features.map((feature: any) => {
              return { name: feature.title };
            })}
          >
            {template.features.length}
          </DisplayNameListPopover>
        </span>{" "}
        <span className="-ml-1.5">Bid Packages</span>)
      </div>
      <div>
        {!isEditing && (
          <Button
            type="primary"
            size="small"
            className="px-4"
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        )}
        {(isEditing || isSaving) && (
          <div className="space-x-3">
            <Button
              type="primary"
              size="small"
              className="px-4"
              onClick={onSave}
              disabled={isSaving}
              loading={isSaving}
            >
              Save
            </Button>
            <Button
              size="small"
              className="px-4"
              onClick={(event) => {
                event.stopPropagation();
                onCancelEdit();
              }}
              disabled={isSaving}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  let panelProps = {
    ...props
  };
  if (isEditing) {
    panelProps = {
      ...panelProps
    };
  }

  return (
    <Panel {...panelProps} header={panelHeader} key={template.id}>
      <WorkflowTemplateBulkEditor
        disabled={!isEditing}
        milestonesState={[milestones, setMilestones]}
        floatState={[float, setFloat]}
        featureType={FeatureTypes.BID_PACKAGE}
      />
    </Panel>
  );
}

export default PanelItem;
