import { Tooltip } from "antd";
import { RiskLevelType, TNewDateBlock } from "./models";
import EditThresholdPanel from "./edit-threshold-panel";
import { EWorkflowStatusDataBlock } from "../../constants";

function RiskStatementView(props: {
  dateBlocks: Array<TNewDateBlock> | undefined;
  disabled: boolean;
}) {
  const { dateBlocks, disabled } = props;
  const db = dateBlocks && dateBlocks.length > 0 ? dateBlocks[0] : null;
  if (!db) return null;

  const isSubmittalCompletedWorkflow =
    db.workflow_status === EWorkflowStatusDataBlock.WF_COMPLETED;
  if (isSubmittalCompletedWorkflow)
    return (
      <div className="flex text-sm text-[#3B3B3B]">{db.risk_assessment}</div>
    );

  return (
    <div className="flex space-x-2 items-center text-[#3B3B3B] text-sm">
      <div>Risk :</div>
      <Tooltip
        title={db.risk_assessment}
        className={
          db.risk_level === RiskLevelType.High
            ? "date-block-risk-high font-semibold cursor-default"
            : "font-semibold cursor-default"
        }
        placement="topRight"
      >
        {db.risk_level}
      </Tooltip>
      {!disabled && <EditThresholdPanel dateblock={db} />}
    </div>
  );
}
export default RiskStatementView;
