type UUID = string;

export interface IUser {
  user_id: UUID;
  first_name: string;
  last_name: string;
  email: string;
}

export interface IOffset {
  id: string;
  name: string;
  value: number;
  order: number;
}

export enum EstimateStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED"
}

export interface IMaterial {
  id: UUID;
  material_id: number;
  name: string;
  invite_id: UUID;
  spec_section_no: string;
  spec_section_name: string;
  material_tag: string;
  description: string;
  estimate_status: EstimateStatus;
  offsets: IOffset[];
  estimate_invite_feature_id: UUID;
}

export interface RequsetLeadTimeData {
  project_name: string;
  gc_company: string;
  materials: IMaterial[];
  project_id: UUID;
  assignee_first_name: string;
  assignee_last_name: string;
  assignee_vendor_name: string;
  assignee_organization_name: string;
  assignee_email: string;
  gc_first_name: string;
  gc_last_name: string;
  gc_organization_name: string;
  gc_email: string;
  notes: string;
  timezone_id: string;
  token: string;
  created_on: string;
}

export interface UpdateLeadTimeRequestItem {
  estimate_invite_feature_id: UUID;
  material_id: UUID;
  offsets: {
    id: string;
    value: number;
  }[];
}

export type UpdateLeadTimeRequestData = UpdateLeadTimeRequestItem[];
