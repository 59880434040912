import { Collapse } from "antd";
import { UserAvatar } from "components/user-avatar";
import Paragraph from "antd/lib/typography/Paragraph";
import { useMemo } from "react";
import { DateUtils } from "utils/dateutils";
import { EstimateStatus, RequsetLeadTimeData } from "./type-definitions";

function PanelHeader({
  requestDetails
}: {
  requestDetails: RequsetLeadTimeData;
}) {
  const submissionMetrics = useMemo(() => {
    let submittedCount = 0;
    let totalCount = 0;
    requestDetails.materials?.forEach((material) => {
      if (material.estimate_status === EstimateStatus.SUBMITTED) {
        submittedCount += 1;
      }
      totalCount += 1;
    });

    return {
      submitted: submittedCount,
      total: totalCount
    };
  }, [requestDetails]);

  return (
    <div className="w-full flex items-center space-x-2 py-1.5">
      <div className="grow gap-x-4 flex flex-wrap items-center">
        <span className="font-semibold">
          {requestDetails.project_name} | {requestDetails.gc_organization_name}
        </span>
        <span className="px-2.5 py-1.5 background-color-1 border border-solid border-color-3 rounded-md text-xs">
          <span className="font-semibold">
            {submissionMetrics.submitted} / {submissionMetrics.total}
          </span>{" "}
          Submitted
        </span>
      </div>
      <div className="shrink-0 expand-label text-color-1 text-xs underline">
        Show Details
      </div>
      <div className="shrink-0 collapse-label text-color-1 text-xs underline">
        Hide Details
      </div>
    </div>
  );
}

function UserInfo({
  title,
  userInfo
}: {
  title: string;
  userInfo: {
    fullName: string;
    organizationName: string;
    email: string;
  };
}) {
  return (
    <div>
      <div className="text-sm font-semibold">{title}</div>
      <div className="mt-4 flex items-start space-x-2">
        <div>
          <UserAvatar url="" fullName={userInfo.fullName} isRect={false} />
        </div>
        <div>
          <div className="text-sm font-semibold mb-1">
            {userInfo.organizationName}
          </div>
          <div className="text-sm text-color-1">{userInfo.fullName}</div>
          <div className="text-sm italic text-color-1">
            &lt;{userInfo.email}&gt;
          </div>
        </div>
      </div>
    </div>
  );
}

function RequestDetailsSection({
  requestDetails
}: {
  requestDetails: RequsetLeadTimeData;
}) {
  return (
    <div className="md:px-10 w-full bg-[#F7F6F4] shrink-0">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel
          header={<PanelHeader requestDetails={requestDetails} />}
          key="1"
        >
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <UserInfo
                title="Requested by:"
                userInfo={{
                  fullName: `${requestDetails.gc_first_name} ${requestDetails.gc_last_name}`,
                  organizationName: requestDetails.gc_organization_name,
                  email: requestDetails.gc_email
                }}
              />
              <div className="text-sm text-color-1 ml-9">
                {DateUtils.format(requestDetails.created_on)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <UserInfo
                title="Request sent to:"
                userInfo={{
                  fullName: `${requestDetails.assignee_first_name} ${requestDetails.assignee_last_name}`,
                  organizationName: requestDetails.assignee_vendor_name,
                  email: requestDetails.assignee_email
                }}
              />
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="text-sm font-semibold">
                General Contractor notes:
              </div>
              <div className="text-sm mt-4">
                <Paragraph
                  ellipsis={{ rows: 3 }}
                  title={requestDetails.notes}
                  className="text-color-1"
                >
                  {requestDetails.notes}
                </Paragraph>
              </div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default RequestDetailsSection;
