import SuccessCheckIcon from "components/svg-icons/success-icon";
import { Button, Result } from "antd";
import { EstimateStatus, IMaterial } from "./type-definitions";

export function VerticalNavList({
  items,
  onChange,
  activeKey
}: {
  items: IMaterial[];
  onChange: (item: IMaterial) => void;
  activeKey: string | null;
}) {
  const initialCssClassNames =
    "text-left bg-white cursor-pointer w-full px-6 py-2.5 pr-5 border-0 border-r-2 border-transparent border-solid flex items-center overflow-hidden";

  return (
    <div>
      {items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => (
          <button
            role="tab"
            type="button"
            tabIndex={0}
            className={`${initialCssClassNames} ${
              activeKey === item.id
                ? "bg-[#F0F0F0] !border-black font-semibold"
                : ""
            }`}
            onClick={() => {
              onChange(item);
            }}
            key={item.id}
            data-testid={`vertical-list-item-${item.id}`}
          >
            <div className="grow text-color-5">{item.name}</div>
            {item.estimate_status === EstimateStatus.SUBMITTED && (
              <div className="flex items-center justify-center">
                <SuccessCheckIcon />
              </div>
            )}
          </button>
        ))}
    </div>
  );
}

const LEAD_TIME_DATA_FETCH_ERROR_MSGS: { [key: string]: string } = {
  JWT_INVALID_OR_EXPIRED: "The link is invalid or expired.",
  FAILED_TO_FETCH_DATA: "Failed to fetch lead time data. Please try again."
};

export enum LeadTimeErrorCodes {
  JWT_INVALID_OR_EXPIRED = "JWT_INVALID_OR_EXPIRED",
  FAILED_TO_FETCH_DATA = "FAILED_TO_FETCH_DATA"
}

export function FailureSection({
  errorCode,
  onRetry
}: {
  errorCode: LeadTimeErrorCodes;
  onRetry: () => void;
}) {
  if (errorCode === LeadTimeErrorCodes.JWT_INVALID_OR_EXPIRED) {
    return (
      <Result
        status="error"
        title={LEAD_TIME_DATA_FETCH_ERROR_MSGS[errorCode]}
      />
    );
  }

  if (errorCode === LeadTimeErrorCodes.FAILED_TO_FETCH_DATA) {
    return (
      <Result
        status="error"
        title={LEAD_TIME_DATA_FETCH_ERROR_MSGS[errorCode]}
        subTitle="Please try again."
        extra={[
          <Button key="retry" onClick={onRetry}>
            Retry
          </Button>
        ]}
      />
    );
  }

  return null;
}
