import EditableDetailsCardWrapper from "entity-app/shared-components/editable-details-card-wrapper/editable-details-card-wrapper";
import { useContext, useMemo } from "react";
import FeatureAttachments from "components/feature-attachments";
import FeatureActivityCard from "components/feature-activity-card/feature-activity-card";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { ProjectPermissionEnum } from "constants/index";
import { TFetureDetailUpdateAction } from "entity-app/models";
import BidPackageEdtableGeneralInfo from "./components/bid-package-editable-general-info";
import Participants from "./components/participants";
import FeatureHeader from "./components/bid-package-header";
import OwnersInfo from "./components/owners-info";
import FeatureLinkedTopSection from "./components/faeture-linked-top-section";

function BidPackageDetails(props: {
  featureInstanceData: any;
  actions: TFetureDetailUpdateAction;
  featureId: string;
}) {
  const { featureInstanceData, actions, featureId } = props;
  const { tokenContents } = useContext(ProjectContext);

  const permissionsForActivityCard = useMemo(() => {
    return ["create_comment"];
  }, []);

  const cannotWriteAccessOfBidPackage = isPermissionNotGrantted(
    ProjectPermissionEnum.BidPackageWrite,
    tokenContents?.role!
  );

  return (
    <div className="space-y-4 mb-4">
      <FeatureHeader
        actions={actions}
        number={featureInstanceData?.number}
        headerTitle={featureInstanceData?.title}
        status={featureInstanceData?.feature_workflow_instance?.milestone_state}
        disabled={cannotWriteAccessOfBidPackage}
        feature="BID_PACKAGE"
      />
      <FeatureLinkedTopSection
        actions={actions}
        featureInstanceData={featureInstanceData}
        disabled={cannotWriteAccessOfBidPackage}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col gap-4">
          <EditableDetailsCardWrapper title="General Information">
            <BidPackageEdtableGeneralInfo
              featureInstanceData={featureInstanceData}
              actions={actions}
              disabled={cannotWriteAccessOfBidPackage}
            />
          </EditableDetailsCardWrapper>
          <EditableDetailsCardWrapper title="Bid Details">
            <Participants
              featureInstanceData={featureInstanceData}
              actions={actions}
              disabled={cannotWriteAccessOfBidPackage}
            />
          </EditableDetailsCardWrapper>
        </div>
        <div className="h-[800px] flex flex-col gap-y-4">
          <FeatureActivityCard
            historyTabContents={<div>History</div>}
            featureId={featureId}
            featureKey="feature_instance_id"
            permissions={permissionsForActivityCard}
            showHistoryTab={false}
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="h-[220px]">
            <EditableDetailsCardWrapper title="Leads">
              <OwnersInfo
                featureInstanceData={featureInstanceData}
                actions={actions}
                disabled={cannotWriteAccessOfBidPackage}
              />
            </EditableDetailsCardWrapper>
          </div>
          <div className="h-[563px]">
            <EditableDetailsCardWrapper title="Attachments">
              <FeatureAttachments
                featureId={featureId}
                featureKey="feature_instance_id"
              />
            </EditableDetailsCardWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidPackageDetails;
