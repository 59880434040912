import { Divider, Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useMemo, useState } from "react";
import "./index.scss";
import SpecNumberNameDropDown from "components/spec-number-name";
import { useMutation } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import { useProjectParticipants } from "hooks/project-participants";
import { INSERT_MATERIALS_MULTI_MUTATION } from "services/graphQL/mutations";
import { ExtractSpecType } from "./model";

const { Option } = Select;

type FormFieldsType = {
  trade_partner: undefined | string;
  assignee: null | string;
  assignee_unregistered: null | string;
  spec_section_id: null | string;
  spec_section_no: null | string;
  spec_section_name: null | string;
  gc_representative: null | string;
};

function CreateMaterialPopup({
  open,
  onClose,
  extractMat
}: {
  open: boolean;
  onClose: (isParentClose: boolean) => void;

  extractMat: ExtractSpecType | undefined;
}) {
  const [form] = useForm();
  const [suggestions, setSuggestions] = useState<
    Array<{ value: string; label?: string }>
  >([]);
  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const [formFieldsData, saveFieldChange] = useState<FormFieldsType>({
    trade_partner: undefined,
    assignee: null,
    assignee_unregistered: null,
    spec_section_id: null,
    spec_section_name: null,
    spec_section_no: null,
    gc_representative: null
  });

  const { gqlClientForProject } = useContext(ProjectContext);

  const [insertMaterialsMutation] = useMutation(
    INSERT_MATERIALS_MULTI_MUTATION,
    { client: gqlClientForProject }
  );

  const { projectParticipants } = useProjectParticipants(false);
  const gcUsers = useMemo(() => {
    return projectParticipants?.gcReviewers || [];
  }, [projectParticipants?.gcReviewers]);

  const tradePartnerOptions = useMemo(() => {
    return projectParticipants.materialTradePartners.map((company: any) => {
      return {
        id: company.vendor_id,
        name: company.subscription_vendor.name,
        companyInviteStatus: company.project_vendors_company_status?.status,
        ...company
      };
    });
  }, [projectParticipants.materialTradePartners]);

  const getAssigneeOptions = () => {
    const asigneeUsers = projectParticipants.submitterUsers.filter(
      (user: any) => {
        return user.company.vendor_id === formFieldsData.trade_partner;
      }
    );

    return asigneeUsers;
  };

  useEffect(() => {
    console.log(extractMat);
    if (
      extractMat &&
      extractMat.metadata?.row_data &&
      extractMat.metadata?.row_data?.length > 0
    ) {
      const keys = Object.keys(extractMat.metadata?.row_data[0]) || [];
      const options = keys
        .map((k) => ({ value: k }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));
      let tableName = extractMat?.metadata?.table_name || "";
      tableName = tableName
        .trim()
        .replace(/schedule$/gim, "")
        .trim();
      setSuggestions([
        { value: tableName, label: `Schedule name - "${tableName}"` },
        ...options
      ]);

      setTitle(tableName);
    } else {
      setSuggestions([]);
      setTitle("");
    }
  }, [extractMat]);

  const handleOk = async (values: any) => {
    setLoading(true);

    const getData = (key: string, row: any) => {
      if (values[key]) {
        return values[key] === title ? title : `${row[values[key]]}`;
      }
      return undefined;
    };

    const objects = extractMat?.metadata?.row_data.map((m) => {
      const name = getData("name_key", m);

      const quantity = Number(getData("quantity_key", m)) || undefined;
      const tagName = getData("tag_key", m);
      const manufacturer = getData("manufacturer_key", m);
      const size = getData("size_key", m);

      return {
        spec_page_extract_id: extractMat.id,
        name,
        tag_name: tagName,
        manufacturer,
        quantity,
        material_size: size,
        ...formFieldsData
      };
    });

    console.log(objects);

    if (objects) {
      const res = await insertMaterialsMutation({
        variables: { objects }
      });
      console.log(res);
      if (res.data) {
        message.success(
          `${res.data.insert_material.affected_rows} Materials were extracted from ${extractMat?.metadata?.table_name} drawings.`
        );
        onClose(true);
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
    }
    setLoading(false);
  };
  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      title={`Extract materials from - ${extractMat?.metadata?.table_name}`}
      open={open}
      className="material-extraction"
      onOk={form.submit}
      onCancel={handleCancel}
      okText="Extract"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      width={620}
    >
      <div className="text-[#3B3B3B] text-xs opacity-60 pt-2 pb-5">
        Instruction: Map the material fields below to the corresponding schedule
        header titles in the dropdown menu that were extracted from the
        drawings. To create the material logs from the extracted schedule, the
        material name is required.
      </div>
      <Form form={form} onFinish={handleOk} onReset={handleCancel}>
        <Form.Item
          name="name_key"
          label="Material Name"
          rules={[
            { required: true, message: "Please select for Material Name." }
          ]}
          required
        >
          <Select placeholder="Select Material Name" options={suggestions} />
        </Form.Item>
        <Form.Item name="tag_key" label="Material Tag">
          <Select
            placeholder="Select Material Tag"
            options={suggestions}
            allowClear
          />
        </Form.Item>
        <Form.Item name="manufacturer_key" label="Manufacturer">
          <Select
            placeholder="Select Manufacturer Name"
            options={suggestions}
            allowClear
          />
        </Form.Item>
        <Form.Item name="quantity_key" label="Quantity">
          <Select
            placeholder="Select Material Quantity"
            options={suggestions}
            allowClear
          />
        </Form.Item>
        <Form.Item name="size_key" label="Material Size">
          <Select
            placeholder="Select Material Size"
            options={suggestions}
            allowClear
          />
        </Form.Item>
        <Divider className="m-0" />
        <div className="font-medium pt-1 pb-3">Additional info</div>
        <Form.Item label="Spec Section">
          <SpecNumberNameDropDown
            onChange={(
              id: string | null,
              number: string | null,
              name: string | null
            ) => {
              saveFieldChange((pre) => ({
                ...pre,
                spec_section_id: id,
                spec_section_no: number,
                spec_section_name: name
              }));
            }}
          />
        </Form.Item>

        <Form.Item label="Responsible Contractor">
          <Select
            placeholder="Select Responsible Contractor"
            showSearch
            filterOption
            optionFilterProp="label"
            value={formFieldsData.trade_partner}
            onChange={async (value: string) => {
              saveFieldChange((pre) => ({
                ...pre,
                trade_partner: value,
                assignee: null,
                assignee_unregistered: ""
              }));
            }}
            notFoundContent="No companies added to this project."
          >
            {tradePartnerOptions?.map((company: any) => {
              return (
                <Option
                  key={company.id}
                  value={company.id}
                  label={company.name}
                >
                  {company.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Assignee">
          <Select
            placeholder="Select Assignee"
            showSearch
            filterOption
            optionFilterProp="label"
            disabled={!formFieldsData.trade_partner}
            value={
              formFieldsData.assignee || formFieldsData.assignee_unregistered
            }
            onChange={async (value: string) => {
              const selectedUser = getAssigneeOptions().find((user: any) => {
                return user.id === value;
              });
              if (selectedUser.type === "actual") {
                saveFieldChange((pre) => ({
                  ...pre,
                  assignee: value,
                  assignee_unregistered: ""
                }));
              } else {
                saveFieldChange((pre) => ({
                  ...pre,
                  assignee: null,
                  assignee_unregistered: value
                }));
              }
            }}
            notFoundContent="User not found. Go to Project Settings to add users"
          >
            {getAssigneeOptions().map((user: any) => (
              <Option
                key={user.id}
                value={user.id}
                label={`${user.first_name} ${user.last_name}`}
              >
                <div>
                  {user.first_name} {user.last_name}
                </div>
                <div className="text-sm text-gray-500">{user.company.name}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="GC Representative">
          <Select
            placeholder="Select GC Representative"
            showSearch
            filterOption
            optionFilterProp="label"
            value={formFieldsData.gc_representative}
            onChange={async (value: string) => {
              saveFieldChange((pre) => ({
                ...pre,
                gc_representative: value
              }));
            }}
            notFoundContent="User not found. Go to Project Settings to add users"
          >
            {gcUsers.map((user: any) => (
              <Option
                key={user.id}
                value={user.id}
                label={`${user.first_name} ${user.last_name}`}
              >
                <div>
                  {user.first_name} {user.last_name}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateMaterialPopup;
