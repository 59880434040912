import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import moment from "moment";
import MaterialPlanningNavbar from "./navbar";
import { FailureSection, VerticalNavList, LeadTimeErrorCodes } from "./widgets";
import "./material-planning.scss";
import { IMaterial } from "./type-definitions";
import MaterialDetailsSection from "./material-details-section";
import MaterialDurationsSection from "./material-durations-section";
import { getLeadTimeRequestDetails } from "./services";
import RequestDetailsSection from "./request-details-section";

function MaterialPlanning() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const leadTimeRequestToken: string | null = params.get("token");

  const [selectedMaterial, setSelectedMaterial] = useState<IMaterial | null>(
    null
  );
  const [leadTimeRequestData, setLeadTimeRequestData] = useState<any>(null);
  const [loadingLeadTimeRequestData, setLoadingLeadTimeRequestData] =
    useState(true);

  const [leadTimeDataFetchErrorCode, setLeadTimeDataFetchErrorCode] =
    useState<LeadTimeErrorCodes | null>(null);

  // Note: kept at a global level so that it can be used at other places
  const [submittingLeadTime, setSubmittingLeadTime] = useState(false);

  const getLeadTimeData = useCallback(async () => {
    if (!leadTimeRequestToken) {
      setLeadTimeDataFetchErrorCode(LeadTimeErrorCodes.JWT_INVALID_OR_EXPIRED);
      return;
    }

    setLoadingLeadTimeRequestData(true);
    const response = await getLeadTimeRequestDetails(leadTimeRequestToken!);
    if (response.success) {
      console.log(response.data);
      if (response.data.timezone_id) {
        moment.tz.setDefault(response.data.timezone_id);
      }

      setTimeout(() => {
        // so that moment timezone is properly set before rendering
        setLeadTimeRequestData(response.data);
        setLoadingLeadTimeRequestData(false);
      });
    }

    if (response?.error) {
      if (response?.error?.code === "ERR_BAD_REQUEST") {
        setLeadTimeDataFetchErrorCode(
          LeadTimeErrorCodes.JWT_INVALID_OR_EXPIRED
        );
      } else {
        setLeadTimeDataFetchErrorCode(LeadTimeErrorCodes.FAILED_TO_FETCH_DATA);
      }
    }
  }, [leadTimeRequestToken]);

  useEffect(() => {
    getLeadTimeData();
  }, [getLeadTimeData]);

  useEffect(() => {
    setSelectedMaterial(leadTimeRequestData?.materials[0]);
  }, [leadTimeRequestData]);

  if (leadTimeDataFetchErrorCode) {
    return (
      <FailureSection
        errorCode={leadTimeDataFetchErrorCode}
        onRetry={() => {
          setLeadTimeDataFetchErrorCode(null);
          getLeadTimeData();
        }}
      />
    );
  }

  if (!leadTimeRequestData && loadingLeadTimeRequestData) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="material-planning-page h-screen flex flex-col overflow-y-hidden">
      <div className="shrink-0 overflow-x-auto">
        <MaterialPlanningNavbar
          userName={`${leadTimeRequestData.assignee_first_name} ${leadTimeRequestData.assignee_last_name}`}
          companyName={
            leadTimeRequestData.assignee_vendor_name ||
            leadTimeRequestData.assignee_organization_name
          }
        />
      </div>
      <RequestDetailsSection requestDetails={leadTimeRequestData} />
      <div className="grow w-full flex bg-white overflow-hidden border-solid border-0 border-t border-color-1 overflow-x-auto">
        <div className="w-[200px] md:w-[350px] overflow-y-auto shrink-0">
          <div>
            <div className="p-5 text-sm font-semibold text-color-1">
              Requested Materials
            </div>
            <VerticalNavList
              items={leadTimeRequestData?.materials}
              onChange={(material) => {
                setSubmittingLeadTime(false);
                setSelectedMaterial(material);
              }}
              activeKey={selectedMaterial?.id ?? null}
            />
          </div>
        </div>
        <div className="grow border-0 border-l border-solid border-color-2 overflow-auto relative">
          {selectedMaterial && (
            <div className="flex items-start justify-between flex-wrap">
              <div className="w-full lg:sticky lg:top-0 lg:w-1/2">
                <MaterialDetailsSection
                  material={selectedMaterial}
                  key={selectedMaterial.id}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <MaterialDurationsSection
                  material={selectedMaterial}
                  key={selectedMaterial.id}
                  token={leadTimeRequestData.token}
                  submittingState={[submittingLeadTime, setSubmittingLeadTime]}
                  onSubmitSuccess={() => {
                    getLeadTimeData();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MaterialPlanning;
