/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Dropdown, Button } from "antd";
import LinkIcon from "components/svg-icons/link-icon";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import { SUBSCRIPTION_SUBMITTALS_LINKING_BY_ID } from "services/graphQL/subscriptions";
import "./index.scss";
import Search from "antd/lib/input/Search";
import {
  QUERY_GANTT_TASKS_LIST,
  QUERY_PROJECT_MATERIALS,
  QUERY_SUBMITTALS_LINKING_TABLE
} from "services/graphQL/queries";
import { GridLoadingIndicator } from "components/widgets";
import ErrorBoundary from "components/error-boundary";
import SubmittalIcon from "components/svg-icons/submittal-icon-2";
import GoverningLegend from "components/governing-legend";
import Tooltip from "antd/es/tooltip";
import RedirectIcon from "components/svg-icons/redirect-icon";
import { mergedStrings } from "utils/utils";
import {
  MaterialLinkedType,
  MaterialType,
  ScheduleLinkingType,
  SubmittalType
} from "./models";
import ActivityLinking from "./activityLinking";
import MaterialLinking from "./materialLinking";
import TrackMaterial from "./trackMaterial";
import TrackActivity from "./trackActivity";
import { ProjectPermissionEnum } from "../../constants";

function SubmittalLinking() {
  // State defination
  const history = useHistory();
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const { gqlClientForProject } = useContext(ProjectContext);

  const [submittalList, setSubmittalList] = useState(
    [] as Array<SubmittalType>
  );
  const [selectedSubmittalId, setSelectedSubmittalId] = useState<string>("");
  const [selectedSubmittal, setSelectedSubmittal] = useState<SubmittalType>();
  const [submittalSearch, setSubmittalSearch] = useState("");

  const [activityList, setActivityList] = useState([] as Array<any>);
  const [materialList, setMaterialList] = useState([] as Array<MaterialType>);
  const [selectedMaterial, setSelectedMaterial] = useState<
    MaterialLinkedType | undefined
  >(undefined);
  const [showActivityLinking, setShowActivityLinking] = useState(true);
  const [loadingSubmittalDetail, setLoadingSubmittalDetail] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const { tokenContents, projectDetails } = useContext(ProjectContext);
  const isPermissionNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const { data: submittalsRaw, loading: loadingSubmittalsRow } = useQuery(
    QUERY_SUBMITTALS_LINKING_TABLE,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: tasksData, refetch: reFetchActivity } = useQuery(
    QUERY_GANTT_TASKS_LIST,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: projectMaterials } = useQuery(QUERY_PROJECT_MATERIALS, {
    client: gqlClientForProject,
    fetchPolicy: "no-cache"
  });
  const { data: submittalById } = useSubscription(
    SUBSCRIPTION_SUBMITTALS_LINKING_BY_ID,
    {
      client: gqlClientForProject,
      variables: {
        id: selectedSubmittalId
      },
      shouldResubscribe: true,
      skip: !selectedSubmittalId,
      onData: () => {
        setLoadingSubmittalDetail(false);
      },
      onError: () => {
        setLoadingSubmittalDetail(false);
      }
    }
  );

  // Api Subscription End

  const projectWithSpecSection = projectDetails
    ? projectDetails?.spec_section
    : null;

  useEffect(() => {
    setSubmittalList(submittalsRaw?.submittals || []);
    const submittalId = new URLSearchParams(search).get("SubmittalId");
    const selectedSubmittalObj = submittalsRaw?.submittals?.find(
      (item: SubmittalType) => item?.id === submittalId
    );
    if (selectedSubmittalObj) {
      setSelectedSubmittalId(selectedSubmittalObj.id || "");
      setLoadingSubmittalDetail(true);
    }
  }, [
    submittalsRaw?.submittals?.sort(
      (current: SubmittalType, next: SubmittalType) =>
        (+current?.submittal_id || 0) - (+next?.submittal_id || 0)
    )
  ]);

  useEffect(() => {
    if (submittalById?.submittals_by_pk) {
      const submittalByIdObj = submittalById.submittals_by_pk;
      (submittalByIdObj?.submittal_material_links || []).sort(
        (current: MaterialLinkedType, next: MaterialLinkedType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      (submittalByIdObj?.submittal_schedule_links || []).sort(
        (current: ScheduleLinkingType, next: ScheduleLinkingType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      setSubmittalList(
        submittalList.map((value: SubmittalType) => {
          if (value.id === submittalByIdObj.id) {
            return submittalByIdObj;
          }
          return value;
        })
      );
      setSelectedSubmittal(submittalByIdObj);
      const selectedMaterialObj =
        submittalByIdObj?.submittal_material_links?.find(
          (item: MaterialLinkedType) =>
            item?.material?.id === selectedMaterial?.material?.id
        );
      if (selectedMaterialObj) {
        setSelectedMaterial(selectedMaterialObj);
      } else if (
        submittalByIdObj?.material_tracking &&
        submittalByIdObj?.submittal_material_links?.length > 0
      ) {
        const drivingIndex =
          submittalByIdObj?.submittal_material_links?.findIndex(
            (item: MaterialLinkedType) => {
              return item.driving_material;
            }
          );
        setSelectedMaterial(
          submittalByIdObj?.submittal_material_links[
            drivingIndex === -1 ? 0 : drivingIndex
          ]
        );
      } else {
        setSelectedMaterial(undefined);
      }
    }
  }, [submittalById]);

  useEffect(() => {
    setActivityList(
      (tasksData?.gantt_tasks || [])
        ?.filter((ts: any) => ts.type !== 3 && ts.type !== 4)
        .sort((current: any, next: any) =>
          current?.source_task_id?.localeCompare(next?.source_task_id)
        )
    );
  }, [tasksData]);
  useEffect(() => {
    setMaterialList(
      projectMaterials?.material?.sort(
        (current: MaterialType, next: MaterialType) => {
          return (+current?.material_id || 0) - (+next?.material_id || 0);
        }
      ) || ([] as Array<MaterialType>)
    );
  }, [projectMaterials]);

  // Effects Handeling End

  const getSelectedSubmittalLabel = () => {
    const selectedSubmittalObj = submittalList?.find((item: SubmittalType) => {
      return item?.id === selectedSubmittalId;
    });

    const titleStr = projectWithSpecSection
      ? mergedStrings([
          selectedSubmittalObj?.spec_no || "",
          selectedSubmittalObj?.submittal_id || "",
          selectedSubmittalObj?.title || ""
        ])
      : mergedStrings([
          selectedSubmittalObj?.submittal_id || "",
          selectedSubmittalObj?.title || ""
        ]);

    return selectedSubmittalObj ? (
      <div className="flex items-center linking-container--nav__dropdown--selected">
        {selectedSubmittalObj?.spec_no || selectedSubmittalObj?.spec_name ? (
          <Tooltip
            title={
              <div className="space-y-1 py-1">
                <div className="text-xs">
                  {mergedStrings([
                    selectedSubmittalObj?.spec_no || "",
                    selectedSubmittalObj?.spec_name || ""
                  ])}
                </div>
                <div>{mergedStrings([selectedSubmittalObj?.title || ""])}</div>
              </div>
            }
          >
            <span className="flex-grow truncate">{titleStr}</span>
          </Tooltip>
        ) : (
          <span className="flex-grow truncate">{titleStr}</span>
        )}

        {(selectedSubmittalObj?.submittal_material_links.filter(
          (material: MaterialLinkedType) => {
            return !material.material.implicit;
          }
        )?.length || 0) +
          (selectedSubmittalObj?.submittal_schedule_links?.length || 0) >
        0 ? (
          <div className="flex items-center">
            <LinkIcon />
            <DownOutlined className="ml-1" />
          </div>
        ) : (
          <div className="flex items-center">
            <DownOutlined className="ml-4" />
          </div>
        )}
      </div>
    ) : (
      <div className="flex items-center linking-select-empty">
        Select a Submittal
        <DownOutlined className="ml-4" />
      </div>
    );
  };

  const menu = () => {
    if (loadingSubmittalsRow) {
      return (
        <div className="linking-container--nav__loading">
          <LoadingOutlined />
        </div>
      );
    }
    if (submittalList.length === 0) {
      return (
        <div className="linking-container--nav__loading">
          No Submittal Available
        </div>
      );
    }
    return (
      <div className="linking-container--nav__dropdown">
        <div className="linking-container--nav__dropdown--search">
          <Search
            className="linking-search"
            placeholder="Search Submittal Here"
            value={submittalSearch}
            onChange={(e) => setSubmittalSearch(e.currentTarget.value)}
          />
        </div>
        {submittalList
          .filter((item) => {
            const label =
              `${item.submittal_id} ${item?.title}`.toLocaleLowerCase();
            return label.includes(submittalSearch.toLocaleLowerCase());
          })
          .map((item) => (
            <div
              className="linking-container--nav__option flex items-center justify-between"
              key={item.id}
              onClick={() => {
                setOpenDropdown(false);
                if (selectedSubmittalId === item.id) {
                  return;
                }
                history.push(
                  `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${item.id}`
                );
                setLoadingSubmittalDetail(true);
                setSelectedSubmittalId(item.id);
                setSelectedSubmittal(undefined);
                setSelectedMaterial(undefined);
              }}
            >
              {item.spec_no || item.spec_name ? (
                <Tooltip
                  mouseEnterDelay={0.5}
                  title={
                    <div className="space-y-1 py-1">
                      <div className="text-xs">
                        {mergedStrings([
                          item.spec_no || "",
                          item.spec_name || ""
                        ])}
                      </div>
                      <div>{mergedStrings([item.title || ""])}</div>
                    </div>
                  }
                >
                  <span className="items-center w-full truncate">
                    {projectWithSpecSection
                      ? mergedStrings([
                          item.spec_no || "",
                          item.submittal_id || "",
                          item.title || ""
                        ])
                      : mergedStrings([
                          item.submittal_id || "",
                          item.title || ""
                        ])}
                  </span>
                </Tooltip>
              ) : (
                <span className="items-center w-full truncate">
                  {mergedStrings([item.submittal_id || "", item.title || ""])}
                </span>
              )}
              {(item?.submittal_material_links?.filter(
                (material: MaterialLinkedType) => {
                  return !material?.material?.implicit;
                }
              ).length || 0) +
                (item?.submittal_schedule_links?.length || 0) >
              0 ? (
                <LinkIcon />
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
    );
  };

  const onMaterialClick = (material: MaterialLinkedType) => {
    setSelectedMaterial(material);
  };

  return (
    <ErrorBoundary>
      <div className="linking-page flex flex-col text-xs">
        <div
          className={`linking-container ${
            selectedSubmittal ? "" : "linking-container--empty"
          }`}
        >
          <div className="linking-container--nav justify-between">
            <div className="flex items-center">
              <div className="flex items-center">
                <SubmittalIcon className="mr-2" />
                <h3>SUBMITTAL</h3>
              </div>

              <Dropdown
                overlay={menu()}
                className="mx-4 linking-container--nav__select"
                trigger={["click"]}
                open={openDropdown}
                onOpenChange={(open) => {
                  setOpenDropdown(open);
                }}
              >
                <Button>{getSelectedSubmittalLabel()}</Button>
              </Dropdown>

              {selectedSubmittal && (
                <>
                  <Tooltip title="Open Submittal Detail">
                    <Link
                      className="flex linking-container--nav__redirectlinkIcon"
                      to={`/project/${projectId}/submittals/${selectedSubmittalId}`}
                      target="_blank"
                    >
                      <RedirectIcon />
                    </Link>
                  </Tooltip>
                  <div>
                    <TrackMaterial
                      selectedSubmittal={selectedSubmittal}
                      canEdit={!isPermissionNotGrant}
                    />
                  </div>
                </>
              )}
            </div>
            <GoverningLegend />
          </div>
          {selectedSubmittal ? (
            <div className="linking-group">
              <div
                className={`linking-group--left ${
                  selectedSubmittal?.material_tracking && showActivityLinking
                    ? "show-material"
                    : ""
                }`}
              >
                {selectedSubmittal?.material_tracking && (
                  <MaterialLinking
                    materialList={materialList}
                    selectedSubmittal={selectedSubmittal}
                    activityList={activityList}
                    userSelectedMaterial={selectedMaterial}
                    onMaterialClick={onMaterialClick}
                    reFetchActivity={reFetchActivity}
                    canEdit={!isPermissionNotGrant}
                  />
                )}
                <section className="flex justify-end p-[10px]">
                  <TrackActivity
                    selectedSubmittal={selectedSubmittal}
                    onChanges={(value: boolean) =>
                      setShowActivityLinking(value)
                    }
                    canEdit={!isPermissionNotGrant}
                  />
                </section>
                {showActivityLinking && (
                  <ActivityLinking
                    activityList={activityList}
                    selectedSubmittal={selectedSubmittal}
                    reFetchActivity={reFetchActivity}
                    canEdit={!isPermissionNotGrant}
                  />
                )}
              </div>
              <div className="linking-group--right" id="linkingOptionContainer">
                <div className="linking-group--right--nav">
                  <h2>Instructions</h2>
                </div>
                <div className="linking-group--right--content">
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 1:</b>{" "}
                      <span className="grow">
                        Choose a Submittal from the dropdown menu.
                      </span>
                    </p>
                  </div>
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 2:</b>{" "}
                      <span className="grow">
                        Click &quot;Associate Material&quot; to link between a
                        Material and the selected Submittal.
                      </span>
                    </p>
                  </div>
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 3:</b>{" "}
                      <span className="grow">
                        Click &quot;Associate Activity&quot; to link an Activity
                        with the associated Material.
                      </span>
                    </p>
                  </div>
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 4:</b>{" "}
                      <span className="grow">
                        Check the &quot;Link submittal with activities&quot;
                        checkbox and click &quot;Associate Activity&quot; to
                        directly connect the selected Submittal with an
                        Activity.
                      </span>
                    </p>
                  </div>
                  <div className="linking-group--right--content__instruction">
                    <p className="flex items-strech">
                      <b className="mr-1 min-w-[60px]">Step 5:</b>{" "}
                      <span className="grow">
                        Material tracking is enabled by default. To detach the
                        linked Material from the Submittal, disable the checkbox
                        &quot;Track Material&quot;. If you wish to monitor the
                        material later on, simply re-check the &quot;Track
                        Material&quot; checkbox.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : loadingSubmittalDetail ? (
            <div className="linking-loading--container">
              <div>
                <GridLoadingIndicator />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default SubmittalLinking;
