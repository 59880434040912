import AppLogoIcon from "components/svg-icons/app-logo-icon";
import UserAvatar from "components/user-avatar/user-avatar";

function MaterialPlanningNavbar(props: {
  userName: string;
  companyName: string;
}) {
  const { userName, companyName } = props;
  return (
    <div className="w-full h-[45px] flex items-stretch justify-between bg-white px-4">
      <div className="w-[120px] flex items-center justify-center">
        <AppLogoIcon />
      </div>
      <div className="flex items-center justify-center gap-2.5">
        <div className="py-0.5">
          <div className="text-color-3 text-sm leading-tight font-semibold">
            {userName}
          </div>
          <div className="text-color-1 text-xs leading-tight font-semibold">
            {companyName}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <UserAvatar url="" fullName={userName} isRect={false} />
        </div>
      </div>
    </div>
  );
}

export default MaterialPlanningNavbar;
