import { Collapse, Form, Tooltip } from "antd";
import { Link } from "react-router-dom";
import PdfAttachmentIcon from "components/svg-icons/pdf-attachment-icon";

import "../links.scss";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import { useCallback, useEffect, useState } from "react";
import LinkIcon from "components/svg-icons/link-icon";
import AsterixIcon from "components/svg-icons/asterix-icon";
import { DateUtils } from "utils/dateutils";
import { ProjectSettingType } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  ErrorMessages,
  SubmittalStatusToStrMap,
  noDocumentMessage
} from "../../../constants";

const { Panel } = Collapse;

type TMaterialLinksProps = {
  materialDetailsState: any;
  projectId: string;
  canOpenLinkingPage: boolean;
  canEditLinkingPage: boolean;
  projectDetails: ProjectSettingType | undefined;
};

function MaterialLinks(props: TMaterialLinksProps) {
  const {
    materialDetailsState,
    canOpenLinkingPage,
    canEditLinkingPage,
    projectId,
    projectDetails
  } = props;

  const [submittalTypes, setSubmittalTypes] = useState([]);

  const { data: submittalTypesData } = useCIQQuery(QUERY_SUBMITTAL_TYPES);

  useEffect(() => {
    setSubmittalTypes(submittalTypesData?.configurations_value);
  }, [submittalTypesData]);

  const submittalPanelHeader = (link: any) => {
    const displaySubmittalId =
      projectDetails?.spec_section && link.spec_no
        ? `${link.spec_no} - ${link.submittal_sequence_id}`
        : `${link.submittal_sequence_id}`;
    return (
      <div
        className="flex items-center"
        title={`${displaySubmittalId}-${link?.title}`}
      >
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>
        <i className="opacity-60">
          {displaySubmittalId}
          <span className="mx-1">-</span>
        </i>
        <span className="w-full truncate overflow-hidden mr-5">
          {link?.title}
        </span>
      </div>
    );
  };

  // const getSpecInfo = (spec_section_no: string, spec_section_name: string) => {
  //   if (spec_section_no && spec_section_name)
  //     return `${spec_section_no} - ${spec_section_name}`;
  //   if (spec_section_no) return spec_section_no;
  //   return "";
  // };

  // const getSubmitterInfo = (submittal: any) => {
  //   if (submittal.submittal_user) {
  //     return `${submittal.submittal_user.first_name} ${submittal.submittal_user.last_name} - ${submittal.submittal_user.project_users[0].subscription_vendor.name}`;
  //   }
  //   if (submittal.submitter_unregistered) {
  //     return submittal.submitter_unregistered;
  //   }
  //   return "NA";
  // };

  const getSubmittalType = useCallback(
    (typeId: any) => {
      if (!submittalTypes || !submittalTypes.length) return "";
      const foundType: any = submittalTypes.find((sType: any) => {
        return sType.id === typeId;
      });
      return foundType.value;
    },
    [submittalTypes]
  );

  const schedulePanelHeader = (link: any) => {
    const headerMarkup = (
      <div
        className="flex items-center"
        title={`${link?.gantt_task?.source_task_id}-${link?.gantt_task?.text}`}
      >
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>
        <i className="opacity-60">
          {link?.gantt_task?.source_task_id}
          {link?.gantt_task?.source_task_id && !link.driving_task && (
            <span className="mx-1">-</span>
          )}
        </i>
        {link.driving_task && (
          <span className="flex items-center mx-1 mb-1">
            <AsterixIcon />
          </span>
        )}
        <span className="truncate overflow-hidden">
          {link?.gantt_task?.text}
        </span>
        {link.linked_to_end_date !== null &&
          (link?.gantt_task?.start_date || link?.gantt_task?.end_date) && (
            <i className="opacity-60 px-1 w-[90px]">
              {link?.gantt_task?.start_date && link.linked_to_end_date === false
                ? DateUtils.format(link?.gantt_task?.start_date)
                : null}
              {link?.gantt_task?.end_date && link.linked_to_end_date === true
                ? DateUtils.format(link?.gantt_task?.end_date)
                : null}
            </i>
          )}
        {link.linked_to_end_date === null && link?.gantt_task?.start_date && (
          <i className="opacity-60 px-1 w-[90px]">
            DateUtils.format(link?.gantt_task?.start_date)
          </i>
        )}
      </div>
    );

    if (canOpenLinkingPage) {
      return (
        <Link
          to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
          className="text-inherit hover:text-inherit"
        >
          {headerMarkup}
        </Link>
      );
    }
    return headerMarkup;
  };

  const getAttachmentsForSubmittal = (submittal: any) => {
    return submittal.submittal_attachments.filter(
      (attachment: any) =>
        !attachment.version_of &&
        attachment.submittal_revision === submittal.revision
    );
  };

  return (
    <div>
      <Form.Item
        label={
          <div className="flex space-x-2 items-center">
            <span>
              SUBMITTALS
              {materialDetailsState?.submittal_material_links?.length
                ? ` (${materialDetailsState?.submittal_material_links.length})`
                : ""}
            </span>
          </div>
        }
      >
        {materialDetailsState?.submittal_material_links.length > 0 ? (
          <Collapse expandIconPosition="end" className="custom-collapse">
            {materialDetailsState?.submittal_material_links.map((link: any) => {
              const { submittal } = link;
              if (!submittal)
                return (
                  <Panel
                    header={
                      <Tooltip title={ErrorMessages.SubmittalAccessDenied}>
                        {submittalPanelHeader(link)}
                      </Tooltip>
                    }
                    key={link.submittal_id}
                    className="custom-panel"
                    collapsible="disabled"
                  />
                );
              const type = getSubmittalType(submittal.type);
              const status =
                SubmittalStatusToStrMap[submittal?.workflow_status];
              const attachments: any[] = getAttachmentsForSubmittal(submittal);
              return (
                <Panel
                  header={submittalPanelHeader(link)}
                  key={submittal.id}
                  className="custom-panel truncate"
                >
                  <div className="space-y-2.5">
                    <div className="flex">
                      <div title={type} className="w-1/4">
                        <div className="form-field-heading">TYPE</div>
                        <div className="text-xs opacity-60 truncate">
                          {type}
                        </div>
                      </div>
                      <div title={status} className="w-2/4">
                        <div className="form-field-heading">STATUS</div>
                        <div className="text-xs opacity-60">({status})</div>
                      </div>
                      <div title={status} className="w-1/4 text-right">
                        <Link
                          to={`/project/${projectId}/submittals/${submittal?.id}`}
                          className="view-details-link"
                        >
                          View submittal
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="form-field-heading mb-1">
                        SUBMITTAL ATTACHMENTS
                      </div>
                      {attachments.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                          {attachments.map((attachment: any) => {
                            return (
                              <Link
                                to={`/project/${projectId}/submittals/${submittal.id}?tab=2&aId=${attachment.id}`}
                                title={attachment.file_name}
                                className="attachment flex items-center min-w-0 cursor-pointer"
                                key={attachment.id}
                              >
                                <div className="w-6 shrink-0 flex items-center justify-center">
                                  <PdfAttachmentIcon />
                                </div>
                                <div className="truncate">
                                  {attachment.file_name}
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="text-xs text-neutral-400">
                          {noDocumentMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            SCHEDULE ACTIVITIES
            {materialDetailsState?.material_schedule_links?.length
              ? ` (${materialDetailsState?.material_schedule_links.length})`
              : ""}
          </span>
        }
      >
        {materialDetailsState?.material_schedule_links.length > 0 ? (
          <Collapse
            accordion
            expandIconPosition="end"
            className="custom-collapse"
          >
            {materialDetailsState?.material_schedule_links.map((link: any) => {
              return (
                <Panel
                  header={schedulePanelHeader(link)}
                  key={link.task_id}
                  className="custom-panel"
                  collapsible="disabled"
                />
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
    </div>
  );
}

export default MaterialLinks;
