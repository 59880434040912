import * as Sentry from "@sentry/react";
import cumulativeErrorMessageUtil from "./cumulative-error-message-util";
import { ErrorMessages, ErrorTypes } from "../../constants";

export default function ErrorManager() {
  const errorMapper = [
    {
      matchError: (errorStr: string) => {
        return ErrorTypes.netWorkError(errorStr);
      },
      errorMsg: ErrorMessages.NetworkError.offlineMessage
    },
    {
      matchError: (errorStr: string) => {
        return ErrorTypes.fieldNotFound(errorStr);
      },
      errorMsg: ErrorMessages.FieldNotFoundMessage
    }
  ];

  const handleError = (error: any, showError: boolean = true) => {
    const errorStr = error.response?.data?.error || error?.message;
    let errorMsg = null;

    if (showError) {
      errorMapper.every((errorTypeObj) => {
        if (errorTypeObj.matchError(errorStr)) {
          errorMsg = errorTypeObj.errorMsg;
          return false;
        }
        return true;
      });

      if (!errorMsg) {
        errorMsg = ErrorMessages.DefaultFailureMessage;
      }

      cumulativeErrorMessageUtil.showError(errorMsg);
    }

    Sentry.captureException(error);
    return errorStr;
  };

  return { handleError };
}
