import { Collapse } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useMemo } from "react";
import { QUERY_GET_DB_TEMPLATES } from "services/graphQL/queries";
import ErrorBoundary from "components/error-boundary";
import { FEATURE_WORKFLOW_INSTANCE_BY_IDS } from "entity-app/graphQL/ciq-feature-queries";
import { EWorkflowStatusDataBlock } from "../../constants";
import PanelItem from "./panel-item";
import { FeatureWorkflowInstance, PropsEditDuration } from "./definitions";

const labelsByFeatureTypeId: any = {
  [FeatureTypes.BID_PACKAGE]: {
    selectedRowsLabel: "Selected Bid Packages:"
  }
};

function FeatureBulkEditDurations(props: PropsEditDuration) {
  const { selectedRows, featureTypeId } = props;

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const { data: DBTemplatesData } = useCIQQuery(QUERY_GET_DB_TEMPLATES, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      feature_id: FeatureTypes.BID_PACKAGE
    }
  });

  const {
    data: featureWorkflowInstanceData,
    refetch: refetchFeatureWorkflowInstanceData
  }: { data: any; refetch: any } = useCIQQuery(
    FEATURE_WORKFLOW_INSTANCE_BY_IDS,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      variables: { featureIds: selectedRows.map((row) => row.id) }
    }
  );

  const selectedRowsWithWorkflowData: any = useMemo(() => {
    if (!featureWorkflowInstanceData) return [];

    return selectedRows
      .filter((row: any) => {
        return row?.workflow_status !== EWorkflowStatusDataBlock.WF_COMPLETED;
      })
      .map((row) => {
        const workflowInstance =
          featureWorkflowInstanceData.feature_workflow_instance.find(
            (instance: FeatureWorkflowInstance) =>
              instance.feature_instance.id === row.id
          );
        return { ...row, workflowInstance };
      });
  }, [featureWorkflowInstanceData, selectedRows]);

  const DBTemplates = useMemo(() => {
    if (!DBTemplatesData) return null;
    return DBTemplatesData.project_workflow_templates;
  }, [DBTemplatesData]);

  const templateWithSelectedFeatures = useMemo(() => {
    if (!DBTemplates || !featureWorkflowInstanceData) return null;
    return DBTemplates.map((mapTemplate: any) => {
      const template: any = { ...mapTemplate };
      template.features = [];
      selectedRowsWithWorkflowData.forEach((row: any) => {
        if (template.id === row.workflowInstance.workflow_template_id) {
          template.features.push(row);
        }
      });
      return template;
    });
  }, [DBTemplates, featureWorkflowInstanceData, selectedRowsWithWorkflowData]);

  return (
    <div className="px-2 -mt-2">
      <div className="font-semibold">
        <span className="tracking-wide">
          {labelsByFeatureTypeId[featureTypeId].selectedRowsLabel}
        </span>{" "}
        {selectedRows.length}
      </div>

      {templateWithSelectedFeatures &&
        templateWithSelectedFeatures?.length !== 0 && (
          <div className="mt-2">
            Durations for completed milestones will not be updated.
          </div>
        )}
      {templateWithSelectedFeatures &&
        templateWithSelectedFeatures?.length === 0 && (
          <div className="mt-2 font-semibold">
            Durations cannot be edited as all the selected materials are closed.
          </div>
        )}

      {templateWithSelectedFeatures?.length !== 0 && (
        <div className="mt-4">
          <ErrorBoundary>
            <Collapse className="ciq-collapse" expandIconPosition="end">
              {templateWithSelectedFeatures?.map((template: any) => {
                if (!template.features.length) return null;
                return (
                  <PanelItem
                    template={template}
                    key={template.id}
                    featureTypeId={FeatureTypes.BID_PACKAGE}
                    onSaveSuccess={() => {
                      refetchFeatureWorkflowInstanceData();
                    }}
                  />
                );
              })}
            </Collapse>
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
}

export default FeatureBulkEditDurations;
