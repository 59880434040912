import { useCallback, useContext, useMemo } from "react";
import { SubmittalDiff } from "pages/schedule/schedule-page/compare-schedule-2/SubmittalDiff";
import { DateUtils } from "utils/dateutils";
import {
  computeXPosition,
  getNormalizedDate
} from "pages/schedule/schedule-page/compare-schedule-2/TemporalLayout";
import { SubmittalDiffV6 } from "pages/schedule/schedule-page/compare-schedule-2/SubmittalDiffV6";
import { ProjectContext } from "context/ProjectProvider";
import { Milestone } from "../../pages/schedule/schedule-page/compare-schedule-2/model";
import {
  ImpactVisualisationDetailProps,
  RiskLevelType,
  TNewDateBlock
} from "./models";
import { ESubmittalStatus } from "../../constants";

type InputSubmittalType = {
  submittal_id: number;
  date_block_submittals: Array<TNewDateBlock>;
  material_tracking: boolean;
  submittal_material_links: Array<any>;
  workflow_status: number;
};

type GoverningTaskType = {
  id: string;
  source_task_id: string;
  text: string;
};

const transformDateBlockToImpactVisualisation = (
  inputDateBlock: InputSubmittalType,
  governingTask?: GoverningTaskType
): ImpactVisualisationDetailProps => {
  const isCompletedWorkflow =
    inputDateBlock.workflow_status === ESubmittalStatus.DONE;
  const db = inputDateBlock.date_block_submittals[0];
  const WIDTH = 1500;
  const width = WIDTH;
  const projectId = db.project_id;
  const todayData = {
    todayDate: DateUtils.dateTimeObj() // Client Today's date
  };

  // console.log("inputDateBlock =>", inputDateBlock);

  const assignedToSCDateString =
    db.actual_milestone_1 || db.projected_milestone_1 || db.planned_milestone_1;

  const getNextMilestoneName = () => {
    if (!db.planned_next_milestone_date) return "";
    if (
      db.planned_next_milestone_date === db.planned_last_milestone_date ||
      db.planned_next_milestone_date === assignedToSCDateString
    )
      return "";
    const dateBlock = db as any;
    for (let index = 1; index <= 10; index += 1) {
      const plannedDate = dateBlock[`planned_milestone_${index}`];
      if (!plannedDate) return "";
      if (plannedDate === db.planned_next_milestone_date)
        return dateBlock[`name_milestone_${index}`];
    }
    return "";
  };

  const beforeFinalDeadlineMilestone = {
    date: DateUtils.dateTimeObj(db.final_deadline_date),
    label: "Final deadline",
    type: "final_deadline",
    normalizedDate: getNormalizedDate(
      DateUtils.dateTimeObj(db.final_deadline_date)
    ),
    otherInfo: governingTask?.text ? `(${governingTask?.text})` : ""
  };
  const getMaterialROJ = (material: any) => {
    const propertyNamePrefix = "name_milestone_";
    const propertyPlannedPrefix = "planned_milestone_";
    let planned = null;
    let name = null;
    if (material?.date_block_materials.length > 0) {
      const mDateBlock = material?.date_block_materials[0];
      for (let index = 1; index <= 10; index = 1 + index) {
        if (!mDateBlock[`${propertyPlannedPrefix}${index}`]) {
          return { planned, name };
        }
        name = mDateBlock[`${propertyNamePrefix}${index}`];
        planned = mDateBlock[`${propertyPlannedPrefix}${index}`];
      }
    }
    return { planned, name };
  };
  const linkedMaterials = inputDateBlock.submittal_material_links
    .filter((x) => x.driving_material)
    .map((m) => {
      const rojInfo = getMaterialROJ(m.material);
      return {
        material_id: m.material_id,
        id: m.material_id,
        implicit: m.implicit,
        material_sequence_id: m.material_sequence_id,
        name: m.material_name,
        description: "",
        start_date: db.final_deadline_date,
        end_date: rojInfo.planned,
        linked_to_end_date: false,
        roj_label: rojInfo.name || "ROJ"
      };
    });

  const beforeData = {
    primaryElementName: `Submittal ${inputDateBlock.submittal_id}`,
    isPlannedOrActualDateAvailable: !!(
      db.actual_milestone_1 || db.planned_milestone_1
    ),
    milestones: [
      {
        date: DateUtils.dateTimeObj(assignedToSCDateString),
        label: db.name_milestone_1,
        type: "assigned_sc",
        normalizedDate: getNormalizedDate(
          DateUtils.dateTimeObj(assignedToSCDateString)
        ),
        otherInfo: ""
      },
      {
        date: DateUtils.dateTimeObj(
          db.projected_last_milestone_date || db.planned_last_milestone_date
        ),
        label: "Submittal distributed",
        type: "submittal_distributed",
        normalizedDate: getNormalizedDate(
          DateUtils.dateTimeObj(
            db.projected_last_milestone_date || db.planned_last_milestone_date
          )
        ),
        otherInfo: ""
      },
      beforeFinalDeadlineMilestone,
      {
        date: DateUtils.dateTimeObj(
          db.projected_last_milestone_date || db.planned_last_milestone_date
        ),
        label: "Last planned milestone",
        type: "last_planned_milestone",
        normalizedDate: getNormalizedDate(
          DateUtils.dateTimeObj(
            db.projected_last_milestone_date || db.planned_last_milestone_date
          )
        ),
        otherInfo: ""
      }
    ],
    linkedMaterials, // [], // oldGoverningMaterials
    siCalculations: {
      effectiveFloat:
        db.effective_float === null ? db.float : db.effective_float,
      currentDelay: db.current_delay
    },
    ...todayData
  };

  if (!isCompletedWorkflow)
    beforeData.milestones.push({
      date: DateUtils.dateTimeObj(
        db.projected_next_milestone_date || db.planned_next_milestone_date
      ),
      label: "Next planned milestone",
      type: "next_planned_milestone",
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(
          db.projected_next_milestone_date || db.planned_next_milestone_date
        )
      ),
      otherInfo: getNextMilestoneName()
    });

  const linkedMaterialsMilestones: Milestone[] = [];
  linkedMaterials.forEach((material) => {
    const materialStartDate = material.start_date;
    if (materialStartDate) {
      linkedMaterialsMilestones.push({
        date: DateUtils.dateTimeObj(materialStartDate),
        label: "Material start date",
        type: "material_start",
        normalizedDate: getNormalizedDate(
          DateUtils.dateTimeObj(materialStartDate)
        ),
        otherInfo: ""
      });
    }
    linkedMaterialsMilestones.push({
      date: DateUtils.dateTimeObj(material.end_date),
      label: "Required on job",
      type: "old_roj",
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(material.end_date)
      ),
      otherInfo: ""
    });
  });

  const beforeDataMilestonesDates = beforeData.milestones.map((m) => m.date);
  const linkedMaterialsMilestonesDates = linkedMaterialsMilestones.map(
    (m) => m.date
  );

  const MILESTONE_WIDTH = 250;
  const xLocationsByDate = computeXPosition(
    [...beforeDataMilestonesDates, ...linkedMaterialsMilestonesDates],
    width,
    DateUtils.dateTimeObj(), // Client Today's date
    MILESTONE_WIDTH
  );
  const maxLocationXWithPadding =
    Math.max(...Object.values(xLocationsByDate)) + 50;
  const impactProps = {
    ...beforeData,
    xLocationsByDate,
    width: maxLocationXWithPadding > width ? maxLocationXWithPadding : width,
    projectId,
    isCompletedWorkflow
  } as ImpactVisualisationDetailProps;

  return impactProps;
};

type Props = {
  submittal: InputSubmittalType;
  governingTask?: GoverningTaskType;
};
function DateBlockSubmittalImpactVisualisation({
  submittal,
  governingTask
}: Props) {
  const { isFeatureFlagEnabled } = useContext(ProjectContext);
  const isV6FeatureEnabled = isFeatureFlagEnabled("SI_V6");

  const impactVisualisationProps = useMemo(() => {
    return submittal && submittal.date_block_submittals?.length > 0
      ? transformDateBlockToImpactVisualisation(submittal, governingTask)
      : undefined;
  }, [submittal, governingTask]);

  const riskAssessment = useMemo(
    () =>
      submittal && submittal.date_block_submittals?.length > 0
        ? {
            risk_assessment: submittal.date_block_submittals[0].risk_assessment,
            risk_level: submittal.date_block_submittals[0].risk_level
          }
        : {
            risk_assessment: null,
            risk_level: null
          },
    [submittal]
  );

  const hasFinalDeadLineDate = useCallback(() => {
    const db: any =
      submittal?.date_block_submittals && submittal?.date_block_submittals[0];
    return db?.final_deadline_date != null;
  }, [submittal]);

  const isCompletedWorkflow =
    submittal?.workflow_status === ESubmittalStatus.DONE;

  return impactVisualisationProps &&
    impactVisualisationProps.isPlannedOrActualDateAvailable &&
    hasFinalDeadLineDate() ? (
    <div
      className="p-2 pt-6 whitespace-normal"
      style={{ width: impactVisualisationProps?.width }}
    >
      {isCompletedWorkflow ? (
        <div className="text-sm">{riskAssessment.risk_assessment}</div>
      ) : (
        <div>
          <div className="flex space-x-2 text-sm text-[#3B3B3B]">
            <div>Risk :</div>
            <div
              className={
                riskAssessment.risk_level === RiskLevelType.High
                  ? "date-block-risk-high font-semibold"
                  : "font-semibold"
              }
            >
              {riskAssessment.risk_level}
            </div>
          </div>
          <div className="text-wrap-on-print pb-2 space-x-2">
            <span>Assessment :</span>
            <span
              className={
                riskAssessment.risk_level === RiskLevelType.High
                  ? "assessment-text-color"
                  : ""
              }
            >
              {riskAssessment.risk_assessment}
            </span>
          </div>
        </div>
      )}

      {isV6FeatureEnabled ? (
        <SubmittalDiffV6 {...impactVisualisationProps} />
      ) : (
        <SubmittalDiff {...impactVisualisationProps} />
      )}
    </div>
  ) : (
    <div className="h-[200px] w-full text-2xl flex justify-center items-center">
      No final deadline set. Please link the submittal to a material/task or set
      the deadline manually.
    </div>
  );
}
export default DateBlockSubmittalImpactVisualisation;
