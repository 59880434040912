export const voidStartingSpaceInput = (e: any) => {
  if (/^\s/.test(e.target.value)) e.target.value = "";
};

export const isNumeric = (text: string) => {
  return /^-?\d+$/.test(text);
};

export const restrictInputToNumbers = (e: any) => {
  if (
    ["e", "E", "+", "-"].includes(e.key) ||
    (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab")
  ) {
    e.preventDefault();
  }
};
