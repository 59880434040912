import { useEffect } from "react";
import { useHistory, useParams } from "react-router";

import "./schedule-menu-tabs.scss";

function ScheduleMenuTabs() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  useEffect(() => {
    history.replace(`/project/${projectId}/schedule/project-schedule`);
  }, [history, projectId]);

  return <div />;
}
export default ScheduleMenuTabs;
