import {
  ENVIRONMENT_NAME,
  GA_MEASUREMENT_ID,
  GTAG_URL,
  SENTRY_DSN
} from "config/config";
import * as Sentry from "@sentry/react";
import { getLoggedinUserId } from "services/auth";
import { isLocalhostDevEnv } from "./utils";

function initGA() {
  if (GTAG_URL && GA_MEASUREMENT_ID && !isLocalhostDevEnv()) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `${GTAG_URL}?id=${GA_MEASUREMENT_ID}`;
    document.body.appendChild(script);
    const windowRef: any = window as any;
    windowRef.dataLayer = windowRef.dataLayer || [];
    windowRef.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      windowRef.dataLayer.push(arguments);
    };
    windowRef.gtag("js", new Date());
  }
}

function updateGAConfigWithUserId() {
  const windowRef: any = window as any;
  const loggedInUserId: string = getLoggedinUserId();
  if (windowRef.gtag && loggedInUserId) {
    windowRef.gtag("config", GA_MEASUREMENT_ID, {
      user_id: loggedInUserId
      // debug_mode: true
    });
  }
}

/**
 * Setup the Sentry tool for our website
 * Features:
 * - Error catching
 * - Browser performance matrix
 * - Replay functionality
 */
function initSentry() {
  if (SENTRY_DSN && !isLocalhostDevEnv()) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true
        })
      ],
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      environment: ENVIRONMENT_NAME || "Dummy"
    });
  }
}

export { initGA, updateGAConfigWithUserId, initSentry };
