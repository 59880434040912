import { Select } from "antd";
import { forwardRef, useState, useImperativeHandle } from "react";

type Role = {
  id: number;
  name: string;
  type_id: number;
};

const ProjectUserRoleSelectCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, value, context } = props;
  const { allProjectRoles } = context;
  const [selectValue, setSelectValue] = useState(value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });

  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      onChange={(id) => {
        setSelectValue(() => id);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
      loading={!allProjectRoles}
      options={
        allProjectRoles
          ? allProjectRoles.map((role: Role) => ({
              value: role.id,
              label: role.name
            }))
          : []
      }
    />
  );
});

export default ProjectUserRoleSelectCellEditor;
