/* eslint-disable jsx-a11y/label-has-associated-control */
// import { DropDownData } from "constants/index.js";
import {
  EditOutlined,
  InfoCircleOutlined,
  LinkOutlined
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  message,
  Popover,
  Radio,
  Select,
  Switch,
  Tag,
  Tooltip
} from "antd";
import {
  AcceptanceStatus,
  EUserRoleName,
  EWorkflowStatusDataBlock,
  EmailInviteStatus,
  ErrorMessages,
  IntegrationAuthStates,
  IntegrationSystemNameMap,
  SourceNames
} from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import {
  SubscriptionContext,
  RoleSubscriptionEnum
} from "context/SubscriptionProvider";
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  MUTATION_CANCEL_SUBSCRIPTION_INVITATION,
  MUTATION_RESEND_EMAIL_INVITE,
  MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
  MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE
} from "services/graphQL/mutations";
import SubmittalMicroVisualiser from "components/submittal-details/submittal-micro-viz";
import {
  getProjectUserSources,
  getSubscriptionUserSources,
  getUserCompany
} from "utils/utils";
import "./cell-renders.css";
import { agGridCheckFieldEditable } from "components/submittal-details/helpers";
import { ICellRendererParams } from "ag-grid-community";
import CustomTooltip from "components/custom-tooltip/custom-tooltip";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { DateUtils } from "utils/dateutils";
import ErrorBoundary from "components/error-boundary";
import DateBlockSubmittalImpactVisualisation from "components/date-block/submittal-impact-visualise";
import SubmittalMicroVizDateBlock from "components/submittal-details/submittal-microviz-dateblock";
import SpecSectionIcon from "components/svg-icons/spec-section-icon";
import { CopyIcon } from "components/svg-icons";
import DefaultTag from "components/widgets/default-tag";
import InfoIconPath from "assets/svg/i-icon.svg";
import CopyDBTemplate from "popups/copy-db-template";
import UserTag from "components/user-tag/user-tag";
import modal from "antd/lib/modal";
import ProcoreLogoIcon from "components/svg-icons/procore-logo-icon";
import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import AutodeskLogoIcon from "components/svg-icons/autodesk-logo-icon";
import AuthenticationExpiredIcon from "components/svg-icons/authentication-expired-icon";
import { RiskLevelType } from "components/date-block/models";
import FeatureSetStatus from "components/feature-status";
import PersonEditIcon from "components/svg-icons/person-edit-icon";
import PersonCheckIcon from "components/svg-icons/person-check-icon";
import BIM360logo from "../../assets/image/bim360-logo.png";

export function IdLinkComponent(props: any) {
  const { value, data, projectId, context } = props;
  const cellText =
    context.projectDetails?.spec_section && data.spec_no
      ? `${data.spec_no} - ${value}`
      : `${value}`;

  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${projectId}/submittals/${data.id}`
      }}
    >
      {cellText}
    </Link>
  );
}

export function MaterialIdLinkCellRenderer(params: any) {
  const { data, value, context } = params;
  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${context.projectId}/materials/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function SubmittalIdLinkCellRenderer(params: any) {
  const { data, value, projectId } = params;
  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${projectId}/submittals/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function IdLinkProjectSumbittalListComponent(props: any) {
  const { value, data, disabled = false, showToolTipMsg, tooltipMsg } = props;

  const userTypeId = data?.user_type_id;

  if (disabled && showToolTipMsg) {
    return (
      <Tooltip title={tooltipMsg} placement="right">
        <label>{value}</label>
      </Tooltip>
    );
  }
  if (disabled && !showToolTipMsg) {
    return <label>{value}</label>;
  }
  return (
    <Link
      to={{
        pathname: `project/${data.id}/${
          userTypeId === 1 || userTypeId === 2 || userTypeId === 3
            ? "dashboard"
            : "submittals"
        }`
      }}
    >
      {value}
    </Link>
  );
}

export const submittalCellRenderer = (props: any) => {
  const { data, context, projectId } = props;
  return (
    <div className="w-full">
      <div className="pl-3 !flex !items-center space-x-1">
        <Link
          className="gridIdLink grow truncate text-left"
          to={{
            pathname: `/project/${projectId}/submittals/${data.id}`
          }}
        >
          {data.title || <>&nbsp;</>}{" "}
        </Link>
        <EditOutlined
          className="cell-edit-icon px-2"
          onClick={() => {
            context.onEditCell({
              rowIndex: props.node.rowIndex!,
              colKey: props.column!.getId(),
              data
            });
          }}
        />
      </div>
      <p className="colSecondValue pl-3">{data.description || <>&nbsp;</>}</p>
    </div>
  );
};

// export const formatDate = (dateStr: string) => {
//   const possibleFormats = [DATE_FORMAT_MMDDYYYY, "YYYY-MM-DD"];
//   if (dateStr) {
//     const autoFormatted = moment(dateStr).format(DATE_FORMAT_MMDDYYYY);

//     if (autoFormatted !== "Invalid date") return autoFormatted;

//     for (let p = 0; p < possibleFormats.length; p += 1) {
//       const formatted = moment(dateStr, possibleFormats[p]).format(
//         DATE_FORMAT_MMDDYYYY
//       );
//       if (formatted !== "Invalid date") return formatted;
//     }
//   }
//   return "";
// };

export const dateCellRenderer = (params: any) => {
  const formattedDate = DateUtils.format(params.value);
  return (
    <div className="w-full flex items-center">
      <div className="truncate grow">
        {formattedDate}
        {/* <p className="colSecondValue">
          {formattedDate ? DateUtils.dateTimeObj(formattedDate).fromNow() : ""}
        </p> */}
      </div>
      <div className="px-1">
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
};

export const dueDateCellRenderer = (params: any) => {
  const todayStartDateObject = DateUtils.dateTimeObj().startOf("day");
  const dueDateObject = DateUtils.dateTimeObj(params.value);
  const isPast = dueDateObject.isBefore(todayStartDateObject);
  const cellValueClassnames = isPast ? "date-block-date-risk" : "";
  return (
    <div className="w-full flex items-center">
      <div className={`truncate grow ${cellValueClassnames}`}>
        {params.value}
      </div>
      <div className="px-1">
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
};

const dueDateHeaderTemplate = (title: string) => ({
  template: `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                  <img src='${InfoIconPath}' title='${title}' class="info-icon">
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`
});

export const submittalDueDateHeaderComponent = (params: any) => {
  const { context } = params;
  const headerTitle = context?.submittalHeaderMap?.due_date || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} should be the minimum of Projected Date of Last Milestone and Final Deadline.`;
  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const materialDueDateHeaderComponent = (params: any) => {
  const { context } = params;
  const headerTitle = context?.materialHeaderMap?.due_date || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} should be the minimum of Projected Date of Last Milestone and Final Deadline.`;

  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const bidPackageDueDateHeaderComponent = (params: any) => {
  const { displayName } = params;
  const headerTitle = displayName || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} should be the minimum of Projected Date of Last Milestone and Final Deadline.`;

  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const projectDateCellRenderer = (params: any) => {
  if (params.data?.status_id === AcceptanceStatus.ACTIVE) {
    return dateCellRenderer(params);
  }
  return null;
};

export function CellValueRendererWithEditIcon(params: any) {
  const { value, context, rowIndex, column, data } = params;
  return (
    <div className="w-full flex items-center">
      <div className="grow overflow-hidden truncate">{value ?? ""}</div>
      <div>
        <EditOutlined
          className="cell-edit-icon"
          onClick={() => {
            if (context.onEditCell)
              context.onEditCell({
                rowIndex,
                colKey: column!.getId(),
                data
              });
          }}
        />
      </div>
    </div>
  );
}

export function CellCurrencyRendererWithEditIcon(params: any) {
  const { value, context, rowIndex, column, data } = params;
  return (
    <div className="w-full flex items-center">
      <div className="grow overflow-hidden truncate">$ {value}</div>
      <div>
        <EditOutlined
          className="cell-edit-icon"
          onClick={() => {
            if (context.onEditCell)
              context.onEditCell({
                rowIndex,
                colKey: column!.getId(),
                data
              });
          }}
        />
      </div>
    </div>
  );
}

export function EditableCell({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex">
      <div className="grow overflow-hidden truncate">{children ?? ""}</div>
      <div>
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
}

export const editSubmittalFloatCellRenderer = (params: any) => {
  const { data, node, column, context } = params;
  return (
    <button
      type="button"
      className="border-0 bg-transparent w-full"
      onClick={() => {
        context.onEditCell({
          rowIndex: node.rowIndex!,
          colKey: column!.getId(),
          data
        });
      }}
    >
      <EditableCell>{params.value}</EditableCell>
    </button>
  );
};

export const userCellRenderer = (params: any) => {
  const { value, context, dataMapVar } = params;
  if (!value || !context[dataMapVar][value]) return null;
  try {
    return (
      <div className="flex w-full">
        <div className="grow">
          {value && (
            <div>
              <p className="colFirstValue">
                {`${context[dataMapVar][value].user.first_name} ${context[dataMapVar][value].user.last_name}`}
              </p>
              <p className="colSecondValue">
                {getUserCompany(context[dataMapVar][value])}
              </p>
            </div>
          )}
        </div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
};

export function specSectionRenderer(params: any) {
  const { data, specNoField, specNameField, context } = params;

  try {
    let specSectionStr = data[specNoField] || "";
    if (data[specNoField] && data[specNameField]) {
      specSectionStr += " - ";
    }
    specSectionStr += data[specNameField] || "";
    return (
      <div className="w-full h-full flex items-center">
        <div className="grow truncate  items-center">{specSectionStr}</div>
        <div>
          <EditOutlined
            className="cell-edit-icon"
            onClick={() => {
              context.onEditCell({
                rowIndex: params.node.rowIndex!,
                colKey: params.column!.getId(),
                data
              });
            }}
          />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function specSectionRendererWithFileViewer(props: any) {
  const { data, specNoField, specNameField, context } = props;

  try {
    let specSectionStr = data[specNoField] || "";
    if (data[specNoField] && data[specNameField]) {
      specSectionStr += " - ";
    }
    specSectionStr += data[specNameField] || "";

    return (
      <div className="w-full max-w-full h-full flex items-center overflow-hidden">
        <button
          type="button"
          className="w-full max-w-full grow truncate border-0 bg-transparent text-left flex items-center"
          onClick={() => {
            context.onEditCell({
              rowIndex: props.node.rowIndex!,
              colKey: props.column!.getId(),
              data
            });
          }}
        >
          <div className="w-full max-w-full truncate">{specSectionStr}</div>
          <div className="-mt-1">
            <EditOutlined className="cell-edit-icon" />
          </div>
        </button>
        {context?.isDesignTabEnabled && data?.spec_section_file_key ? (
          <button
            type="button"
            className="spec-file-icon shrink-0 max-w-full -mt-0.5 ml-1 bg-white m-0 p-0 border-0 cursor-pointer leading-none"
            onClick={() => {
              context.onViewSpecSection(data);
            }}
          >
            <div>
              <SpecSectionIcon />
            </div>
          </button>
        ) : null}
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function userNameRenderer(params: any) {
  const { data } = params;
  try {
    let userNameStr = data.user?.first_name || "";
    if (data.user?.last_name) {
      userNameStr += `  ${data.user?.last_name}`;
    }
    return (
      <div className="w-full flex">
        <div className="grow">{userNameStr}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function userRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data.subscription_permission.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function projectUserRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data.project_role?.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function projectCompanyRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data?.project_vendors_type?.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export const dropDownCellRenderer = (params: any) => {
  const { context, dataMapVar, labelVar } = params;

  // if (!params.value) return null;

  const getText = () => {
    if (params.value) {
      const mapObject = context[dataMapVar];
      const selectedValue = mapObject[params.value];
      if (!selectedValue) return null;
      return selectedValue[labelVar];
    }
    return "";
  };

  try {
    const text = getText();
    if (!text) return null;
    return (
      <div className="w-full flex items-center">
        <div className="grow truncate status-col">{text}</div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return null;
  }
};

export const dropDownCellRendererWithCustomEdit = (props: any) => {
  const { context, dataMapVar, labelVar } = props;

  const getText = () => {
    if (props.value) {
      const mapObject = context[dataMapVar];
      const selectedValue =
        mapObject[props.value] ||
        mapObject.find((x: any) => x?.value === props.value);
      if (!selectedValue) return null;
      return selectedValue[labelVar];
    }
    return "";
  };

  try {
    const text = getText();
    return (
      <button
        type="button"
        className="bg-transparent border-0 w-full flex items-center"
        onClick={() => {
          context.onEditCell({
            rowIndex: props.node.rowIndex!,
            colKey: props.column!.getId()
          });
        }}
      >
        <div className="grow truncate text-left">{text}</div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </button>
    );
  } catch (ex) {
    return null;
  }
};

function InviteStatusText(props: {
  statusId: number;
  userId: string;
  inviteType: string | null;
  isCIQUser: boolean;
  isInviteBtnVisible?: boolean;
  context?: any;
  userData?: any;
  roleId?: any;
}) {
  const {
    statusId,
    userId,
    inviteType,
    isCIQUser,
    isInviteBtnVisible,
    context,
    userData,
    roleId
  } = props;

  const { subscriptionRole } = useContext(SubscriptionContext);
  const { tokenContents } = useContext(ProjectContext);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [cancelprogess, setCancelProgress] = useState(false);
  const [resendprogess, setResendProgress] = useState(false);

  const [resendAccProjectUserInviteMutation] = useMutation(
    MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        role_id: roleId,
        invited_user_company_id: userData?.company_id
      },
      client: gqlClientForProject
    }
  );

  const [resendAccSubscriptionUserInviteMutation] = useMutation(
    MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        permission_id: roleId,
        invited_user_company_id: userData?.company_id
      }
    }
  );

  const [resendInviteMutation] = useMutation(MUTATION_RESEND_EMAIL_INVITE, {
    variables: {
      user_id: userId,
      invited_user_company_id: userData?.company_id
    },
    client: gqlClientForProject
  });

  const [resendSubscriptionInviteMutation] = useMutation(
    MUTATION_RESEND_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        invited_user_company_id: userData?.company_id
      }
    }
  );
  const [cancelSubscriptionInviteMutattion] = useMutation<any>(
    MUTATION_CANCEL_SUBSCRIPTION_INVITATION,
    {
      variables: { user_id: userId }
    }
  );

  const resendInvite = () => {
    setResendProgress(true);

    if (inviteType === "Subscription") {
      (isCIQUser
        ? resendSubscriptionInviteMutation()
        : resendAccSubscriptionUserInviteMutation()
      )
        .then((res) => {
          if (res.data) {
            message.success(res.data.resend_email_invite.message);
          }
          if (res.errors) {
            message.error(res.errors[0].message);
          }
        })
        .finally(() => setResendProgress(false));
    } else {
      (isCIQUser
        ? resendInviteMutation()
        : resendAccProjectUserInviteMutation()
      )
        .then((res) => {
          if (res.data) {
            message.success(res.data.resend_email_invite.message);
          }
          if (res.errors) {
            message.error(res.errors[0].message);
          }
        })
        .finally(() => setResendProgress(false));
    }
  };

  const cancelSubscriptionInvite = () => {
    setCancelProgress(true);
    cancelSubscriptionInviteMutattion()
      .then((res) => {
        if (res.data) {
          message.success(res.data.cancel_subscription_invite.message);
        }
        if (res.errors) {
          message.error(res.errors[0].message);
        }
      })
      .finally(() => setCancelProgress(false));
  };

  switch (statusId) {
    case AcceptanceStatus.INVITED:
      return (
        <div className="flex space-x-2 items-baseline">
          <p className="margin-0 captialize-first text-red-600">Pending</p>
          {inviteType === "ProjectUser" &&
            tokenContents &&
            tokenContents.role ===
              EUserRoleName[EUserRoleName.gc_project_admin] && (
              <div className=" space-x-2">
                <Button
                  loading={resendprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={resendInvite}
                >
                  Resend
                </Button>
              </div>
            )}

          {inviteType === "Subscription" &&
            subscriptionRole >= RoleSubscriptionEnum.subscription_admin && (
              <div className=" space-x-2">
                <Button
                  loading={resendprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={resendInvite}
                >
                  Resend
                </Button>
                <Button
                  loading={cancelprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={cancelSubscriptionInvite}
                >
                  Cancel Invite
                </Button>
              </div>
            )}
        </div>
      );
    case AcceptanceStatus.ACTIVE:
      return <p className="margin-0 captialize-first text-green-600">Active</p>;
    case AcceptanceStatus.DEACTIVATED:
      return (
        <p className="margin-0 captialize-first text-[#3b3b3b]">Inactive</p>
      );
    case AcceptanceStatus.NOT_INVITED:
      return (
        <div className="flex space-x-2 items-baseline">
          <p className="margin-0 captialize-first text-[#3b3b3b]">
            Not Invited
          </p>
          {inviteType === "Subscription" &&
            isInviteBtnVisible &&
            subscriptionRole >= RoleSubscriptionEnum.subscription_admin && (
              <Button
                onClick={() => {
                  context?.setInviteAccUser({
                    showInviteModel: true,
                    inviteUserData: userData
                  });
                }}
              >
                Send Invite
              </Button>
            )}

          {inviteType === "ProjectUser" &&
            isInviteBtnVisible &&
            tokenContents &&
            tokenContents.role ===
              EUserRoleName[EUserRoleName.gc_project_admin] && (
              <div className=" space-x-2">
                <Button
                  onClick={() => {
                    context?.setInviteAccUser({
                      showInviteModel: true,
                      inviteUserData: userData
                    });
                  }}
                >
                  Send Invite
                </Button>
              </div>
            )}
        </div>
      );
    default:
      return null;
  }
}

export const projectUserActionRenderer = (params: any) => {
  const { data, context } = params;

  const infoMsg =
    data.status_id === AcceptanceStatus.ACTIVE
      ? "No permission to deactivate"
      : "No permission to activate";

  const confirmMsg =
    data.status_id === AcceptanceStatus.ACTIVE
      ? "Are you sure you want to deactivate this user from the project?"
      : "Are you sure you want to make this user active? ";

  const onToggleAction = (isChecked: boolean) => {
    modal.confirm({
      content: <div className="pl-10">{confirmMsg}</div>,
      onOk() {
        context?.userAction(data?.user?.id, isChecked);
      },
      okText: "Confirm"
    });
  };

  if (
    data.status_id === AcceptanceStatus.INVITED ||
    context?.currentUser?.email === data?.user?.email
  ) {
    return "";
  }

  try {
    return (
      <div className="w-full h-full flex items-center custom-switch">
        <Tooltip title={!context?.isGCADmin ? infoMsg : ""}>
          <Switch
            disabled={!context?.isGCADmin}
            checked={data.status_id === AcceptanceStatus.ACTIVE}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={(isChecked) => {
              onToggleAction(isChecked);
            }}
          />
        </Tooltip>
      </div>
    );
  } catch (ex) {
    return "";
  }
};

export function InviteStatusCellRenderer(props: any) {
  const { data, context } = props;
  const { value, inviteType } = props;
  let dataSources = [];
  let isCIQUser = true;
  let nonCIQsrcPresent = true;

  let isInviteBtnVisible = false;
  let projectVendorType = null;
  let roleId = null;

  if (inviteType === "ProjectUser") {
    dataSources = getProjectUserSources(data);
    nonCIQsrcPresent =
      dataSources.filter((src) => src !== SourceNames.CONSTRUCTIVIQ).length > 0;

    projectVendorType =
      data?.subscription_vendor &&
      data?.subscription_vendor?.project_vendors?.length &&
      data?.subscription_vendor?.project_vendors[0].project_vendors_type?.id;

    if (data?.project_role) {
      roleId = data?.project_role?.id;
    }
  } else {
    dataSources = getSubscriptionUserSources(data);
    nonCIQsrcPresent =
      dataSources.filter((src) => src !== SourceNames.CONSTRUCTIVIQ).length > 0;
    roleId = data?.subscription_permission_id;
  }
  isCIQUser = dataSources.includes(SourceNames.CONSTRUCTIVIQ);
  isInviteBtnVisible =
    nonCIQsrcPresent && data.status_id === AcceptanceStatus.NOT_INVITED;
  const organizations = data.subscription?.organization_subscriptions;
  const org = organizations?.length ? organizations[0] : null;
  const company = data?.subscription_vendor ?? org?.organization;

  const userData = {
    email: data?.user?.email,
    phone: data?.user?.phone,
    id: data?.user?.id,
    first_name: data?.user?.first_name,
    last_name: data?.user?.last_name,
    company_id: company?.id,
    company_name: company?.name,
    type: projectVendorType
  };

  return (
    <div className="flex space-x-3">
      <div className="w-12">
        <InviteStatusText
          statusId={value}
          userId={data?.user_id}
          inviteType={inviteType}
          isCIQUser={isCIQUser}
          isInviteBtnVisible={isInviteBtnVisible}
          context={context}
          userData={userData}
          roleId={roleId}
        />
      </div>
      {inviteType === "ProjectUser" &&
        isCIQUser &&
        value !== AcceptanceStatus.NOT_INVITED && (
          <div className="-mt-3">{projectUserActionRenderer(props)}</div>
        )}
    </div>
  );
}

export function SubmittalStatusCellRenderer(params: any) {
  const { data, context } = params;

  // Note: keep useAlternateDBStructure as always true here
  // as the submittal list response structure does not change for new or old DB.
  return (
    <div>
      <div>{dropDownCellRenderer(params)}</div>
      {context.isIntegrationMode && (
        <ErrorBoundary>
          <SubmittalMicroVizDateBlock
            size="sm"
            submittalData={{
              ...data,
              date_block_submittals: [
                {
                  actual_milestone_1: data.actual_assigned_date,
                  actual_milestone_2: data.actual_trade_partner_submitted_date,
                  actual_milestone_3: data.actual_ext_review_submitted_date,
                  actual_milestone_4: data.actual_ext_review_completed_date,
                  actual_milestone_5: data.actual_submittal_distributed_date
                }
              ]
            }}
            projectParticipants={context.projectParticipants}
            submittalRevisions={data.revision_date_blocks}
            selectedRevision={data.revision}
          />
        </ErrorBoundary>
      )}
      {!context.isIntegrationMode && (
        <div>
          <ErrorBoundary>
            <SubmittalMicroVisualiser
              design_reviewer_id={
                data?.design_reviewer_user_id ||
                data.design_reviewer_unregistered
              }
              gc_reviewer_id={data?.gc_reviewer_user_id}
              submittal_history={data?.history}
              size="sm"
              vendorInfo={{
                designReviewerCompanyName:
                  data.design_reviewer_unregistered_org,
                responsibleContractorCompanyName:
                  data.responsible_contractor_name
              }}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
}

export function userOrgCellRenderer(params: any): any {
  const { data } = params;
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;

  if (!data[userInput.id] && !data[userInput.unregistered_user])
    return {
      cellMarkup: (
        <div className="flex justify-end">
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </div>
      ),
      fullName: ""
    };

  let fullName = "";
  let userOrg = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
    userOrg = data[userInput.org];
  }
  if (data[userInput.unregistered_user]) {
    fullName = data[userInput.unregistered_user];
    userOrg = data[userInput.unregistered_user_org];
  }

  const statusId = data[userInput.statusKey];

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <div className="flex">
        <div>
          <p className="colFirstValue">{fullName}</p>
          <p className="colSecondValue">{userOrg}</p>
        </div>
        {agGridCheckFieldEditable(params) && (
          <div className="flex items-center pl-1">
            <EditableCell />
          </div>
        )}
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-3 -mt-[10px]">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName,
    userOrg
  };
}

export function userOrgCellRendererWithCustomEdit(params: any): any {
  const { data, context } = params;

  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id] && !data[userInput.unregistered_user])
    return {
      cellMarkup: (
        <button
          type="button"
          className="w-full flex justify-end border-0 bg-transparent text-left"
          onClick={() => {
            context.onEditCell({
              rowIndex: params.node.rowIndex!,
              colKey: params.column!.getId()
            });
          }}
        >
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </button>
      ),
      fullName: ""
    };

  let fullName = "";
  let userOrg = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
    userOrg = data[userInput.org];
  }
  if (data[userInput.unregistered_user]) {
    fullName = data[userInput.unregistered_user];
    userOrg = data[userInput.unregistered_user_org];
  }

  const statusId = data[userInput.statusKey];
  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left items-center"
        onClick={() => {
          context.onEditCell({
            rowIndex: params.node.rowIndex!,
            colKey: params.column!.getId()
          });
        }}
      >
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-4 -top-2.5">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
        <div className="grow">
          <p className="colFirstValue">{fullName}</p>
          <p className="colSecondValue">{userOrg}</p>
        </div>
        <div>{agGridCheckFieldEditable(params) && <EditableCell />}</div>
      </button>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName,
    userOrg
  };
}

export function TaskLinksCellRenderer(params: ICellRendererParams) {
  const { value } = params;
  const tasks = value || [];

  const toShow = tasks.slice(0, 2);
  const remaining = tasks.slice(2, tasks.length);

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((item: any) => {
        const name = item.activity_id
          ? `${item.activity_id} - ${item.task_name}`
          : item.task_name;

        return (
          <Tag
            color="default"
            icon={<LinkOutlined />}
            className="bg-gray-100 max-w-[120px] truncate"
          >
            <CustomTooltip value={name} />
            {name}
          </Tag>
        );
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.map((task: any, index: number) => {
        const name = task.activity_id
          ? `${task.activity_id} - ${task.task_name}`
          : task.task_name;
        return (
          <div className="flex items-center">
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 max-w-[120px] truncate"
            >
              <CustomTooltip value={name} />
              {name}
            </Tag>

            {index === 1 && tasks.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover
                  content={popupContent}
                  title={<div className="py-1">Linked Task(s)</div>}
                >
                  <div>+{remaining.length}</div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

export function MaterialLinksCellRenderer(params: ICellRendererParams) {
  const { value, data, context } = params;
  const showSpecSectionNo = context.projectDetails?.spec_section;
  const materials = value || [];

  if (materials.length === 1 && materials[0].implicit) {
    const submittalRefrenceId =
      showSpecSectionNo && data.spec_no
        ? `${data.spec_no} - ${data.submittal_sequence_id}`
        : data.submittal_sequence_id;

    const materialName = `Material for Submittal ${submittalRefrenceId}`;

    return (
      <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
        <div className="flex items-center">
          <Tag
            color="default"
            icon={<LinkOutlined />}
            className="bg-gray-100 w-[120px] truncate"
          >
            <CustomTooltip value={materialName} />
          </Tag>
        </div>
      </div>
    );
  }

  const actualMaterials = materials.filter(
    (material: any) => !material.implicit
  );
  const toShow = actualMaterials.slice(0, 2);
  const remaining = actualMaterials.slice(2, actualMaterials.length);

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((item: any) => {
        return (
          <Link to={`materials/${item.id}`} target="_blank">
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 w-[120px] truncate"
            >
              <CustomTooltip
                value={`${item.material_sequence_id} - ${item.name}`}
              />
            </Tag>
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.slice(0, 2).map((material: any, index: number) => {
        return (
          <div className="flex items-center">
            <Link
              to={`materials/${material.id}`}
              target="_blank"
              className="leading-normal"
            >
              <Tag
                color="default"
                icon={<LinkOutlined />}
                className="bg-gray-100 w-[120px] truncate"
              >
                <CustomTooltip
                  value={`${material.material_sequence_id} - ${material.name}`}
                />
              </Tag>
            </Link>
            {index === 1 && actualMaterials.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover
                  content={popupContent}
                  title={<div className="py-1">Linked Material(s)</div>}
                >
                  <div>+{remaining.length}</div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

export function SubmittalLinksCellRenderer(params: ICellRendererParams) {
  const { value, data, context } = params;
  const showSpecSectionNo = context.projectDetails?.spec_section;

  const submittals = value || [];

  const toShow = submittals.slice(0, 2);
  const remaining = submittals.slice(2, submittals.length);

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((item: any) => {
        const submittalRefrenceId: string =
          showSpecSectionNo && data.spec_section_no
            ? `${data.spec_section_no} - ${item.submittal_sequence_id}`
            : item.submittal_sequence_id;
        const name = `${submittalRefrenceId} - ${item.name}`;
        return (
          <Link target="_blank" to={`submittals/${item.id}`}>
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 w-[120px] truncate"
            >
              <CustomTooltip value={name} />
            </Tag>
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.map((submittal: any, index: number) => {
        const submittalRefrenceId: string =
          showSpecSectionNo && data.spec_section_no
            ? `${data.spec_section_no} - ${submittal.submittal_sequence_id}`
            : submittal.submittal_sequence_id;
        const name = `${submittalRefrenceId} - ${submittal.name}`;
        return (
          <div className="flex items-center" key={submittal.id}>
            <Link
              target="_blank"
              to={`submittals/${submittal.id}`}
              className="leading-normal"
            >
              <Tag
                color="default"
                icon={<LinkOutlined />}
                className="bg-gray-100 w-[120px] truncate"
              >
                <CustomTooltip value={name} />
              </Tag>
            </Link>
            {index === 1 && submittals.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover
                  content={popupContent}
                  title={<div className="py-1">Linked Submittal(s)</div>}
                >
                  <div>+{remaining.length}</div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

export const responsibleContractorCellRenderer = (params: any): any => {
  const { data } = params;
  let cellMarkup = <div />;

  try {
    cellMarkup = (
      <div className="w-full flex items-center">
        <div className="grow truncate">
          {data.responsible_contractor_name || data.trade_partner_name}
        </div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }

  return {
    cellMarkup,
    orgName: data?.responsible_contractor_name || data.trade_partner_name || ""
  };
};

export const responsibleContractorCellRendererWithCustomEdit = (
  params: any
): any => {
  const { data, context } = params;
  let cellMarkup = <div />;

  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left"
        onClick={() => {
          context.onEditCell({
            rowIndex: params.node.rowIndex!,
            colKey: params.column!.getId(),
            data
          });
        }}
      >
        <div className="grow truncate">
          {data.responsible_contractor_name || data.trade_partner_name}
        </div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </button>
    );
  } catch (ex) {
    return "";
  }

  return {
    cellMarkup,
    orgName: data?.responsible_contractor_name || data.trade_partner_name || ""
  };
};

export function gcRepresentativeCellRendererWithCustomEdit(params: any): any {
  const { data, context } = params;
  const onClickEdit = () => {
    context.onEditCell({
      rowIndex: params.node.rowIndex!,
      colKey: params.column!.getId(),
      data
    });
  };
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id])
    return {
      cellMarkup: (
        <button
          type="button"
          className="flex justify-end w-full border-0 bg-transparent"
          onClick={onClickEdit}
        >
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </button>
      ),
      fullName: ""
    };

  let fullName = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
  }

  const statusId = data[userInput.statusKey];

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left items-center"
        onClick={onClickEdit}
      >
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-4 -top-2.5">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
        <div className="grow">
          <p className="colFirstValue">{fullName}</p>
        </div>
        <div>{agGridCheckFieldEditable(params) && <EditableCell />}</div>
      </button>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName
  };
}

export function gcRepresentativeCellRenderer(params: any): any {
  const { data } = params;
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id])
    return {
      cellMarkup: (
        <div className="flex justify-end">
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </div>
      ),
      fullName: ""
    };

  let fullName = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
  }

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <div className="flex items-start">
        <div>
          <p className="colFirstValue">{fullName}</p>
        </div>
        {agGridCheckFieldEditable(params) && <EditableCell />}
      </div>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName
  };
}

export function SubmittalReportDetailsCellRenderer(params: any) {
  const { data } = params;
  const submittalData = { ...data };

  submittalData.submittal_schedule_links =
    submittalData.submittal_schedule_links || [];
  submittalData.submittal_material_links =
    submittalData.submittal_material_links?.map((m: any) => ({
      assignee: m.assignee,
      driving_material: m.driving_material,
      material_id: m.id,
      material_sequence_id: m.material_id,
      material_name: m.name,
      status: m.status,
      implicit: m.implicit,
      material: {
        date_block_materials: [
          {
            planned_milestone_1: m.ROJ_date,
            name_milestone_1: m.last_milestone_name
          }
        ]
      }
    })) || [];

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        text: linkedTask.task_name,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  return (
    <div>
      <DateBlockSubmittalImpactVisualisation
        submittal={submittalData}
        governingTask={governingActivity}
      />
    </div>
  );
}

export function UpcomingSubmittalReportDetailsCellRenderer(params: any) {
  const { data } = params;
  const submittalData = { ...data };
  const { projectId } = useParams() as any;

  submittalData.submittal_schedule_links =
    submittalData.submittal_schedule_links || [];
  submittalData.submittal_material_links =
    submittalData.submittal_material_links?.map((m: any) => ({
      assignee: m.assignee,
      driving_material: m.driving_material,
      material_id: m.id,
      material_sequence_id: m.material_id,
      material_name: m.name,
      status: m.status,
      implicit: m.implicit,
      material: {
        date_block_materials: [
          {
            planned_milestone_1: m.ROJ_date,
            name_milestone_1: m.last_milestone_name
          }
        ]
      }
    })) || [];

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        text: linkedTask.task_name,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  return (
    <div>
      <div className="flex font-medium space-x-2">
        <div className="px-2 uppercase">IMPACTED MATERIALS:</div>
        {submittalData?.submittal_material_links?.length ? "" : "None"}
        {submittalData?.submittal_material_links?.map((item: any) => {
          let materialName = "";
          if (item.implicit) {
            materialName = `Material for Submittal ${submittalData.submittal_id}`;
          } else {
            materialName = `${item.material_sequence_id}-${item.material_name}`;
          }
          const tag = (
            <Tag
              key={materialName}
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 max-w-[120px] truncate"
            >
              <CustomTooltip value={materialName} />
            </Tag>
          );
          return item.implicit ? (
            tag
          ) : (
            <Link
              to={`/project/${projectId}/materials/${item.material_id}`}
              target="_blank"
              key={materialName}
            >
              {tag}
            </Link>
          );
        })}
      </div>

      <div className="flex font-medium space-x-2 pt-4">
        <div className="px-2 uppercase">IMPACTED ACTIVITIES:</div>
        {submittalData?.submittal_schedule_links?.length ? "" : "None"}
        {submittalData?.submittal_schedule_links?.map((item: any) => {
          const name = item.activity_id
            ? `${item.activity_id} - ${item.task_name}`
            : item.task_name;

          return (
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 max-w-[120px] truncate"
              key={name}
            >
              <CustomTooltip value={name} />
            </Tag>
          );
        })}
      </div>
      <DateBlockSubmittalImpactVisualisation
        submittal={submittalData}
        governingTask={governingActivity}
      />
    </div>
  );
}

export function UpcomingMaterialReportDetailsCellRenderer(params: any) {
  const { data, context } = params;
  const { projectId } = useParams() as any;
  const materialData = { ...data };
  const showSpecSectionNo = context.projectDetails?.spec_section;

  return (
    <div className="pb-2">
      <div className="flex font-medium space-x-2">
        <div className="px-2 uppercase">LINKED SUBMITTALS:</div>
        {materialData?.material_submitttal_links?.length ? "" : "None"}
        {materialData?.material_submitttal_links?.map((item: any) => {
          const submittalRefrenceId: string =
            showSpecSectionNo && item.spec_section_no
              ? `${item.spec_section_no} - ${item.submittal_id}`
              : item.submittal_id;
          const name = `${submittalRefrenceId} - ${item.name}`;
          return (
            <Link
              to={`/project/${projectId}/submittals/${item.id}`}
              target="_blank"
            >
              <Tag
                key={item.submittal_id}
                color="default"
                icon={<LinkOutlined />}
                className="bg-gray-100 max-w-[120px] truncate"
              >
                <CustomTooltip value={name} />
              </Tag>
            </Link>
          );
        })}
      </div>

      <div className="flex font-medium space-x-2 pt-4">
        <div className="px-2 uppercase">IMPACTED ACTIVITIES:</div>
        {materialData?.material_schedule_links?.length ? "" : "None"}
        {materialData?.material_schedule_links?.map((item: any) => {
          const name = item.activity_id
            ? `${item.activity_id} - ${item.task_name}`
            : item.task_name;

          return (
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 max-w-[120px] truncate"
            >
              <CustomTooltip value={name} />
            </Tag>
          );
        })}
      </div>
    </div>
  );
}

export const nextDeadlineCellRenderer = (params: any) => {
  const { data, context } = params;
  if (!params.value) return null;
  const todayStartDateObject = DateUtils.dateTimeObj().startOf("day");
  const nextDeadlineDateObject = DateUtils.dateTimeObj(params.value);
  const isPast = nextDeadlineDateObject.isBefore(todayStartDateObject);
  const cellValueClassnames = isPast ? "date-block-date-risk" : "";
  if (!data.next_action) {
    return (
      <div className="flex items-center w-20">
        <span className={cellValueClassnames}>{params.value}</span>
      </div>
    );
  }

  const popupContent = <div className="p-1">{data.next_action}</div>;
  return (
    <div className="flex items-center">
      <div className={`w-20 ${cellValueClassnames}`}>{params.value}</div>
      {context.isCurrentUserGC && (
        <Tooltip title={popupContent} placement="right">
          <InfoCircleOutlined className="pl-3 text-xs h-[13px] mt-[2px]" />
        </Tooltip>
      )}
    </div>
  );
};

export const materialTemplateListNameCellRenderer = (params: any) => {
  const { data, context } = params;

  return (
    <div className="w-full">
      <div className="flex space-x-3 min-w-0">
        <div className="shrink overflow-hidden text-ellipsis">
          <button
            type="button"
            className="bg-transparent border-0 underlined-text cursor-pointer"
            onClick={() => {
              context.onTemplateNameClick(data);
            }}
          >
            {params.value}
          </button>
        </div>
        {data.default && (
          <div>
            <DefaultTag />
          </div>
        )}
      </div>
    </div>
  );
};

export function MaterialTemplateListActionsCellRenderer(params: any) {
  const {
    data,
    context,
    savingDefaultTemplate,
    canEditMaterialDBTemplate,
    materialTemplates
  } = params;
  const { onWorkflowCopy } = context;
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);

  const isTemplateDisabled = data.disabled;
  const isTemplateUsed = !!data.date_blocks_aggregate?.aggregate?.count;

  const availableMaterialTemplateNames = useMemo(() => {
    return materialTemplates.map((template: any) => {
      return template.name.toLowerCase();
    });
  }, [materialTemplates]);

  const editDisableTooltip = useMemo(() => {
    if (!canEditMaterialDBTemplate) {
      return ErrorMessages.PermissionNotGranted;
    }
    if (isTemplateUsed) {
      return ErrorMessages.templateAlreadyUsed;
    }
    return "";
  }, [canEditMaterialDBTemplate, isTemplateUsed]);

  const isCopyDisabled =
    savingDefaultTemplate || !canEditMaterialDBTemplate || isTemplateDisabled;

  return (
    <div className="flex items-center">
      <Button
        disabled={
          savingDefaultTemplate ||
          !canEditMaterialDBTemplate ||
          isTemplateUsed ||
          isTemplateDisabled
        }
        title={editDisableTooltip}
        type="text"
        size="small"
        className="-mt-0.5 border-0"
        onClick={() => {
          context.onTemplateEditClick(data);
        }}
      >
        <EditOutlined />
      </Button>
      {!isCopyDisabled ? (
        <Popover
          open={copyPopupVisible}
          placement="bottom"
          content={
            <CopyDBTemplate
              setCopyPopupVisible={setCopyPopupVisible}
              availableTemplateNames={availableMaterialTemplateNames}
              template={data}
              onCopy={onWorkflowCopy}
            />
          }
          trigger="click"
          destroyTooltipOnHide
          onOpenChange={() => {
            setCopyPopupVisible(!copyPopupVisible);
          }}
        >
          <Button
            type="text"
            size="small"
            className="pt-0.5 border-0"
            onClick={() => {
              setCopyPopupVisible(true);
            }}
          >
            <CopyIcon />
          </Button>
        </Popover>
      ) : (
        <Button
          disabled
          type="text"
          size="small"
          className="pt-0.5 border-0"
          title={
            !canEditMaterialDBTemplate ? ErrorMessages.PermissionNotGranted : ""
          }
        >
          <CopyIcon />
        </Button>
      )}

      <Switch
        className="-mt-0.5 ml-1.5"
        checked={!isTemplateDisabled}
        onChange={(value: any) => {
          context.onToggleTemplateDisabledConfirm(data, value);
        }}
        size="small"
        disabled={!canEditMaterialDBTemplate || savingDefaultTemplate}
        title={
          !canEditMaterialDBTemplate ? ErrorMessages.PermissionNotGranted : ""
        }
      />
    </div>
  );
}

export const materialTemplateListDefaultCellRenderer = (params: any) => {
  const { data, context, savingDefaultTemplate, canEditMaterialDBTemplate } =
    params;

  const isTemplateDisabled = data.disabled;

  return (
    <div className="flex items-center">
      <Radio.Group
        disabled={
          savingDefaultTemplate ||
          !canEditMaterialDBTemplate ||
          isTemplateDisabled
        }
        onChange={() => {
          context.onTemplateDefaultChange(data);
        }}
        value={data.default ? 1 : 0}
      >
        <Radio className="ml-5" value={1} />
      </Radio.Group>
    </div>
  );
};

export const featureTemplateListNameCellRenderer = (params: any) => {
  const { data, context } = params;

  return (
    <div className="w-full">
      <div className="flex space-x-3 min-w-0">
        <div className="shrink overflow-hidden text-ellipsis">
          <button
            type="button"
            className="bg-transparent border-0 underlined-text cursor-pointer whitespace-pre"
            onClick={() => {
              context.onTemplateNameClick(data);
            }}
          >
            {params.value}
          </button>
        </div>
        {data.default && (
          <div>
            <DefaultTag />
          </div>
        )}
      </div>
    </div>
  );
};

export function FeatureTemplateListActionsCellRenderer(params: any) {
  const {
    data,
    context,
    savingDefaultTemplate,
    canEditFeatureDBTemplate,
    featureTemplates
  } = params;
  const { onWorkflowCopy } = context;
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);

  const isTemplateDisabled = data.disabled;
  const isTemplateUsed = !!data.date_blocks_aggregate?.aggregate?.count;

  const availableTemplateNames = useMemo(() => {
    return featureTemplates.map((template: any) => {
      return template.name.toLowerCase();
    });
  }, [featureTemplates]);

  const editDisableTooltip = useMemo(() => {
    if (!canEditFeatureDBTemplate) {
      return ErrorMessages.PermissionNotGranted;
    }
    if (isTemplateUsed) {
      return ErrorMessages.templateAlreadyUsed;
    }
    return "";
  }, [canEditFeatureDBTemplate, isTemplateUsed]);

  const isCopyDisabled =
    savingDefaultTemplate || !canEditFeatureDBTemplate || isTemplateDisabled;

  return (
    <div className="flex items-center">
      <Button
        disabled={
          savingDefaultTemplate ||
          !canEditFeatureDBTemplate ||
          isTemplateUsed ||
          isTemplateDisabled
        }
        title={editDisableTooltip}
        type="text"
        size="small"
        className="-mt-0.5 border-0"
        onClick={() => {
          context.onTemplateEditClick(data);
        }}
      >
        <EditOutlined />
      </Button>
      {!isCopyDisabled ? (
        <Popover
          open={copyPopupVisible}
          placement="bottom"
          content={
            <CopyDBTemplate
              setCopyPopupVisible={setCopyPopupVisible}
              availableTemplateNames={availableTemplateNames}
              template={data}
              onCopy={onWorkflowCopy}
            />
          }
          trigger="click"
          destroyTooltipOnHide
          onOpenChange={() => {
            setCopyPopupVisible(!copyPopupVisible);
          }}
        >
          <Button
            type="text"
            size="small"
            className="pt-0.5 border-0"
            onClick={() => {
              setCopyPopupVisible(true);
            }}
          >
            <CopyIcon />
          </Button>
        </Popover>
      ) : (
        <Button
          disabled
          type="text"
          size="small"
          className="pt-0.5 border-0"
          title={
            !canEditFeatureDBTemplate ? ErrorMessages.PermissionNotGranted : ""
          }
        >
          <CopyIcon />
        </Button>
      )}

      <Switch
        className="-mt-0.5 ml-1.5"
        checked={!isTemplateDisabled}
        onChange={(value: any) => {
          context.onToggleTemplateDisabledConfirm(data, value);
        }}
        size="small"
        disabled={!canEditFeatureDBTemplate || savingDefaultTemplate}
        title={
          !canEditFeatureDBTemplate ? ErrorMessages.PermissionNotGranted : ""
        }
      />
    </div>
  );
}

export const featureTemplateListDefaultCellRenderer = (params: any) => {
  const { data, context, savingDefaultTemplate, canEditFeatureDBTemplate } =
    params;

  const isTemplateDisabled = data.disabled;

  return (
    <div className="flex items-center">
      <Radio.Group
        disabled={
          savingDefaultTemplate ||
          !canEditFeatureDBTemplate ||
          isTemplateDisabled
        }
        onChange={() => {
          context.onTemplateDefaultChange(data);
        }}
        value={data.default ? 1 : 0}
      >
        <Radio className="ml-5" value={1} />
      </Radio.Group>
    </div>
  );
};

export const SelectCellEditor = forwardRef((props: any, ref) => {
  const {
    context,
    dropDownOptionsProperty,
    showSearch,
    stopEditing,
    notFoundContent,
    isSorting = true
  } = props;

  const [value, setValue] = useState(props.value);

  const dropDownOptions = useMemo(() => {
    if (isSorting)
      return context[dropDownOptionsProperty].sort((a: any, b: any) =>
        a.label.localeCompare(b.label)
      );
    return context[dropDownOptionsProperty];
  }, [context, dropDownOptionsProperty, isSorting]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      autoFocus
      defaultOpen
      placeholder={showSearch ? "Select / Search" : "Select"}
      allowClear
      showSearch={showSearch}
      optionFilterProp="label"
      options={dropDownOptions}
      virtual={false}
      notFoundContent={notFoundContent}
      style={{ width: "100%" }}
      filterOption={(input, option: any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    />
  );
});

export const integrationTypeCellRenderer = (params: any) => {
  const { value } = params;
  const integrationSystemName = IntegrationSystemNameMap[value];

  const sourceLogo = () => {
    switch (value) {
      case IntegrationType[IntegrationType.PROCORE]:
        return <ProcoreLogoIcon />;
      case IntegrationType[IntegrationType.AUTODESK]:
        return <AutodeskLogoIcon />;
      case IntegrationType[IntegrationType.BIM360]:
        return (
          <img src={BIM360logo} alt="" style={{ width: 18, height: 19 }} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center space-x-2" title={integrationSystemName}>
      <div className="flex items-center">{sourceLogo()}</div>
      <div>{integrationSystemName}</div>
    </div>
  );
};

export function IntegrationNameCellRenderer(params: any) {
  const { context, data } = params;

  const isFileBasedIntegration =
    data?.systemIntgConfigProperties?.INTEGRATION_METHOD === "file";

  const subReauthRequired =
    data.properties.SUBSCRIPTION_CONFIG_AUTH_STATE &&
    data.properties.SUBSCRIPTION_CONFIG_AUTH_STATE ===
      IntegrationAuthStates.REAUTHORIZATION_REQUIRED;

  const projReauthRequired =
    data.properties.PROJECT_CONFIG_AUTH_STATE &&
    data.properties.PROJECT_CONFIG_AUTH_STATE ===
      IntegrationAuthStates.REAUTHORIZATION_REQUIRED;

  const reauthRequired = subReauthRequired || projReauthRequired;

  return (
    <div className="max-w-full flex items-center">
      <div className="grow truncate flex items-center">
        {!isFileBasedIntegration ? (
          <button
            type="button"
            className="gridButtonLink "
            onClick={async () => {
              context.onManageIntegrationClick(data);
            }}
          >
            <div className="space-x-1">
              <span>{data.properties.INTEGRATION_NAME}</span>
            </div>
          </button>
        ) : (
          <div className="space-x-1">
            <span>{data.properties.INTEGRATION_NAME}</span>
          </div>
        )}
      </div>
      <div className="shrink-0 flex items-end">
        {reauthRequired && (
          <div className=" mt-2">
            <Popover
              placement="left"
              content={
                <div className="w-[300px]">
                  Authorization has expired and integration with{" "}
                  {`${IntegrationSystemNameMap[data.integration.system]}`} is no
                  longer valid. View configuration details to renew the
                  connection.
                </div>
              }
            >
              <div className="inline">
                <AuthenticationExpiredIcon />
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
}

export const dlGrpUserNameCellRenderer = (params: any): any => {
  const { data } = params;

  let cellDiv = <div />;
  try {
    cellDiv = (
      <div className="flex">
        <div>
          <p className="colFirstValue">{data.name}</p>
        </div>
        {data.status_id && data.status_id === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-3 -top-2">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } catch {
    //
  }

  return cellDiv;
};

export const userNameCellRenderer = (params: any) => {
  const { data } = params;
  const userInfo = data?.created_by_user ?? data?.user_created;

  const fullName = `${userInfo?.first_name ?? ""} ${userInfo?.last_name ?? ""}`;
  const email = userInfo?.email ?? "";

  const userDetails = (
    <div className="flex flex-col ">
      <div className="flex items-center h-5">{fullName}</div>
      <div className="flex items-center h-5">{email}</div>
    </div>
  );

  return userDetails;
};

export const riskCellRenderer = ({ value }: any) => {
  if (!value) return "";
  return (
    <div
      className={
        value === RiskLevelType.High
          ? "date-block-risk-high font-semibold cursor-default"
          : "cursor-default"
      }
    >
      {value}
    </div>
  );
};

export const logStatusCellRenderer = (params: any) => {
  const { data, context } = params;
  const status = data?.status;
  const headerTitle = data?.material_id
    ? `${data?.material_id ?? ""}  - ${data?.name ?? ""}`
    : data?.name ?? "";

  const userDetails = (
    <div className="flex space-x-2 items-center justify-between">
      <CustomTooltip value={status} />
      <FeatureSetStatus
        headerTitle={headerTitle}
        data={data}
        gqlClientForProject={context?.gqlClientForProject}
      />
    </div>
  );

  return userDetails;
};

export const materialActionCellRenderer = (params: any) => {
  const { context, data } = params;
  const { onLinkClick, onRequestDurationClick } = context;

  const workflowStatus = data?.date_block_data?.workflow_status;
  const isAssigneePresent = data?.assignee || data?.assignee_unregistered;
  const isAssigneeUnregistered = !!data?.assignee_unregistered;
  const isWorkflowNotStarted =
    workflowStatus === EWorkflowStatusDataBlock.WF_NOT_STARTED;

  const requestAlreadySent =
    data?.invite_status &&
    data?.invite_status === EmailInviteStatus.REQUEST_SENT;

  const requestBtnDisabled =
    !isAssigneePresent ||
    isAssigneeUnregistered ||
    !isWorkflowNotStarted ||
    requestAlreadySent;

  const requestBtnDisabledHint = () => {
    const strCannotRequestDurations =
      "Cannot request lead time from Subcontractor:";

    if (requestAlreadySent) {
      return (
        <div className="w-[200px] text-sm">
          <div>Request for Lead Time is already sent.</div>
        </div>
      );
    }

    if (!isWorkflowNotStarted)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>Workflow Started.</div>
        </div>
      );
    if (!isAssigneePresent)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>No subcontractor assigned.</div>
        </div>
      );
    if (isAssigneeUnregistered)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>Unregistered subcontractor assigned.</div>
        </div>
      );

    return (
      <div className="w-[200px] text-sm">
        Request Lead Time from Subcontractor
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center">
      <button
        type="button"
        className="ciq-icon-button !p-1.5"
        onClick={() => {
          onLinkClick(params.data);
        }}
      >
        <LinkOutlined />
      </button>
      {!requestAlreadySent ? (
        <Popover
          placement="left"
          content={<div>{requestBtnDisabledHint()}</div>}
        >
          <div>
            <button
              type="button"
              className="ciq-icon-button !p-1.5"
              onClick={() => {
                onRequestDurationClick(params.data);
              }}
              disabled={requestBtnDisabled}
            >
              <PersonEditIcon disabled={requestBtnDisabled} />
            </button>
          </div>
        </Popover>
      ) : (
        <div>
          <Popover
            placement="left"
            content={<div>{requestBtnDisabledHint()}</div>}
          >
            <span className="!p-1.5 flex items-center">
              <PersonCheckIcon />
            </span>
          </Popover>

          {/* <button
            type="button"
            className="ciq-icon-button !p-1.5"
            onClick={() => {
              onRequestDurationClick(params.data);
            }}
            title="View request details"
          >
            <PersonCheckIcon />
          </button> */}
        </div>
      )}
    </div>
  );
};
