/* eslint-disable no-nested-ternary */
import classnames from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";

import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useMemo } from "react";
import { isProdDemoEnv } from "utils/utils";
import { ProjectPermissionEnum } from "../../constants";

type MenuLink = {
  label: string;
  key: string;
};

const borderRightClassname =
  "border-solid border-y-0 border-l-0 border-r-1 border-slate-100";
const verticalCenterClassname = "flex items-center";
const linkClassname =
  "text-[black] hover:text-[black] hover:drop-shadow-[0_0_0.3px_rgba(0,0,0,1)]";
const activeLinkClassname =
  "border-solid border-[black] border-x-0 border-t-0 border-b-2 font-semibold";

type CIQTabProps = {
  menuLinks?: MenuLink[];
};

function CIQTabs(props: CIQTabProps) {
  const { menuLinks = [] } = props;
  const { pathname } = useLocation();

  const { tokenContents }: TProjectContext = useContext(ProjectContext);

  const filteredMenuLinks: any = useMemo(() => {
    const isPermissionNotGrant = isPermissionNotGrantted(
      ProjectPermissionEnum.ViewLinkingPage,
      tokenContents?.role!
    );

    const isPermissionChangeImpactNotGrant = isPermissionNotGrantted(
      ProjectPermissionEnum.ImportSchedule,
      tokenContents?.role!
    );

    let fMenuLinks: any = menuLinks;

    if (isProdDemoEnv()) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return item.label !== "Procurement Schedule";
      });
    }

    if (isPermissionChangeImpactNotGrant) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return item.label !== "Change Impact";
      });
    }
    if (isPermissionNotGrant) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return (
          item.label !== "Submittal Linking" &&
          item.label !== "Material Linking"
        );
      });
    }

    return fMenuLinks;
  }, [menuLinks, tokenContents]);

  return (
    <nav className=" bg-transparent leading-none hide-on-print">
      <div className="h-[45px] flex items-stretch">
        <div
          className={`${borderRightClassname} flex grow justify-start gap-x-4`}
        >
          {filteredMenuLinks.map((link: any) => {
            const className = classnames(verticalCenterClassname, {
              [activeLinkClassname]: matchPath(pathname, { path: link.key })
            });
            return (
              <Link
                key={link.key}
                to={link.key}
                className={`${verticalCenterClassname} items-stretch ${linkClassname}`}
              >
                <div className={className}>{link.label}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
}

export default CIQTabs;
