import { AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Menu, Modal, notification } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { calculateLogPageStats } from "components/ciq-log-page-header";
import { newEntityNotificationMessage } from "entity-app/shared-components/new-entity-notification-message";
import { useParams } from "react-router";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import { AppContext } from "context/AppProvider";
import { applyBidPackagesLogFilters } from "utils/ag-grid-programmatic-filtering";
import { resetGridFilters } from "entity-app/utils/utils";
import { FeatureTypes } from "entity-app/constants";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { exportEntityLogAsCSV } from "entity-app/utils/export-entity-log-as-csv";

import {
  EFeaturePageId,
  ErrorMessages,
  ProjectPermissionEnum,
  SidepanelStyles
} from "constants/index";
import { UtilColumnConfig } from "components/column-config-views/column-config-util";
import ColumnConfigViews from "components/column-config-views";
import { DownOutlined } from "@ant-design/icons";
import ErrorBoundary from "components/error-boundary";
import FeatureBulkEditDurations from "components/feature-bulk-edit-durations";
import { usePreconstrutionData } from "./hooks/preconstruction-data";
import CreateBidPackageComponent from "./components/create-bid-package";
import useFeatureLogInlineEdit from "../../entity-app/hooks/feature-log-inline-edit";

import BulkEditBidPackageDetails from "./components/bulk-edit-details";
import { usePreconstrutionAgGrid } from "./hooks/preconstruction-ag-grid-data";

const featureKey = "bid-packages"; // URL

function PreconstructionLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { projectDetails, tokenContents } = useContext(ProjectContext);

  const cannotWriteAccessOfBidPackage = isPermissionNotGrantted(
    ProjectPermissionEnum.BidPackageWrite,
    tokenContents?.role!
  );

  const pageData = usePreconstrutionData({ gridRef });
  const agGridData = usePreconstrutionAgGrid({
    featureKey,
    milestonesColumns: pageData.milestonesColumns,
    cannotWriteAccessOfBidPackage
  });

  const { onCellEditRequest } = useFeatureLogInlineEdit({
    refetchListData: pageData.refetchBidPackageList
  });
  const filterChipRef = useRef<FilterChipComponentRef>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useParams() as any;
  const { bidPackageListFilter, setBidPackageListFilter } =
    useContext(AppContext);

  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);
  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();
  const [selectedRows, setSelectedRows] = useState<Array<any> | null>(null);

  const [bulkEditMode, setBulkEditMode] = useState<
    "details" | "durations" | null
  >(null);

  const openNotification = (id: string, title: string) => {
    api.open(
      newEntityNotificationMessage({
        projectId,
        id,
        title,
        featureKey
      })
    );
  };

  const onFirstDataRendered = useCallback(() => {
    if (bidPackageListFilter) {
      applyBidPackagesLogFilters({ gridRef, bidPackageListFilter });
    }
    if (
      pageData.savedColumns?.bid_package_column_config &&
      bidPackageListFilter === null &&
      gridRef.current
    ) {
      UtilColumnConfig.setFilterStateFromData({
        columnStates: pageData.savedColumns?.bid_package_column_config,
        gridRef: gridRef.current
      });
    }
    pageData.calculateMaxNumberOfMilestone();

    setTimeout(() => {
      // NOTE: To load initial data once in onFilterApplied method
      setIsFirstDataRendered(true);
    }, 2000);

    gridRef.current?.api.onFilterChanged();
  }, [bidPackageListFilter, pageData]);

  const bulkEditActionMenuItems = useMemo(() => {
    return (
      <Menu
        items={[
          {
            key: 0,
            label: "Bulk Edit Details",
            onClick: () => setBulkEditMode("details")
          },
          {
            key: 1,
            label: "Bulk Edit Durations",
            onClick: () => setBulkEditMode("durations")
          }
        ]}
      />
    );
  }, []);

  const headerItems = () => {
    const onAllBidPackagesClick = () => {
      resetGridFilters({
        setListFilter: setBidPackageListFilter,
        api: gridRef.current?.api
      });
    };

    const menus = [
      <CiqAgSearch key="PreconstructionLogPage_search" gridRef={gridRef} />,
      ...(bidPackageListFilter
        ? [
            <Button
              key="PreconstructionLogPage_AllBidPackages"
              onClick={() => onAllBidPackagesClick()}
            >
              All Bid Packages
            </Button>
          ]
        : [
            <FilterChipComponent
              key="PreconstructionLogPage_FilterChip"
              columnDefs={agGridData.columnDefs}
              gridRef={gridRef}
              ref={filterChipRef}
            />
          ]),
      <ColumnConfigViews
        key="PreconstructionLogPage_ColumnConfigViews"
        featureId={EFeaturePageId.BID_PACKAGE}
        setFilterStateFromData={(columnStates) => {
          if (gridRef.current)
            UtilColumnConfig.setFilterStateFromData({
              columnStates,
              gridRef: gridRef.current
            });
        }}
        getColumnStateFromGrid={() => {
          if (gridRef.current) {
            return UtilColumnConfig.getColumnStateFromGrid({
              gridRef: gridRef.current
            });
          }
          return null;
        }}
        afterApplyFilterCallBack={pageData.calculateMaxNumberOfMilestone}
      />,
      ...(selectedRows && selectedRows.length > 1
        ? [
            <Dropdown
              overlay={bulkEditActionMenuItems}
              key="PreconstructionLogPage_bulkEdit"
            >
              <Button>
                Edit ({selectedRows.length}) <DownOutlined />
              </Button>
            </Dropdown>
          ]
        : []),
      <Button
        key="PreconstructionLogPage_new"
        disabled={cannotWriteAccessOfBidPackage}
        title={
          cannotWriteAccessOfBidPackage
            ? ErrorMessages.PermissionNotGranted
            : "Create New Bid Package"
        }
        onClick={() => {
          setShowDrawer(!showDrawer);
        }}
      >
        New Bid Package
      </Button>,
      <Button key="PreconstructionLogPage_importLog" disabled>
        Import Log
      </Button>,
      <Button
        key="PreconstructionLogPage_export"
        placeholder="Export Bid Package Log"
        onClick={() => {
          if (projectDetails && gridRef.current)
            exportEntityLogAsCSV({
              entityName: "Bid Package Log",
              projectDetails,
              gridRef: gridRef.current,
              includedColumns: ["description"],
              excludedColumns: ["_milestones", "_durations"]
            });
        }}
        disabled={!pageData.bidPackageList?.bid_package_list_view}
      >
        Export
      </Button>
    ];

    return menus;
  };

  const onEditCell = useCallback(
    (params: any) => {
      gridRef.current!.api.setFocusedCell(params.rowIndex, params.colKey);
      gridRef.current!.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colKey
      });
    },
    [gridRef]
  );

  return (
    <>
      {contextHolder}
      <CiqGridViewWrapper
        headerInfo={{
          entityName: "PreconstructionLogPage",
          titleParam: {
            title: "Bid Packages",
            totalCount: pageData.bidPackageList?.bid_package_list_view?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems()
        }}
        gridInfo={{
          gridRef,
          columnDefs: agGridData.columnDefs,
          rowData: pageData.bidPackageList?.bid_package_list_view,
          defaultColDef: agGridData.defaultColDef,
          rowHeight: 62,
          readOnlyEdit: true,
          singleClickEdit: true,
          stopEditingWhenCellsLoseFocus: true,
          onCellEditRequest,
          onFirstDataRendered,
          onSortChanged: () => {
            if (isFirstDataRendered && !bidPackageListFilter)
              pageData.onSaveColumnState();
          },
          onFilterChanged: () => {
            filterChipRef.current?.onFilterApplied();
            setStatsData(
              calculateLogPageStats({
                gridRef,
                ListFilter: bidPackageListFilter,
                featureTypeId: FeatureTypes.BID_PACKAGE
              })
            );
            if (isFirstDataRendered && !bidPackageListFilter)
              pageData.onSaveColumnState();
          },
          onColumnVisible: (e) => {
            if (e.columnApi && e.source === "toolPanelUi") {
              if (e.column) {
                agGridData.milestonesColumns.onColumnVisible(
                  e,
                  pageData.maxNumberOfMilestone
                );
              } else {
                agGridData.milestonesColumns.updateAllMileStoneInitialVisibility(
                  e.columnApi,
                  pageData.maxNumberOfMilestone
                );
              }
              pageData.onSaveColumnState();
            }
          },
          onDragStopped: (e) => {
            const classNames = [
              "ag-header-cell-resize",
              "ag-tool-panel-horizontal-resize"
            ];
            if (e.columnApi && !classNames.includes(e.target.className)) {
              pageData.onSaveColumnState();
            }
          },
          context: {
            onEditCell,
            isGridCellEditable: agGridData.isGridCellEditable,
            refetchBidPackageList: pageData.refetchBidPackageList,
            editPermission: !cannotWriteAccessOfBidPackage
          },
          onSelectionChanged: () => {
            setSelectedRows(gridRef?.current?.api?.getSelectedRows() || null);
          }
        }}
      />
      {showDrawer && (
        <CreateBidPackageComponent
          setDrawerOpen={setShowDrawer}
          showDrawerOpen={showDrawer}
          modelTitle="Create Bid Package"
          onBidPackageCreated={(title: string, id: string) => {
            setShowDrawer(false);
            openNotification(id, title);
            pageData.refetchBidPackageList();
          }}
        />
      )}

      <Modal
        title="BULK EDIT DETAILS"
        className="custom-drawer"
        width={420}
        style={SidepanelStyles.style}
        bodyStyle={{ minHeight: "calc(100vh - 96px)" }}
        footer={null}
        open={bulkEditMode === "details"}
        onCancel={() => {
          setBulkEditMode(null);
        }}
        destroyOnClose
      >
        <ErrorBoundary>
          <BulkEditBidPackageDetails
            selectedRows={selectedRows!}
            onCancel={() => {
              setBulkEditMode(null);
            }}
            onUpdateSuccess={() => {
              setBulkEditMode(null);
              setSelectedRows(null);
              setTimeout(() => {
                pageData.refetchBidPackageList();
              });
            }}
          />
        </ErrorBoundary>
      </Modal>
      <Modal
        title="BULK EDIT DURATIONS"
        className="custom-drawer with-padding-fix"
        style={SidepanelStyles.style}
        bodyStyle={{ minHeight: "calc(100vh - 148px)" }}
        width="calc(100vw - 200px)"
        footer={[
          <div className="flex w-full space-x-3 justify-end" key={100}>
            <Button
              onClick={() => {
                setBulkEditMode(null);
              }}
              key={101}
            >
              Close
            </Button>
          </div>
        ]}
        open={bulkEditMode === "durations"}
        onCancel={() => {
          setBulkEditMode(null);
        }}
        destroyOnClose
        keyboard={false}
      >
        <ErrorBoundary>
          <FeatureBulkEditDurations
            selectedRows={selectedRows!}
            featureTypeId={FeatureTypes.BID_PACKAGE}
          />
        </ErrorBoundary>
      </Modal>
    </>
  );
}

export default PreconstructionLogPage;
