import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useMemo } from "react";
import { GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION } from "services/graphQL/ciq-queries";
import { DateUtils } from "utils/dateutils";
import { IMaterial } from "./type-definitions";
import "./request-durations.scss";

function RequestedDurationDetails({
  selectedItem
}: {
  selectedItem: { id: string };
}) {
  const { gqlClientForProject } = useContext(ProjectContext);

  // console.log("RequestedDurationDetails selectedItem", selectedItem);

  const { data: queryResponse, loading } = useCIQQuery<{
    material: IMaterial[];
  }>(GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION, {
    client: gqlClientForProject,
    variables: {
      materialIds: [selectedItem.id]
    }
  });

  // console.log("RequestedDurationDetails queryResponse ", queryResponse);

  const materialData: any = useMemo(() => {
    if (!queryResponse) return null;
    const material = queryResponse.material[0];
    return {
      id: material.id,
      name: material.name,
      materialAssignee: material.material_assignee,
      assigneeName: material.material_assignee
        ? `${material.material_assignee?.first_name} ${material.material_assignee?.last_name}`
        : "",
      assigneeEmail: material.material_assignee?.email,
      tradePartner: material?.materials_trade_partner?.name,
      estimatedInvite: material?.estimate_invite_features[0]?.estimated_invite,
      inviteCreatedAt:
        material?.estimate_invite_features[0]?.estimated_invite?.created_at
    };
  }, [queryResponse]);

  if (loading && !queryResponse)
    return (
      <div
        className="min-h-[260px] flex justify-center items-center"
        data-testid="loading-spinner"
      >
        <Spin size="small" />
      </div>
    );

  return (
    <div className="px-4 py-6">
      <div className="text-sm">
        A request was recently sent to the Subcontractor to enter Durations. The
        changes will reflect on the Dateblock.
      </div>
      <div className="my-5 space-y-1">
        {materialData?.materialAssignee && (
          <div>
            <div className="text-sm font-semibold">
              {materialData?.tradePartner}
            </div>
            <div className="text-xs">
              {materialData?.assigneeName} ({materialData?.assigneeEmail})
            </div>
          </div>
        )}
        <div className="text-xs">{materialData?.name}</div>
        {materialData?.inviteCreatedAt && (
          <div className="text-xs">
            {DateUtils.format(materialData?.inviteCreatedAt)}
          </div>
        )}
      </div>
      <div>
        <div className="form-field-heading-2">Notes</div>
        <TextArea
          rows={2}
          autoSize={{ minRows: 2, maxRows: 2 }}
          disabled
          value={materialData?.estimatedInvite?.notes}
          title={materialData?.estimatedInvite?.notes ? "" : "No notes added"}
        />
      </div>
    </div>
  );
}

export default RequestedDurationDetails;
