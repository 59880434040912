import { useContext, useEffect, useMemo, useState } from "react";
import { message } from "antd";
import { getScheduleImpactForMaterial } from "services/schedule-versions-services";
import { ProjectContext } from "context/ProjectProvider";
import { TMaterialImpactAnalysis } from "./models";
import "./style.scss";
import { ScheduleChangeSharedContext } from "../../../../context/ScheduleChangeContext";
import {
  filterImpactedMaterial,
  filterImpactedSubmittal,
  formatCategoryData,
  formatLinkedSubmittalDataForGoverningMaterial,
  getFormattedMaterialImpactData
} from "./utils";
import { EntityType, ErrorMessages } from "../../../../constants";
import RenderImpactCategory from "./render-impact-category";
import { ImpactSummaryDataType } from "../compare-schedule-2/model";
import ScheduleImpactSummary from "../compare-schedule-2/schedule-impact-summary";

// import ImpactedDataResponseBody from "./test-data/si-new-sample-projected.json";

const defaultImpactAnalysisData: TMaterialImpactAnalysis = {
  HIGH: {},
  MEDIUM: {},
  LOW: {},
  DELETED: {}
} as TMaterialImpactAnalysis;

const defaultFormattedCategoryData = {
  HIGH: {
    categoryType: "",
    count: 0,
    data: {},
    showCategory: true,
    entityType: EntityType.None
  },
  MEDIUM: {
    categoryType: "",
    count: 0,
    data: {},
    showCategory: true,
    entityType: EntityType.None
  },
  LOW: {
    categoryType: "",
    count: 0,
    data: {},
    showCategory: true,
    entityType: EntityType.None
  },
  DELETED: {
    categoryType: "",
    count: 0,
    data: {},
    showCategory: true,
    entityType: EntityType.None
  }
};

function CompareScheduleV3() {
  const { sharedState } = useContext(ScheduleChangeSharedContext);
  const [sia, setSia] = useState(defaultImpactAnalysisData); // submittal impact analysis //TODO: change it
  const [mia, setMia] = useState(defaultImpactAnalysisData); // material impact analysis
  const [isLoadingData, setIsLoadingData] = useState({ current: false });
  const [scheduleImpactSummary, setscheduleImpactSummary] =
    useState<ImpactSummaryDataType>({
      totalNoOfActivities: {
        updated: 0,
        deleted: 0,
        added: 0
      },
      activitiesTiedToProcurement: {
        updated: 0,
        deleted: 0,
        added: 0
      },
      impactedSubmittals: {
        updated: 0,
        deleted: 0,
        added: 0
      },
      impactedMaterials: {
        updated: 0,
        deleted: 0,
        added: 0
      }
    });

  const [formattedImpactedSubmittal, setFormattedImpactedSubmittal] = useState(
    defaultFormattedCategoryData
  ); // new formatted impacted submittals

  const [formattedImpactedMaterial, setFormattedImpactedMaterial] = useState(
    defaultFormattedCategoryData
  ); // new formatted impacted materials

  const { isFeatureFlagEnabled } = useContext(ProjectContext);

  const isAffectedSubmittalEnabled = true; // if it is also define in the feature config then set here.
  const isAffectedMaterialEnabled = isFeatureFlagEnabled("MATERIAL_IMPACT");

  const isShowDeletedActivitiesEnabled = isFeatureFlagEnabled(
    "SHOW_DELETED_ACTIVITIES"
  );

  useEffect(() => {
    const getData = async () => {
      if (!sharedState.params.importLogId) return;
      setIsLoadingData({ current: true });
      getScheduleImpactForMaterial(
        sharedState.params.importLogId,
        sharedState.params.sourceLogId,
        sharedState.params.projectToken
      ).then((res: any) => {
        if (res.data) {
          // Get impacted submittal data
          // console.log("Loading json data =>", ImpactedDataResponseBody);
          // const responseBody = ImpactedDataResponseBody;
          const responseBody = res.data;

          // console.log("data =>", responseBody);

          const submittalImpactAnalysis =
            responseBody.success?.impactAnalysis?.submittals ||
            defaultFormattedCategoryData;

          if (submittalImpactAnalysis) {
            const allMaterialDateBlock =
              responseBody.success.all_material_date_block;

            const formattedSubmittalImpactData =
              formatLinkedSubmittalDataForGoverningMaterial(
                submittalImpactAnalysis,
                allMaterialDateBlock
              );

            setSia(formattedSubmittalImpactData);
          }

          // Get impacted material data
          const materialImpactAnalysis =
            responseBody.success?.impactAnalysis?.materials ||
            defaultFormattedCategoryData;
          if (materialImpactAnalysis) {
            const allSubmittalDateBlock =
              responseBody.success.all_submittal_date_block;
            const formattedMaterialImpcatData = getFormattedMaterialImpactData(
              materialImpactAnalysis,
              allSubmittalDateBlock
            );
            setMia(formattedMaterialImpcatData);
          }

          setscheduleImpactSummary(responseBody.success?.impactSummary);
        } else {
          message.error(
            res?.error?.message || ErrorMessages.SomethingWentWrong
          );
        }

        setIsLoadingData({ current: false });
      });
    };

    getData();
  }, [sharedState.params]);

  const filteredSia = useMemo(() => {
    const data = filterImpactedSubmittal(sia, sharedState.searchText);
    const formattedData = formatCategoryData(data, EntityType.Submittal);

    setFormattedImpactedSubmittal(formattedData);
    return data;
  }, [sia, sharedState.searchText]);

  const filteredMia = useMemo(() => {
    const data = filterImpactedMaterial(mia, sharedState.searchText);
    const formattedData = formatCategoryData(data, EntityType.Material);
    setFormattedImpactedMaterial(formattedData);
    return data;
  }, [mia, sharedState.searchText]);

  const hasImpactedSubmittals = useMemo(() => {
    return (
      filteredSia.DELETED?.activities?.length ||
      filteredSia.HIGH?.activities?.length ||
      filteredSia.MEDIUM?.activities?.length ||
      filteredSia.LOW?.activities?.length
    );
  }, [
    filteredSia.DELETED?.activities?.length,
    filteredSia.HIGH?.activities?.length,
    filteredSia.LOW?.activities?.length,
    filteredSia.MEDIUM?.activities?.length
  ]);

  const hasImpactedMaterials = useMemo(() => {
    return (
      filteredMia.DELETED?.activities?.length ||
      filteredMia.HIGH?.activities?.length ||
      filteredMia.MEDIUM?.activities?.length ||
      filteredMia.LOW?.activities?.length
    );
  }, [
    filteredMia.DELETED?.activities?.length,
    filteredMia.HIGH?.activities?.length,
    filteredMia.LOW?.activities?.length,
    filteredMia.MEDIUM?.activities?.length
  ]);

  return (
    <div className="h-full">
      <ScheduleImpactSummary scheduleImpactSummary={scheduleImpactSummary} />
      <div className="flex">
        <div className="h-full w-full schedule-compare-v3">
          {isAffectedSubmittalEnabled && (
            <div className="">
              <div className="flex">
                <h3 className="font-bold ml-2 mt-3">Impacted Submittals:</h3>
              </div>
              <div>
                {hasImpactedSubmittals ? (
                  <div className="h-full overflow-auto">
                    {RenderImpactCategory(formattedImpactedSubmittal.HIGH)}
                    {RenderImpactCategory(formattedImpactedSubmittal.MEDIUM)}
                    {RenderImpactCategory(formattedImpactedSubmittal.LOW)}
                    {isShowDeletedActivitiesEnabled &&
                      RenderImpactCategory(formattedImpactedSubmittal.DELETED)}
                  </div>
                ) : (
                  <div className="pl-2">
                    {isLoadingData.current ? (
                      <span className="loading-dots">Loading</span>
                    ) : (
                      "No Submittals are impacted."
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {isAffectedMaterialEnabled && (
            <div className="">
              <div className="flex">
                <h3 className="font-bold ml-2  mt-3">Impacted Materials:</h3>
              </div>
              <div>
                {hasImpactedMaterials ? (
                  <div className="h-full overflow-auto">
                    {RenderImpactCategory(formattedImpactedMaterial.HIGH)}
                    {RenderImpactCategory(formattedImpactedMaterial.MEDIUM)}
                    {RenderImpactCategory(formattedImpactedMaterial.LOW)}
                    {isShowDeletedActivitiesEnabled &&
                      RenderImpactCategory(formattedImpactedMaterial.DELETED)}
                  </div>
                ) : (
                  <div className="pl-2">
                    {isLoadingData.current ? (
                      <span className="loading-dots">Loading</span>
                    ) : (
                      "No Materials are impacted."
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default CompareScheduleV3;
