import { gql } from "@apollo/client";
import { message } from "antd";
import {
  InfoFeatureType,
  TAuditChangeLog
} from "change-events/change-event-polling";
import {
  CompanyParticipationType,
  UserParticipationType
} from "constants/index";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  MUTATION_UPDATE_FEATURE_USER_PARTICIPANT,
  UPDATE_FEATURE_BID_PACKAGE_BY_PK,
  UPDATE_FEATURE_INSTANCE_BY_PK,
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET,
  UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE,
  UPDATE_FEATURE_INSTANCE_SPEC_SECTION,
  UPDATE_FEATURE_COMPANY_PARTICIPANT,
  UPDATE_FEATURE_COMPANY_PARTICIPANT_WITH_EMPTY_USERS
} from "entity-app/graphQL/ciq-feature-mutations";
import { FEATURE_INSTANCE_DETAILS_BY_PK } from "entity-app/graphQL/ciq-feature-queries";
import { TFetureDetailUpdateAction } from "entity-app/models";
import { getGQLQueryData } from "entity-app/services";
import { hasTheFeature } from "entity-app/utils/utils";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

const allImpactingFeatureTypes: any = [
  InfoFeatureType.designPackage,
  InfoFeatureType.bidPackage,
  InfoFeatureType.planningActivity,
  InfoFeatureType.planningMaterial,
  InfoFeatureType.planningSubmittal
];

export const useManageFeatureInstanceDetails = (
  featureId: string
): {
  featureInstanceData: any;
  actions: TFetureDetailUpdateAction;
} => {
  const {
    gqlClientForProject,
    tokenRetrievalState,
    eventLogs
  }: TProjectContext = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [mutationUpdateFeatureInstanceFields] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureInstanceWorkflowTemplate] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureBidPackageFields] = useCIQMutation(
    gql(UPDATE_FEATURE_BID_PACKAGE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureSpecSection] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_SPEC_SECTION),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureCompanyParticipant] = useCIQMutation(
    gql(UPDATE_FEATURE_COMPANY_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureCompanyParticipantWithEmptyUsers] =
    useCIQMutation(gql(UPDATE_FEATURE_COMPANY_PARTICIPANT_WITH_EMPTY_USERS), {
      client: gqlClientForProject
    });

  const [mutationUpdateFeatureWorkflowInstanceOffset] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET),
    {
      client: gqlClientForProject
    }
  );
  const [mutationUpdateFeatureWorkflowInstanceMilestone] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstance] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureUserParticipant] = useCIQMutation(
    gql(MUTATION_UPDATE_FEATURE_USER_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [featureInstanceData, setFeatureInstanceData] = useState();

  const getFeatureInstanceData = useCallback(async () => {
    if (!token) {
      return;
    }

    const fetchInstanceData = async () => {
      const featureInstanceDataResp = await getGQLQueryData(
        token,
        FEATURE_INSTANCE_DETAILS_BY_PK,
        {
          featureId
        }
      );
      setFeatureInstanceData(
        featureInstanceDataResp.data.data.feature_instance_by_pk
      );
    };

    await fetchInstanceData();

    setTimeout(fetchInstanceData, 3000); // Calling after 3 second
  }, [featureId, token]);

  const updateFeatureInstanceWorkflowTemplate = useCallback(
    async (workflowTemplateId: string) => {
      const payload: any = {
        variables: {
          featureInstanceId: featureId,
          workflowTemplateId
        }
      };

      const updateResponse =
        await mutationUpdateFeatureInstanceWorkflowTemplate(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationUpdateFeatureInstanceWorkflowTemplate
    ]
  );

  const updateFeatureInstanceFields = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          set: params
        }
      };

      const updateResponse = await mutationUpdateFeatureInstanceFields(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureInstanceFields]
  );

  const updateBidPackageFields = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          set: params
        }
      };
      const updateResponse = await mutationUpdateFeatureBidPackageFields(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, mutationUpdateFeatureBidPackageFields]
  );

  const updateFeatureInstanceBidScope = useCallback(
    async (params: { ids: Array<string> }) => {
      const { ids } = params;
      const objects = ids.map((spec_section_id: string) => ({
        bid_scope: true,
        feature_instance_id: featureId,
        spec_section_id
      }));

      const variables: any = {
        instance_id: featureId,
        objects
      };
      const updateResponse = await mutationUpdateFeatureSpecSection({
        variables
      });

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to add.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureSpecSection]
  );

  const updateFeatureCompanyParticipant = useCallback(
    async (params: {
      vendorIds: Array<string>;
      companyParticipantTypeId: CompanyParticipationType;
    }) => {
      const { vendorIds, companyParticipantTypeId } = params;

      const objects = vendorIds.map((id) => ({
        instance_id: featureId,
        company_participation_type_id: companyParticipantTypeId,
        vendor_id: id
      }));
      const variables: any = {
        instance_id: featureId,
        company_participation_type_id: companyParticipantTypeId,
        objects
      };
      const updateResponse = await mutationUpdateFeatureCompanyParticipant({
        variables
      });

      if (updateResponse?.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureCompanyParticipant]
  );

  const updateFeatureCompanyParticipantWithEmptyUsers = useCallback(
    async (params: {
      vendorIds: Array<string>;
      companyParticipantTypeId: CompanyParticipationType;
      userParticipationTypeId: UserParticipationType;
    }) => {
      const { vendorIds, companyParticipantTypeId, userParticipationTypeId } =
        params;

      const objects = vendorIds.map((id) => ({
        instance_id: featureId,
        company_participation_type_id: companyParticipantTypeId,
        vendor_id: id
      }));
      const variables: any = {
        instance_id: featureId,
        company_participation_type_id: companyParticipantTypeId,
        user_participation_type_id: userParticipationTypeId,
        objects
      };
      const updateResponse =
        await mutationUpdateFeatureCompanyParticipantWithEmptyUsers({
          variables
        });

      if (updateResponse?.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationUpdateFeatureCompanyParticipantWithEmptyUsers
    ]
  );

  const updateFeatureWorkflowInstanceOffset = useCallback(
    async (params: {
      id: string;
      set: {
        duration: number;
      };
      workflowInstanceId: string;
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse = await mutationUpdateFeatureWorkflowInstanceOffset(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstanceOffset]
  );

  const updateFeatureWorkflowInstanceMilestone = useCallback(
    async (params: {
      id: string;
      set: {
        actual_date?: string | null;
        milestone_action?: string | null;
        name?: string | null;
      };
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse =
        await mutationUpdateFeatureWorkflowInstanceMilestone(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstanceMilestone]
  );

  //

  const updateFeatureWorkflowInstance = useCallback(
    async (params: {
      id: string;
      set: {
        high_risk_threshold?: number;
        low_risk_threshold?: number;
        manual_entry_date?: string | null;
        float?: number;
        override_project_threshold?: boolean;
        workflow_template_id?: string;
      };
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse = await mutationUpdateFeatureWorkflowInstance(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstance]
  );

  const updateFeatureUserParticipant = useCallback(
    async (params: {
      user_id: string;
      user_participation_type_id: UserParticipationType;
    }) => {
      const payload: any = {
        variables: {
          featureInstanceId: featureId,
          user_participation_type_id: params?.user_participation_type_id,
          object: {
            instance_id: featureId,
            user_id: params?.user_id,
            user_participation_type_id: params?.user_participation_type_id
          }
        }
      };
      const updateResponse = await mutationUpdateFeatureUserParticipant(
        payload
      );
      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureUserParticipant]
  );

  useEffect(() => {
    if (!token) return;
    getFeatureInstanceData();
  }, [getFeatureInstanceData, token]);

  // Poll the changes and update the respective UI components
  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      // Check if the change event is related to the current bid or design instance
      // if feature_type is bid/design then match the instance id
      // if feature_type is something differnet than the design and bid
      // then check it inside the linked_features
      const conditionForBidAndDesignPackage = (log: TAuditChangeLog) => {
        return (
          allImpactingFeatureTypes.includes(log?.info?.feature_type) &&
          hasTheFeature(featureId, log, featureId)
        );
      };

      if (eventLogs.some(conditionForBidAndDesignPackage)) {
        getFeatureInstanceData();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, featureId, getFeatureInstanceData]);

  return {
    featureInstanceData,
    actions: {
      refetchFeatureInstanceData: getFeatureInstanceData,
      updateFeatureInstanceFields,
      updateFeatureInstanceWorkflowTemplate,
      updateBidPackageFields,
      updateFeatureInstanceBidScope,
      updateFeatureUserParticipant,
      updateFeatureCompanyParticipant,
      updateFeatureCompanyParticipantWithEmptyUsers,
      updateFeatureWorkflowInstanceOffset,
      updateFeatureWorkflowInstanceMilestone,
      updateFeatureWorkflowInstance
    }
  };
};
