import { useMutation, useSubscription } from "@apollo/client";
import { Button, Form, Input, message, Modal, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ErrorMessages, Regex } from "constants/index";
import { useUserProfileInfo } from "hooks/users";
import { useCallback, useContext, useEffect, useState } from "react";
import { getUser } from "services/auth";
import {
  SUBSCRIPTION_COMPANY_LIST,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES
} from "services/graphQL/subscriptions";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION } from "services/graphQL/mutations";

const { Option } = Select;

export type Role = {
  id: number;
  name: string;
};

function InviteSubscriptionUser({
  modelTitle,
  setDrawerOpen,
  showDrawerOpen,
  existingUsers
}: {
  modelTitle: string;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  existingUsers: Array<any>;
}) {
  const [form] = Form.useForm();

  const { subscriptionRole } = useContext(SubscriptionContext);

  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const [pocUsers, setPOCUsers] = useState<any>([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [currentUser]: [any, any] = useState(getUser());
  const { data: loggedInUserData } = useUserProfileInfo(currentUser.email);

  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    { shouldResubscribe: true }
  );

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  const [addUserToSubscription] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION
  );

  useEffect(() => {
    if (companyData && companyData?.subscription_vendors) {
      const subscriptionVendors = companyData?.subscription_vendors || [];
      try {
        const allCompanies = [...subscriptionVendors].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setCompanies(allCompanies);
      } catch (ex) {
        //
      }
    }
  }, [companyData]);

  useEffect(() => {
    if (userSubscriptionRoles) {
      form.setFieldValue("subscription_permission_id", 3);
      const subscriptionPermission =
        userSubscriptionRoles?.subscription_permission?.filter(
          (role: any) => role.key !== "subscription_none"
        ); // Remove None permission for editor

      if (subscriptionRole <= RoleSubscriptionEnum.subscription_editor) {
        const roles = subscriptionPermission?.filter(
          (role: any) => role.name !== "Admin"
        );
        setUserRoles(roles);
      } else {
        setUserRoles(subscriptionPermission);
      }
    }
  }, [form, subscriptionRole, userSubscriptionRoles]);

  const setSelectedPoc = useCallback(
    (userdata: any) => {
      const fields: any = [
        {
          name: "first_name",
          value: userdata?.first_name || ""
        },
        {
          name: "last_name",
          value: userdata?.last_name || ""
        },
        {
          name: "email",
          value: userdata?.email || ""
        },
        {
          name: "phone",
          value: userdata?.phone || ""
        }
      ];

      form.setFields(fields);
    },
    [form]
  );

  const setPocUsersOfSelectedCompany = useCallback(
    (company_id: string) => {
      const selectedCompany = companies.find(
        (cmpny: any) => cmpny.id === company_id
      );
      if (selectedCompany?.trade_partner_pocs?.length > 0) {
        const remainingUsers: Array<any> =
          selectedCompany.trade_partner_pocs.filter(
            (u: any) => !existingUsers.some((e) => e.user.email === u.email)
          );

        if (remainingUsers.length > 0) {
          setPOCUsers(remainingUsers);
          const userData = remainingUsers[0];
          setSelectedPoc(userData);
          form.setFieldValue(
            "select_poc_user",
            `${userData.first_name} ${userData.last_name}`
          );
        } else {
          setSelectedPoc({});
          setPOCUsers([]);
        }
      } else {
        setSelectedPoc({});
        setPOCUsers([]);
      }
    },
    [companies, existingUsers, form, setSelectedPoc]
  );

  useEffect(() => {
    if (
      companies?.length > 0 &&
      loggedInUserData?.subscription_users?.length &&
      loggedInUserData?.subscription_users[0]?.subscription
    ) {
      const id =
        loggedInUserData?.subscription_users[0]?.subscription
          ?.default_vendor_id;
      form.setFieldValue("company", id);
      setPocUsersOfSelectedCompany(id);
    }
  }, [
    companies,
    form,
    loggedInUserData?.subscription_users,
    setPocUsersOfSelectedCompany
  ]);

  const onSendInvite = async (values: any) => {
    setResponseLoading(true);

    const userMap: any = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      subscription_permission: values.subscription_permission_id,
      company_id: values.company
    };
    if (values.phone) {
      userMap.phone = values.phone;
    }

    const requestedUsers = [userMap];
    try {
      const addUserResponse = await addUserToSubscription({
        variables: { users: requestedUsers }
      });

      if (addUserResponse?.errors && addUserResponse?.errors.length > 0) {
        message.error(addUserResponse.errors[0]?.message);
        return;
      }

      setDrawerOpen(false);
      if (addUserResponse?.data?.insert_subscription_users_multi?.message) {
        message.success(
          addUserResponse?.data?.insert_subscription_users_multi?.message
        );
      } else {
        message.error("Unable to invite user.");
      }
    } catch (ex) {
      console.log(ex);

      message.error(ex);
    } finally {
      setResponseLoading(false);
    }
  };

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="flex-col h-full overflow-y overflow-x-hidden px-3">
        <Form
          preserve={false}
          onFinish={onSendInvite}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <FormItem name="company" label="Company">
            <Select
              loading={companyDataLoading}
              disabled={companyDataLoading}
              onChange={(value) => {
                setPocUsersOfSelectedCompany(value);
              }}
              showSearch
              filterOption={(input, option) => {
                const inputMes = input.toString().toLowerCase();
                const label = (option?.label || "").toLowerCase();
                return label.includes(inputMes);
              }}
              options={companies?.map((c) => ({ value: c.id, label: c.name }))}
            />
          </FormItem>

          {pocUsers?.length > 1 && (
            <FormItem label="Select user" name="select_poc_user">
              <Select
                placeholder="Select User"
                loading={companyDataLoading}
                disabled={companyDataLoading}
                className="flex w-full font-normal text-slate-700"
                onChange={(index: any) => {
                  const userdata = pocUsers[index];
                  setSelectedPoc(userdata);
                }}
                showSearch
                allowClear
                onClear={() => setSelectedPoc({})}
                filterOption={(input, option: any) => {
                  const user = pocUsers.find((u: any) => u.id === option.key);
                  const inputMes = input.toString().toLowerCase();
                  const userData = [
                    user.email.toLowerCase(),
                    `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`
                  ];
                  return userData.some((text) => text.includes(inputMes));
                }}
              >
                {pocUsers.map((item: any, index: number) => {
                  return (
                    <Option key={item.id} value={index}>
                      <div>
                        {item.first_name} {item.last_name}
                      </div>
                      <div className="text-xs text-slate-500">{item.email}</div>
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          )}

          <div>
            <FormItem
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.FirstName
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.LastName
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailRequired
                },
                {
                  type: "email",
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailInvalid
                },
                {
                  validateTrigger: "onSubmit",
                  validator(_, value: string) {
                    if (value === undefined || !value) return Promise.resolve();
                    const email = value.toLowerCase();
                    if (existingUsers.some((e) => e.user.email === email))
                      return Promise.reject();
                    return Promise.resolve();
                  },
                  message: ErrorMessages.EmailAlreadyExist
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              rules={[
                {
                  message: ErrorMessages.PhoneNumber,
                  validateTrigger: "onSubmit",
                  pattern: Regex.phoneNumber
                }
              ]}
            >
              <Input />
            </FormItem>

            <FormItem
              name="subscription_permission_id"
              label="Permission"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.SubscriptionRole,
                  validator(_, value: string) {
                    if (!value) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                className="w-full"
                disabled={!userRoles.length}
                options={
                  userRoles?.map((role) => ({
                    value: role.id,
                    label: role.name
                  })) || []
                }
              />
            </FormItem>
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              disabled={responseLoading}
              loading={responseLoading}
              type="primary"
            >
              Send Invite
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteSubscriptionUser;
