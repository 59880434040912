import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGetGQLClientForProject } from "hooks/init-gql-client";

import jwtDecode from "jwt-decode";
import { NotFoundPage } from "pages";
import moment from "moment";
import { message } from "antd";
import { simplifyProjectIntegrationConfigs } from "pages/subscription-settings/integrations-tab-new/utils";
import useChangeEventPolling, {
  TAuditChangeLog
} from "change-events/change-event-polling";
import { useProjectGlobalDataSource } from "hooks/project-global-data";
import {
  EUserRoleName,
  EUserTypes,
  ErrorMessages,
  ProjectPermissionEnum
} from "../constants";

export interface ProjectProviderProps {
  children: React.ReactNode;
}

export type ProjectSettingType = {
  id: string;
  mode: number;
  spec_section: any;
  name: string;
  timezone: {
    timezone_id: string;
    name: string;
  };
};

export type TProjectContext = {
  gqlClientForProject: any;
  tokenRetrievalState: any;
  tokenContents?: {
    userType: string;
    role: string;
    claims: any;
  };
  calHolidaysWeekends?: { holidays: Array<any>; weekends: Array<any> };
  columnHeaders: {
    submittalHeaders: any;
    materialHeaders: any;
    submittalHeaderMap: any;
    materialHeaderMap: any;
  };
  projectDetails: ProjectSettingType | undefined;
  projectIntegrationConfig: any;
  eventLogs: Array<TAuditChangeLog>;
  lastImportLogData: any;
  isFeatureFlagEnabled: (feature: string) => Boolean;
};

export const ProjectContext = createContext<TProjectContext>({
  gqlClientForProject: null,
  tokenRetrievalState: null,
  tokenContents: undefined,
  calHolidaysWeekends: { holidays: [], weekends: [] },
  columnHeaders: {
    submittalHeaders: null,
    materialHeaders: null,
    submittalHeaderMap: {},
    materialHeaderMap: {}
  },
  projectDetails: undefined,
  projectIntegrationConfig: null,
  eventLogs: [],
  lastImportLogData: null,
  isFeatureFlagEnabled: () => false
});

const getUserTypeFromToken = (token: string) => {
  const decryptedToken: any = jwtDecode(token);
  try {
    return (
      decryptedToken["https://hasura.io/jwt/claims"]["x-hasura-user-type"] ||
      EUserTypes[EUserTypes.GENERAL_CONTRACTOR]
    );
  } catch (ex) {
    return EUserTypes[EUserTypes.GENERAL_CONTRACTOR];
  }
};

const getProjectRoleFromToken = (token: string) => {
  const decryptedToken: any = jwtDecode(token);
  try {
    return (
      decryptedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ||
      EUserRoleName[EUserRoleName.owner]
    );
  } catch (ex) {
    return EUserRoleName[EUserRoleName.owner];
  }
};

const getUserClaimsFromToken = (token: string) => {
  const decryptedToken: any = jwtDecode(token);
  return decryptedToken["https://hasura.io/jwt/claims"];
};

export const isPermissionNotGrantted = (
  type: ProjectPermissionEnum,
  role: string
) => {
  switch (type) {
    case ProjectPermissionEnum.ViewProjectList: {
      return false; // All have access
    }
    case ProjectPermissionEnum.AddProjectUser: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.architect],
        EUserRoleName[EUserRoleName.design_engineer]
      ];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.EditProjectDetail: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.CreateSubmittal: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.InlineEditSubmittal: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.SubmittalWorkflow: {
      const roles = [
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.architect],
        EUserRoleName[EUserRoleName.design_engineer],
        EUserRoleName[EUserRoleName.owner],
        EUserRoleName[EUserRoleName.gc_sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_architect],
        EUserRoleName[EUserRoleName.gc_sc_design_engineer],
        EUserRoleName[EUserRoleName.gc_sc_owner]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.AddTradePartner: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin]
      ];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.AddAttachment: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.architect],
        EUserRoleName[EUserRoleName.design_engineer],
        EUserRoleName[EUserRoleName.owner],
        EUserRoleName[EUserRoleName.gc_sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_architect],
        EUserRoleName[EUserRoleName.gc_sc_design_engineer],
        EUserRoleName[EUserRoleName.gc_sc_owner]
      ];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.AddEditCalendar: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.ViewLinkingPage: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_foreman],
        EUserRoleName[EUserRoleName.architect],
        EUserRoleName[EUserRoleName.design_engineer],
        EUserRoleName[EUserRoleName.owner]
      ];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.EditLinkingPage: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditDateBlockOffsetSubmittal: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditDateBlockOffsetMaterial: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.CreateDistributionGroup: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.EditDistributionGroup: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.LocationEdit: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.some((r) => r === role);
    }
    case ProjectPermissionEnum.CreateMaterial: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.InlineEditMaterialPrimaryFields: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditMaterialGeneralInfoAndParticipants: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditMaterialCharacteristics: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditMaterialDBOffset: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.ImportSchedule: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.MakeActiveSchedule: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.changeMaterialActualDate: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.changeProjectedDate: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.changeMaterialPlannedDate: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.DeleteMaterialAttachment: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.ViewProjectReports: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_foreman]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.UploadSpecSectionFile: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_sc_project_admin]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.ViewDesignPage: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_foreman]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.CreateMaterialComment: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_project_admin],
        EUserRoleName[EUserRoleName.gc_foreman],
        EUserRoleName[EUserRoleName.architect],
        EUserRoleName[EUserRoleName.design_engineer],
        EUserRoleName[EUserRoleName.owner],
        EUserRoleName[EUserRoleName.gc_sc_architect],
        EUserRoleName[EUserRoleName.gc_sc_design_engineer],
        EUserRoleName[EUserRoleName.gc_sc_owner]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.CreateMaterialCommentIfAssignee: {
      const roles = [
        EUserRoleName[EUserRoleName.gc_sc_project_admin],
        EUserRoleName[EUserRoleName.owner],
        EUserRoleName[EUserRoleName.gc_sc_owner]
      ];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.ChangeMaterialTemplate: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.EditMaterialDBTemplate: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }

    case ProjectPermissionEnum.BidPackageWrite: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    case ProjectPermissionEnum.DesignPackageWrite: {
      const roles = [EUserRoleName[EUserRoleName.gc_project_admin]];
      return !roles.includes(role);
    }
    default:
      return true;
  }
};

function ProjectProvider({ children }: ProjectProviderProps) {
  const [projectPermissionAccess, setProjectPermissionAccess] = useState(true);
  const [projectState, setProjectState] = useState<TProjectContext>({
    gqlClientForProject: null,
    tokenRetrievalState: null,
    columnHeaders: {
      submittalHeaders: null,
      materialHeaders: null,
      submittalHeaderMap: {},
      materialHeaderMap: {}
    },
    projectDetails: undefined,
    projectIntegrationConfig: null,
    eventLogs: [],
    lastImportLogData: null,
    isFeatureFlagEnabled: () => false
  });
  const { projectId } = useParams() as any;

  const { gqlClientForProject, tokenRetrievalState } =
    useGetGQLClientForProject(projectId);

  const { data: eventLogs } = useChangeEventPolling({ gqlClientForProject });

  const {
    projectDetails: projectDetailsData,
    calendarOffs,
    columnHeaders,
    projectIntgConfigs,
    lastImportStatus,
    isFeatureFlagEnabled
  } = useProjectGlobalDataSource(eventLogs, gqlClientForProject, { projectId });

  useEffect(() => {
    setProjectState((prev: TProjectContext) => {
      return {
        ...prev,
        eventLogs
      };
    });
  }, [eventLogs]);

  useEffect(() => {
    setProjectState((prev: TProjectContext) => {
      return {
        ...prev,
        lastImportLogData: lastImportStatus
      };
    });
  }, [lastImportStatus]);

  useEffect(() => {
    if (projectDetailsData && projectDetailsData.project_by_pk) {
      try {
        moment.tz.setDefault(
          projectDetailsData.project_by_pk.timezone.timezone_id
        );
      } catch (err) {
        console.log(err);
        message.error(ErrorMessages.TimezoneFetchFailed);
      }
      setProjectState((pre) => ({
        ...pre,
        projectDetails: projectDetailsData.project_by_pk
      }));
    }
  }, [projectDetailsData]);

  useEffect(() => {
    const projectIntegrationConfig = simplifyProjectIntegrationConfigs(
      projectIntgConfigs?.project_integration_configs
    );
    setProjectState((pre) => ({
      ...pre,
      projectIntegrationConfig
    }));
  }, [projectIntgConfigs?.project_integration_configs]);

  useEffect(() => {
    if (tokenRetrievalState.error === true) {
      setProjectPermissionAccess(false);
    }
    if (
      projectId &&
      gqlClientForProject &&
      tokenRetrievalState.success &&
      tokenRetrievalState.token
    ) {
      setProjectPermissionAccess(true);
      setProjectState((pre) => ({
        ...pre,
        gqlClientForProject,
        tokenRetrievalState,
        tokenContents: {
          userType: getUserTypeFromToken(tokenRetrievalState.token),
          role: getProjectRoleFromToken(tokenRetrievalState.token),
          claims: getUserClaimsFromToken(tokenRetrievalState.token)
        },
        calHolidaysWeekends: calendarOffs,
        columnHeaders,
        isFeatureFlagEnabled
      }));

      (window as any).projectToken = tokenRetrievalState.token; // for dev purposes
    }
  }, [
    projectId,
    gqlClientForProject,
    tokenRetrievalState,
    calendarOffs,
    columnHeaders,
    isFeatureFlagEnabled
  ]);

  return (
    <ProjectContext.Provider value={projectState}>
      {projectPermissionAccess ? (
        children
      ) : (
        <NotFoundPage
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      )}
    </ProjectContext.Provider>
  );
}

export default ProjectProvider;
