import { ErrorBoundary } from "@sentry/react";
import FeatureToFeatureLinksManagePanel from "components/feature-to-feature-links-manage-panel";
import { TFetureDetailUpdateAction } from "entity-app/models";
import EntityDateblock from "entity-app/shared-components/entity-dateblock";

function HeadingDateBlock() {
  return (
    <div className="flex w-full h-14 justify-between items-center px-4 py-2 text-[#000000D9]">
      <div className="uppercase text-sm font-bold">DESIGN PACKAGE SCHEDULE</div>
    </div>
  );
}

function DesignLinkedTopSection(props: {
  featureInstanceData: any;
  actions: TFetureDetailUpdateAction;
  disabled: boolean;
}) {
  const { featureInstanceData, actions, disabled } = props;
  return (
    <div className="w-full h-[470px] border border-[#3b3b3b80] border-solid flex">
      <div className="grow overflow-auto border-0 border-r border-solid border-[#3b3b3b80]">
        <HeadingDateBlock />
        <div className="overflow-auto px-3 flex items-center">
          <EntityDateblock
            actions={actions}
            workflowInstanceMilestones={
              featureInstanceData?.workflow_instance_milestones
            }
            featureWorkflowInstance={
              featureInstanceData?.feature_workflow_instance
            }
            showFloat={featureInstanceData?.feature?.show_float}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="w-[280px] shrink-0 overflow-y-auto">
        <ErrorBoundary>
          <FeatureToFeatureLinksManagePanel
            featureId={featureInstanceData?.id}
            title="LINKED BID PACKAGES"
            showDependencyButton
            disabled={disabled}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
}
export default DesignLinkedTopSection;
