import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import { GridLoadingIndicator } from "components/widgets";

import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_PROJECT_VENDORS } from "services/graphQL/subscriptions";
import InviteProjectCompanies from "components/invite-project-companies";
import { QUERY_USER_TYPE } from "services/graphQL/queries";
import {
  getProjectVendorSources,
  isProjectInIntegrationMode
} from "utils/utils";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import CiqAgSearch from "components/ciq-ag-search";
import CiqLogPageHeader, {
  calculateLogPageStats
} from "components/ciq-log-page-header";
import { projectCompanyRoleRenderer } from "components/cell-renders";
import ProjectCompanyRoleSelectCellEditor from "components/cell-editor/project-company-role-cell-editor";
import { MUTATION_UPDATE_PROJECT_VENDOR } from "services/graphQL/mutations";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";

function ProjectCompanies() {
  const gridRef = useRef<AgGridReact>(null);
  const [drawerCompany, setCompanyDrawerVisibility] = useState<boolean>(false);
  const [isGridReady, setGridReady] = useState(false);
  const { gqlClientForProject, tokenContents, projectDetails } =
    useContext(ProjectContext);
  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();
  const filterChipRef = useRef<FilterChipComponentRef>(null);

  const { data: companyTypes } = useQuery(QUERY_USER_TYPE);

  const { data: companiesData } = useSubscription(
    SUBSCRIPTION_PROJECT_VENDORS,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject && !companyTypes,
      variables: { where: {} }
    }
  );

  const [updateCompanyRole] = useMutation<any>(MUTATION_UPDATE_PROJECT_VENDOR, {
    client: gqlClientForProject
  });

  const isPermissionEditable = useCallback((params: any) => {
    const { context } = params;

    const rolePermission = isPermissionNotGrantted(
      ProjectPermissionEnum.AddProjectUser,
      context?.tokenContents?.role!
    );

    return rolePermission;
  }, []);

  const updateCompanyPermission = async (event: any) => {
    if (event.newValue === event.oldValue) return;
    const companyRole = companyTypes?.user_types?.find(
      (role: any) => role?.id === event.newValue
    );

    if (companyRole?.id) {
      const variables = {
        where: { vendor_id: { _eq: event?.data?.vendor_id } },
        _set: { type_id: companyRole.id }
      };

      const updateResponse = await updateCompanyRole({
        variables
      });
      try {
        if (updateResponse.data?.update_project_vendors?.affected_rows > 0) {
          message.success("Role of company updated successfully.");
        } else {
          message.error("Failed to update.");
        }
      } catch (ex) {
        message.error("An error occured");
      }
    }
  };

  const cellEditRequest = async (event: any) => {
    if (!event.newValue) return;
    if (event.column.colId === "project_vendors_type.name") {
      updateCompanyPermission(event);
    }
  };
  // Each Column Definition results in one Column.

  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Serial No.",
        valueGetter: "node.rowIndex + 1",
        headerTooltip: "SERIAL NO.",
        tooltipField: "node.rowIndex + 1",
        menuTabs: [],
        width: 100,
        sortable: false,
        editable: false
      },
      {
        field: "subscription_vendor.name",
        headerName: "Company Name",
        headerTooltip: "COMPANY NAME",
        menuTabs: [],
        filter: false,
        tooltipField: "subscription_vendor.name",
        sort: "asc"
      },
      {
        field: "project_vendors_type.name",
        colId: "project_vendors_type.name",
        headerName: "Role of Company",
        headerTooltip: "ROLE OF COMPANY",
        menuTabs: ["filterMenuTab"],
        filter: true,
        tooltipField: "project_vendors_type.name",
        editable: (params) => {
          return isPermissionEditable(params);
        },
        cellRenderer: (params: any) => {
          return projectCompanyRoleRenderer(params);
        },
        cellEditor: ProjectCompanyRoleSelectCellEditor,
        cellClass: (params: any) => {
          return isPermissionEditable(params) ? "cell-editable" : "";
        },
        minWidth: 240
      },
      {
        colId: "external_source_id",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegrationMode,
        valueGetter: ({ data }) => {
          const allSources = getProjectVendorSources(data);
          return allSources.join(",");
        }
      }
    ];
  }, [isIntegrationMode, isPermissionEditable]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    };
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const contextData = {
    companyTypes,
    projectRole: tokenContents?.role
  };

  const onFiltersApplied = () => {
    filterChipRef.current?.onFilterApplied();
    setStatsData(
      calculateLogPageStats({
        gridRef
      })
    );
  };

  const headerItems = [
    <CiqAgSearch
      key="ProjectCompany_search"
      gridRef={gridRef}
      placeholder="Search Project Companies"
    />,
    <FilterChipComponent
      key="ProjectCompany_FilterChip"
      columnDefs={columnDefs}
      gridRef={gridRef}
      ref={filterChipRef}
    />,
    <Button
      key="ProjectCompany_AddCompany"
      onClick={() => {
        setCompanyDrawerVisibility(true);
      }}
      title={
        isPermissionNotGrantted(
          ProjectPermissionEnum.AddTradePartner,
          tokenContents?.role!
        )
          ? ErrorMessages.PermissionNotGranted
          : "Add Company"
      }
      disabled={isPermissionNotGrantted(
        ProjectPermissionEnum.AddTradePartner,
        tokenContents?.role!
      )}
    >
      Add Company
    </Button>
  ];

  return (
    <div className="flex flex-col h-[calc(100vh-160px)]">
      <CiqLogPageHeader
        className="flex justify-between items-center"
        {...{
          entityName: "Companies",
          titleParam: {
            title: "Companies",
            totalCount: companiesData?.project_vendors?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems
        }}
      />
      <div className="grow ag-theme-alpine">
        <AgGridReact<any>
          ref={gridRef}
          onGridReady={() => {
            gridRef.current!.api.sizeColumnsToFit();
            setGridReady(true);
          }}
          rowData={companiesData?.project_vendors}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          context={contextData}
          onFilterChanged={onFiltersApplied}
          readOnlyEdit
          onCellEditRequest={cellEditRequest}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
      {drawerCompany && (
        <InviteProjectCompanies
          setDrawerVisibility={setCompanyDrawerVisibility}
          showAddProjectCompanyModel={drawerCompany}
        />
      )}
    </div>
  );
}

export default ProjectCompanies;
