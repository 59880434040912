import { Button, Divider, Form, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useMemo, useState } from "react";
import { GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION } from "services/graphQL/ciq-queries";
import { MUTATION_SEND_FEATURE_WF_INVITE } from "services/graphQL/mutations";
import { IMaterial } from "./type-definitions";

function RequestDurationsSingle({
  onCancel,
  onComplete,
  selectedItem
}: {
  onCancel: () => void;
  onComplete: () => void;
  selectedItem: {
    id: string;
  };
}) {
  // console.log(selectedItem);

  const { gqlClientForProject } = useContext(ProjectContext);

  const [submitting, setSubmitting] = useState(false);

  const { data: queryResponse, loading } = useCIQQuery<{
    material: IMaterial[];
  }>(GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION, {
    client: gqlClientForProject,
    variables: {
      materialIds: [selectedItem.id]
    }
  });

  const [sendFeatureWFInvite] = useCIQMutation(
    MUTATION_SEND_FEATURE_WF_INVITE,
    {
      client: gqlClientForProject
    }
  );

  // console.log("queryResponse ", queryResponse);

  const materialData: any = useMemo(() => {
    if (!queryResponse) return null;
    const material = queryResponse.material[0];
    return {
      id: material.id,
      name: material.name,
      materialAssignee: material.material_assignee,
      assigneeName: material.material_assignee
        ? `${material.material_assignee?.first_name} ${material.material_assignee?.last_name}`
        : "",
      assigneeEmail: material.material_assignee?.email,
      tradePartner: material.materials_trade_partner?.name
    };
  }, [queryResponse]);

  // console.log("materialData ", materialData);

  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    const variables = {
      sendEstimateInviteData: [
        {
          assignee: materialData.materialAssignee.id,
          material_ids: [materialData.id],
          notes: values?.notes?.trim()
        }
      ]
    };

    // console.log("variables ", variables);

    setSubmitting(true);
    const response = await sendFeatureWFInvite({ variables });
    // console.log("response ", response);

    if (response.success) {
      message.success("Invite sent successfully");
      onComplete();
    }
    setSubmitting(false);
  };

  if (loading && !queryResponse)
    return (
      <div className="min-h-[260px] flex justify-center items-center">
        <Spin size="small" data-testid="loading-spinner" />
      </div>
    );

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <div className="px-4 py-3">
          <div className="text-sm">
            This will send an email to the Assigned Subcontractor with a link to
            enter durations. The changes will reflect on the Dateblock.
          </div>
          <div className="my-5 space-y-1">
            {materialData?.materialAssignee && (
              <div>
                <div className="text-sm font-semibold">
                  {materialData?.tradePartner}
                </div>
                <div className="text-xs">
                  {materialData?.assigneeName} ({materialData?.assigneeEmail})
                </div>
              </div>
            )}
            <div className="text-xs">{materialData?.name}</div>
          </div>
          <div>
            <Form.Item label="Notes" name="notes">
              <TextArea
                rows={2}
                placeholder="Add a Note to your Request"
                autoSize={{ minRows: 2, maxRows: 2 }}
                autoFocus
              />
            </Form.Item>
          </div>
        </div>
        <Divider className="m-0 p-0" />
        <div className="flex justify-end gap-2 px-4 py-3">
          <Button
            type="default"
            onClick={onCancel}
            disabled={submitting}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={submitting}
            data-testid="send-request-button"
          >
            Send Request
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default RequestDurationsSingle;
