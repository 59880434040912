import { message, Spin, notification } from "antd";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  CsvExportParams,
  ValueFormatterParams,
  SideBarDef
} from "ag-grid-community";
import "./submittal-list.css";
import { SubmittalLog } from "models/submittal-log";
import {
  matchPath,
  useHistory,
  useParams,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import { FilterItem } from "models/types";
import numberCellEditor from "components/cell-editor/numberCellEditor";
import {
  IdLinkComponent,
  submittalCellRenderer,
  SubmittalStatusCellRenderer,
  dateCellRenderer,
  TaskLinksCellRenderer,
  MaterialLinksCellRenderer,
  responsibleContractorCellRenderer,
  userOrgCellRenderer,
  specSectionRendererWithFileViewer,
  dropDownCellRendererWithCustomEdit,
  userOrgCellRendererWithCustomEdit,
  responsibleContractorCellRendererWithCustomEdit,
  nextDeadlineCellRenderer,
  submittalDueDateHeaderComponent,
  CellValueRendererWithEditIcon,
  editSubmittalFloatCellRenderer,
  dueDateCellRenderer,
  riskCellRenderer
} from "components/cell-renders";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import SubmittalLogCreateComponent from "pages/submittal-log-create";
import { SelectCellEditor } from "components/cell-editor";
import { LinkOutlined } from "@ant-design/icons";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { SUBSCRIPTION_PROJECT_SPECS } from "services/graphQL/subscriptions";
import SelectNotFoundContent from "components/widgets/select-notfound-content";
import { agGridCheckFieldEditable } from "components/submittal-details/helpers";
import { getLoggedinUserId, getUser } from "services/auth";
import { SubmitterSelectCellEditor } from "components/cell-editor/submitter-select-cell-editor";
import SpecSectionCellEditor from "components/cell-editor/spec-section-editor";
import { DateFilter, DateUtils } from "utils/dateutils";
import { AppContext } from "context/AppProvider";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants,
  getSubmittalUserById,
  getSubmittalCompanyById
} from "hooks/project-participants";
import UserSelectCellEditor from "components/cell-editor/user-select-cell-editor";
import ResponsibleContractorCellEditor from "components/cell-editor/responsible-contractor-cell-editor";
import ImportACCSubmittalLogs from "components/import-acc-submittal-logs";
import {
  MUTATION_SAVE_COLUMNS,
  MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
  MUTATION_UPDATE_SUBMITTAL_BY_ID
} from "services/graphQL/mutations";
import {
  QUERY_SAVED_COLUMN_HEADERS,
  QUERY_SUBMITTAL_TYPES
} from "services/graphQL/queries";
import { getMilestoneColumns } from "components/milestones-columns";
import { useShowDesignAndSpec } from "hooks/common";
import ErrorBoundary from "components/error-boundary";
import { isProjectInIntegrationMode, matchUserProjectRole } from "utils/utils";
import { GridStatusMessage } from "components/widgets/grid-status-message";
import { useGetProjectINTGState } from "hooks/submittal";
import FileViewerModal from "components/file-viewer-modal/file-viewer-modal";
import { applySubmittalLogFilters } from "utils/ag-grid-programmatic-filtering";
import { priorities } from "components/submittal-details/submittal-details";
import { NotificationPlacement } from "antd/lib/notification";
import { TSaveColumnState } from "components/column-config-views";
import BulkEditSubmittalDetails from "components/bulk-edit-submittal/bulk-edit-submittal-details";
import BulkEditSubmittalDurations from "components/bulk-edit-submittal/bulk-edit-submittal-durations";
import CustomDateFilters from "./custom-date-filter";
import "../../index.css";

import {
  ESubmittalStatus,
  EUserTypes,
  ProjectPermissionEnum,
  submittalLogFilterTitles,
  SubmittalStatusToStrMap
} from "../../constants";

import SubmittalListFilterComponent from "./filter-bar";
import ImportSubmittalLogs from "../../components/import-submittal-logs";
import { updateSubmittalList } from "./helpers";
import SubmittalTooltip from "./submittal-tooltip";
import { useSubmittalListPerf } from "./hooks/submittal";

const maxNumberOfMilestone = {
  isLastMileStone: false,
  numberOfMaxMileStones: 5
};

function SubmittalList() {
  const match = useRouteMatch();
  const history = useHistory();
  const { projectId } = useParams() as any;
  const loggedInUserInfo = useMemo(getUser, []);
  const [displayRowCount, setDisplayRowCount] = useState(0);

  const gridRef = useRef<AgGridReact<SubmittalLog>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const [gridData, setGridData] = useState<{
    submittals: any[];
    statusesMap: any;
  }>({
    submittals: [],
    statusesMap: null
  });

  const [typesMap, setTypesMap] = useState(null);

  const [customDateFilter, setCustomDateFilter] = useState<any>({});
  const [filters, setFilters] = useState<FilterItem[]>([]);

  const [showNewLogDrawer, setshowNewLogDrawer] = useState<boolean>(false);

  const [showImportLogDrawer, setshowImportLogDrawer] =
    useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [showSubmittalBulkEditDetails, setShowSubmittalBulkEditDetails] =
    useState(false);

  const [showSubmittalBulkEditDurations, setShowSubmittalBulkEditDurations] =
    useState(false);

  const clearFilterRef = useRef(false);

  const [specFileModalData, setSpecFileModalData] = useState<null | {
    file_key: string;
    pageid: number;
    spec_no: string;
    spec_name: string;
  }>(null);

  const [gridSearchtext, setGridSearchtext] = useState("");

  const [stats, setStats] = useState<any>("");
  const [filterState, setFilterState] = useState<any>({});

  const [skipSubscribe, setSkipSubscribe] = useState(true);

  const { isDesignTabEnabled } = useShowDesignAndSpec();
  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);

  const milestonesColumns = useMemo(
    () =>
      getMilestoneColumns({
        setCustomDateFilter,
        totalPossibleMileStones: maxNumberOfMilestone.numberOfMaxMileStones
      }),
    []
  );

  const {
    gqlClientForProject,
    tokenRetrievalState,
    tokenContents,
    columnHeaders: { submittalHeaderMap },
    projectDetails,
    projectIntegrationConfig
  }: TProjectContext = useContext(ProjectContext);

  const { submittalListFilter, setSubmittalListFilter }: any =
    useContext(AppContext);

  const existingSubmittalsCountRef = useRef<any>();
  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  // TODO: Commented out subscription code due to loop calls on filterchange events. will refactor in feature.
  // const { query: qColumnHeaders, sub: sColumnHeaders } = useMemo(() => {
  //   return getGQL(SAVED_COLUMN_HEADERS);
  // }, []);
  // const { data: savedColumnsData } = useQuerySubscription(
  //   qColumnHeaders,
  //   sColumnHeaders,
  //   {
  //     client: gqlClientForProject,
  //     skip: !gqlClientForProject || !getLoggedinUserId(),
  //     variables: {
  //       where: { user_id: { _eq: getLoggedinUserId() } }
  //     }
  //   }
  // );

  const { data: savedColumnsData } = useQuery(QUERY_SAVED_COLUMN_HEADERS, {
    fetchPolicy: "no-cache",
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: { user_id: { _eq: getLoggedinUserId() } }
    }
  });

  const savedColumns = useMemo(() => {
    if (!savedColumnsData) return null;
    if (savedColumnsData?.project_users?.length === 0) return null;
    const resArr: any[] = savedColumnsData?.project_users;
    const colConfig = resArr[resArr.length - 1];
    return {
      submittals: colConfig.submittal_column_config,
      materials: colConfig.material_column_config
    };
  }, [savedColumnsData]);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { data: submittalTypes, error: errorSubmittalTypes } = useQuery(
    QUERY_SUBMITTAL_TYPES
  );

  const { data: specSections } = useSubscription(SUBSCRIPTION_PROJECT_SPECS, {
    client: gqlClientForProject
  });

  // const isLoggedInUserGcAdmin =
  //   tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin] ||
  //   tokenContents?.role === EUserRoleName[EUserRoleName.gc_sc_project_admin];

  const { projectParticipants: WFProjectParticipants } =
    useProjectParticipants(isIntegrationMode);

  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  useEffect(() => {
    // Note: to handle a glitch in useSubscription hook.. navigate to a submittal detail page from the submittal list and navigate back immediately before the details page loads using "All submittals" navbutton causing the submittal list to not load. mainly happening due to subscription to SUBSCRIPTION_SUBMITTAL_STATUSES.. timeout allows the previous subscription to get cleared.
    setTimeout(() => {
      setSkipSubscribe(false);
    });
  }, []);

  const {
    data: submittalListData,
    error: errorSubmittalList,
    loading: submittalListLoading,
    loadedOnce: submittalListLoadedOnce,
    refetch: refetchSubmittalData
  } = useSubmittalListPerf({
    gqlClientForProject,
    projectDetails,
    isIntegrationMode,
    skipSubscribe,
    tokenContents
  });

  const refetchDataForPage = useCallback(() => {
    refetchSubmittalData();
  }, [refetchSubmittalData]);

  const submittalList = useMemo(() => {
    if (!projectParticipants.dataLoaded || !submittalListData) return null;

    return updateSubmittalList(submittalListData, projectParticipants);
  }, [projectParticipants, submittalListData]);

  const [updateSubmittalById] = useMutation<any>(
    MUTATION_UPDATE_SUBMITTAL_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const [saveColumnsState] = useMutation<any>(MUTATION_SAVE_COLUMNS, {
    client: gqlClientForProject
  });

  const { data: projectIntegrationQueryData, isIntegrationConfigured } =
    useGetProjectINTGState({
      client: gqlClientForProject,
      skip: !isIntegrationMode
    });

  const [updateSDB] = useMutation(MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK, {
    client: gqlClientForProject
  });

  useEffect(() => {
    if (errorSubmittalTypes || errorSubmittalList) {
      console.log(
        "Data fetch errors ",
        errorSubmittalTypes,
        errorSubmittalList
      );
      // message.error("An error occured while fetching the data");
    }
  }, [errorSubmittalTypes, errorSubmittalList]);

  useEffect(() => {
    if (tokenRetrievalState?.success === false) {
      message.error(tokenRetrievalState?.msg);
    }
  }, [gqlClientForProject, tokenRetrievalState]);

  useEffect(() => {
    return () => {
      const nextURLPath = window.location.pathname;
      const matchUrl = matchPath(nextURLPath, {
        path: "/project/:projectId/submittals/:submittalId",
        exact: true,
        strict: false
      });
      if (!matchUrl && submittalListFilter) {
        setSubmittalListFilter(null);
      }
    };
  }, [setSubmittalListFilter, submittalListFilter]);

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current)
      milestonesColumns.updateAllMileStoneInitialVisibility(
        gridRef.current?.columnApi,
        maxNumberOfMilestone
      );

    if (submittalListFilter && submittalListFilter.key) {
      applySubmittalLogFilters({
        gridRef,
        loggedInUserInfo,
        submittalListFilter,
        SubmittalStatusToStrMap
      });
    }

    if (Object.keys(filterState).length > 0 && submittalListFilter === null) {
      setTimeout(() => {
        gridRef?.current?.api?.setFilterModel(filterState);
        setTimeout(() => {
          // NOTE: To load initial data once in onFilterApplied method
          setIsFirstDataRendered(true);
        }, 2000);
      }, 0);
    }
  }, [milestonesColumns, loggedInUserInfo, submittalListFilter, filterState]);

  const canChangeSubmittalDBFloat = useCallback(
    (params: any) => {
      const { context, data } = params;
      const floatChangePermitted = !isPermissionNotGrantted(
        ProjectPermissionEnum.InlineEditSubmittal,
        tokenContents?.role!
      );

      const isSubmittalWorkflowStarted =
        context.statusesMap[data.workflow_status].name?.toLowerCase() !==
        SubmittalStatusToStrMap[ESubmittalStatus.CREATE].toLowerCase();
      return floatChangePermitted && !isSubmittalWorkflowStarted;
    },
    [tokenContents?.role]
  );

  const updateStats = useCallback(() => {
    if (!gridRef.current?.api) return;
    const displayedRowCount = gridRef.current?.api?.getDisplayedRowCount();

    if (displayedRowCount === undefined || submittalList?.length === undefined)
      return;

    if (submittalListFilter && submittalListFilter.key) {
      const titleText = submittalLogFilterTitles[submittalListFilter.key];
      const statsContents = (
        <>
          <span>{`${displayedRowCount} Submittals`} </span>
          <span className="text-xs font-normal">({titleText})</span>
        </>
      );
      setStats(statsContents);
      setDisplayRowCount(displayedRowCount || 0);
      return;
    }
    if (gridRef.current?.api?.isAnyFilterPresent()) {
      setTimeout(() => {
        const rowCount = gridRef?.current?.api?.getDisplayedRowCount();
        setStats(`${rowCount} of ${submittalList?.length} Submittals`);
        setDisplayRowCount(rowCount || 0);
      }, 500);

      return;
    }
    setStats(`${submittalList?.length} Submittals`);
    setDisplayRowCount(submittalList?.length || 0);
  }, [submittalList?.length, submittalListFilter]);

  useEffect(() => {
    if (isGridReady && submittalList && projectDetails && savedColumns) {
      const statusesMap: any = {};

      Object.keys(SubmittalStatusToStrMap).forEach((keyStatusId: any) => {
        statusesMap[keyStatusId] = {
          name: SubmittalStatusToStrMap[keyStatusId]
        };
      });

      const gridDataState = {
        submittals: submittalList,
        statusesMap
        // typesMap
      };

      setGridData(gridDataState);

      updateStats();
    }
  }, [
    gridRef,
    isGridReady,
    projectDetails,
    updateStats,
    submittalList,
    filterState,
    savedColumns
  ]);

  useEffect(() => {
    if (submittalTypes) {
      const tmpTypesMap: any = {};
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.value] = type;
      });
      setTypesMap(tmpTypesMap);
    }
  }, [submittalTypes]);

  // useEffect(() => {
  //   gridRef.current?.api?.setQuickFilter(gridSearchtext);
  // }, [gridSearchtext]);

  const skippedColumns = useMemo(() => {
    const milsStoneColumnColIds = milestonesColumns.mileStoneColumns.map(
      (x) => x.colId
    );
    return [
      "planned_submittal_distributed_date",
      "planned_trade_partner_submitted_date",
      "planned_ext_review_completed_date",
      "submittal_created_by",
      "submittal_schedule_links",
      ...milsStoneColumnColIds
    ];
  }, [milestonesColumns.mileStoneColumns]);

  const getColumnStateFromGrid = () => {
    const colNameMap: any = {
      custom_spec_section: "spec_no",
      submittal_sequence_id: "submittal_id",
      linked_tasks: "linked_activities",
      status: "workflow_status"
    };

    const gridRefObj = gridRef?.current;

    const colFilters: any = gridRefObj?.api.getFilterModel();

    const columnState = gridRefObj?.columnApi
      ?.getColumnState()
      ?.map((column: any, index: number) => {
        if (skippedColumns.includes(column.colId)) return null;

        const colKey = column?.colId || "";
        const range: any = { ...colFilters[colKey]?.range } || null;

        if (range) {
          range.to = range.to ? `${range.to}` : null;
          range.from = range.from ? `${range.from}` : null;
        }

        const field = {
          name: colNameMap[colKey] || column.colId,
          filter: colFilters[colKey]?.values || null,
          sortOrder: column?.sort || null,
          range
        };
        return {
          order: index + 1,
          field,
          visibility: !column.hide
        };
      })
      .filter((c: any) => c);

    if (savedColumns?.submittals?.columnList) {
      const sortedColArr = savedColumns?.submittals?.columnList?.map(
        (col1: any) =>
          columnState?.find(
            (col2: any) => col2?.field.name === col1?.field.name
          )
      );
      return {
        columnList: sortedColArr,
        quickSearch: gridSearchtext
      };
    }
    return null;
  };
  const onSaveColumnState = async () => {
    console.log("onSaveColumnState ");
    const columnsStates = getColumnStateFromGrid();
    if (columnsStates) {
      try {
        const variables = {
          submittal_column_config: columnsStates,
          material_column_config: savedColumns?.materials
        };
        const updateResponse: any = await saveColumnsState({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
        } else {
          // message.success("Updated successfully");
        }
      } catch (ex) {
        console.log("An error occured");
      }
    }
  };

  const setFilterStateFromData = useCallback(
    (submittalColumns: TSaveColumnState) => {
      if (!submittalColumns) return;

      const filterstate: any = {};
      // setGridSearchtext(submittalColumns.quickSearch || "");
      if (submittalColumns.columnList?.length > 0) {
        const colNameMap: any = {
          spec_no: "custom_spec_section",
          submittal_id: "submittal_sequence_id",
          linked_activities: "linked_tasks",
          workflow_status: "status"
        };
        const columnState: any = gridRef?.current?.columnApi
          ?.getColumnState()
          ?.map((column: any) => {
            if (skippedColumns.includes(column.colId)) return null;
            const colKey = column?.colId || "";
            const col1 = submittalColumns.columnList?.find(
              (cols: any) =>
                colNameMap[cols.field.name] === colKey ||
                cols.field.name === colKey
            );

            const filter = col1?.field.filter;
            if (filter) {
              const range: any = { ...col1?.field?.range };
              if (range) {
                range.to = Number(range.to);
                range.from = Number(range.from);
              }
              filterstate[colKey] = {
                values: filter || null,
                filterType: "set",
                range
              };
            }

            const gridCol = { ...column };
            gridCol.hide = !col1?.visibility;
            gridCol.order = col1?.order;
            gridCol.sort = col1?.field.sortOrder;

            return gridCol;
          })
          .filter((c: any) => c);
        if (columnState) {
          const sortedArr = columnState?.sort(
            (a: any, b: any) => a.order - b.order
          );

          gridRef.current?.columnApi.applyColumnState({
            state: sortedArr,
            applyOrder: true
          });

          if (Object.keys(filterstate).length > 0) {
            setFilterState(filterstate);
            setTimeout(() => {
              gridRef?.current?.api?.setFilterModel(filterstate);
            }, 0);
          }
        }
      }
    },
    [skippedColumns]
  );

  useEffect(() => {
    if (isGridReady && savedColumns && savedColumns.submittals)
      setFilterStateFromData(savedColumns.submittals);
  }, [isGridReady, savedColumns, submittalListFilter, setFilterStateFromData]);

  const isGridCellEditable = (params: any) => {
    return (
      !params.context.isIntegrationMode && agGridCheckFieldEditable(params)
    );
  };

  const onEditCell = useCallback(
    (params: any) => {
      gridRef.current!.api.setFocusedCell(params.rowIndex, params.colKey);
      gridRef.current!.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colKey
      });
    },
    [gridRef]
  );

  const onSDBFloatChange = useCallback(
    async (event: any) => {
      if (!event.newValue) return;

      const oldData = { ...event.data };

      event.node.setData({
        ...oldData,
        float: event.newValue
      });

      const updateVars = {
        variables: {
          where: { submittal_id: { _in: [event.data.id] } },
          set: { float: event.newValue }
        }
      };
      const res = await updateSDB(updateVars);
      if (res.data) {
        message.success("Updated successfully");
        setTimeout(() => {
          refetchDataForPage();
        }, 3000);
      }
      if (res.errors) {
        event.node.setData({ ...oldData });
        message.error(res.errors[0].message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refetchDataForPage, updateSDB]
  );

  const onViewSpecSection = (data: any) => {
    setSpecFileModalData({
      file_key: data.spec_section_file_key,
      pageid: data.snippet_coordinates?.pageid,
      spec_no: data.spec_no,
      spec_name: data.spec_name
    });
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      field: "",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true
      }
      // cellEditorPopup: true
    };
  }, []);

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      // editable: true,
      filter: true,
      resizable: true,
      // autoHeight: true,
      menuTabs: ["filterMenuTab"],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: false
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        colId: "submittal_sequence_id",
        field: "submittal_sequence_id",
        headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        pinned: "left",
        lockPosition: "left",
        checkboxSelection: !isIntegrationMode,
        headerCheckboxSelection: !isIntegrationMode,
        headerCheckboxSelectionFilteredOnly: !isIntegrationMode,
        width: 150,
        minWidth: 80,
        maxWidth: 200,
        filter: false,
        sortable: true,
        // sort: "asc",
        menuTabs: [],
        editable: false,
        cellRenderer: IdLinkComponent,
        cellRendererParams: {
          projectId
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
          const valA = Number(valueA);
          const valB = Number(valueB);

          const compareIdOnly = () => {
            if (valA === valB) {
              return 0;
            }
            return valA > valB ? 1 : -1;
          };

          if (projectDetails?.spec_section) {
            const specNoA = nodeA.data.spec_no;
            const specNoB = nodeB.data.spec_no;

            if (specNoA && specNoB) {
              if (specNoA === specNoB) return compareIdOnly();
              const stringA = `${specNoA}${valueA}`;
              const stringB = `${specNoB}${valueB}`;
              return stringA > stringB ? 1 : -1;
            }

            if (!specNoA && !specNoB) return compareIdOnly();
            if (specNoB) return -1;
            return 1;
          }
          return compareIdOnly();
        },
        tooltipValueGetter: (params: any) => {
          const cellText =
            params.context.projectDetails?.spec_section && params.data.spec_no
              ? `${params.data.spec_no} - ${params.value}`
              : `${params.value}`;
          return cellText;
        },
        lockVisible: true
      },
      {
        colId: "submittal_number",
        field: "submittal_number",
        headerName: submittalHeaderMap?.submittal_number?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.submittal_number?.toUpperCase() || "",
        width: 120,
        minWidth: 80,
        tooltipField: "submittal_number",
        editable: isGridCellEditable,
        cellRenderer: CellValueRendererWithEditIcon,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        pinned: "left"
      },
      {
        colId: "title",
        field: "title",
        headerName: submittalHeaderMap?.title?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.title?.toUpperCase() || "",
        tooltipComponent: SubmittalTooltip,
        pinned: "left",
        filter: false,
        sortable: true,
        menuTabs: [],
        width: 140,
        minWidth: 140,
        maxWidth: 500,
        cellRenderer: submittalCellRenderer,
        cellRendererParams: {
          projectId
        },
        cellStyle: {
          overflow: "hidden",
          padding: 0
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        comparator(valueA: any, valueB: any, nodeA: any, nodeB: any) {
          const compareA = nodeA.data.title ?? nodeA.data.description;
          const compareB = nodeB.data.title ?? nodeB.data.description;
          return compareA.toLowerCase().localeCompare(compareB.toLowerCase());
        },
        editable: isGridCellEditable,
        getQuickFilterText: (params: any) => {
          try {
            return `${params.data.title}\n${params.data.description}`;
          } catch (error) {
            return "";
          }
        },
        tooltipValueGetter: (params: any) => {
          try {
            const tooltip = `${params.data.title} - ${params.data.description}`;
            return tooltip;
          } catch (error) {
            return "";
          }
        },
        lockVisible: true
      },
      {
        colId: "description",
        field: "description",
        headerName: "DESCRIPTION",
        headerTooltip: "Description",
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        filter: false,
        menuTabs: [],
        pinned: "left",
        lockPosition: "left"
      },
      {
        colId: "status",
        field: "workflow_status",
        headerName: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        width: 250,
        minWidth: 100,
        maxWidth: 450,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: SubmittalStatusCellRenderer,
        cellRendererParams: {
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        },
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        },
        // valueGetter: (params: any) => {
        //   return (
        //     getSubmittalStatus(params.data.history) || ESubmittalStatus.CREATE
        //   );
        // },
        keyCreator: (params: any) => {
          try {
            return params.context.statusesMap[params.value].name;
          } catch (error) {
            return "";
          }
        },
        getQuickFilterText: (params: any) => {
          try {
            return params.context.statusesMap[params.value].name;
          } catch (error) {
            return "";
          }
        },
        tooltipValueGetter: (params: any) => {
          return params.context.statusesMap[params.value].name;
        },
        lockVisible: true
      },
      {
        colId: "next_planned_deadline",
        field: "next_planned_deadline",
        sort: "asc",
        headerName:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: nextDeadlineCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
          columnData: {
            field: "next_planned_deadline",
            header:
              submittalHeaderMap?.next_planned_deadline?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        },
        valueGetter: ({ data }: any) =>
          data.next_planned_deadline
            ? DateUtils.format(data.next_planned_deadline)
            : "",
        tooltipValueGetter: (params: any) => params.value
      },
      {
        colId: "due_date",
        field: "due_date",
        headerName: submittalHeaderMap?.due_date?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.due_date?.toUpperCase() || "",
        headerComponentParams: submittalDueDateHeaderComponent,
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: dueDateCellRenderer,
        comparator: DateFilter.comparator,
        menuTabs: ["filterMenuTab"],
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.due_date?.toUpperCase() || "",
          columnData: {
            field: "due_date",
            header: submittalHeaderMap?.due_date?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        },
        valueGetter: ({ data }: any) =>
          data.due_date ? DateUtils.format(data.due_date) : "",
        tooltipValueGetter: (params: any) => params.value
      },
      {
        colId: "next_action",
        field: "next_action",
        headerName: submittalHeaderMap?.next_action?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.next_action?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "next_action",
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => "",
        hide: true
      },
      {
        colId: "float",
        field: "float",
        headerName: submittalHeaderMap?.float?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.float?.toUpperCase() || "",
        width: 130,
        minWidth: 30,
        maxWidth: 170,
        menuTabs: ["filterMenuTab"],
        editable: canChangeSubmittalDBFloat,
        cellEditorPopup: false,
        cellEditor: numberCellEditor,
        cellRenderer: editSubmittalFloatCellRenderer,
        tooltipField: "float",
        filter: true,
        cellClass: (params: any) => {
          return canChangeSubmittalDBFloat(params) ? "cell-editable" : "";
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "custom_spec_section",
        headerName: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 500,
        filter: "agSetColumnFilter",
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              return params.value.split("-")[0].trim();
            } catch {
              return params.value;
            }
          }
        },
        sortable: true,
        editable: isGridCellEditable,
        cellEditor: SpecSectionCellEditor,
        cellEditorParams: {
          specNoField: "spec_no",
          specNameField: "spec_name",
          dataMapVar: "specNoMap"
        },
        cellRenderer: specSectionRendererWithFileViewer,
        cellRendererParams: {
          specNoField: "spec_no",
          specNameField: "spec_name",
          projectId
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        valueGetter: (params: any) => {
          try {
            let specSectionStr = params.data.spec_no || "";
            if (params.data.spec_name) {
              specSectionStr += ` - ${params.data.spec_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        },
        comparator: (valueA: string | null, valueB: string | null) => {
          if (!valueA) return 1;
          if (!valueB) return -1;
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        tooltipValueGetter: ({ value }: any) => value
      },
      {
        colId: "revision",
        field: "revision",
        headerName: submittalHeaderMap?.revision?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.revision?.toUpperCase() || "",
        tooltipField: "revision",
        maxWidth: 120,
        lockVisible: true,
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "type",
        field: "type",
        headerName: submittalHeaderMap?.type?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.type?.toUpperCase() || "",
        width: 180,
        minWidth: 80,
        maxWidth: 250,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        cellEditor: SelectCellEditor,
        cellRenderer: (params: any) => {
          return isIntegrationMode
            ? params?.value
            : dropDownCellRendererWithCustomEdit(params);
        },
        cellRendererParams: {
          dataMapVar: "typesMap",
          valueVar: "id",
          labelVar: "value"
        },
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "typesMap",
          valueVar: "id",
          labelVar: "value",
          showSearch: true
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        keyCreator: (params: any) => params.value,
        getQuickFilterText: (params: any) => params.value,
        tooltipValueGetter: (params: any) => params.value,
        lockVisible: true
      },
      {
        colId: "state",
        field: "state",
        headerName: submittalHeaderMap?.state?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.state?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "state"
      },
      {
        colId: "assignee",
        // field: "submittal_assignee",
        headerName: submittalHeaderMap?.assignee?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.assignee?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: false,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellRenderer: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "assignee_user_id",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name",
            org: "assignee_org",
            unregistered_user: "assignee_unregistered",
            unregistered_user_org: "assignee_unregistered_org",
            statusKey: "assignee_status"
          }
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "gc_reviewer",
        field: "gc_reviewer_user_id",
        headerName: submittalHeaderMap?.gc_reviewer?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.gc_reviewer?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "gc_reviewer_user_id",
            firstName: "gc_reviewer_first_name",
            lastName: "gc_reviewer_last_name",
            org: "gc_reviewer_org",
            statusKey: "gc_reviewer_status"
          }
        },
        cellEditor: UserSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          usersVar: "gcReviewers",
          notFoundContent: (
            <SelectNotFoundContent
              notFoundMsg="User does not exist in the project. Go to Project Settings to add new user."
              onAddClick={() => {}}
              showAddBtn={false}
            />
          )
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "design_reviewer",
        field: "design_reviewer_user_id",
        headerName: submittalHeaderMap?.design_reviewer?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.design_reviewer?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "design_reviewer_user_id",
            firstName: "design_reviewer_first_name",
            lastName: "design_reviewer_last_name",
            org: "design_reviewer_org",
            unregistered_user: "design_reviewer_unregistered",
            unregistered_user_org: "design_reviewer_unregistered_org",
            statusKey: "design_reviewer_status"
          }
        },
        cellEditor: UserSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          usersVar: "designReviewers",
          notFoundContent: (
            <SelectNotFoundContent
              notFoundMsg="User does no exist in the project. Go to Project Settings to add new responsible contractor."
              onAddClick={() => {}}
              showAddBtn={false}
            />
          )
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "responsible_contractor",
        field: "responsible_contractor_id",
        headerName:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        // autoHeight: true,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = responsibleContractorCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellEditor: ResponsibleContractorCellEditor,
        cellEditorParams: {
          type: "regular-select",
          showSearch: true,
          projectId
        },
        valueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        },
        tooltipValueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        }
      },
      {
        colId: "submitter",
        field: "submitter_user_id",
        headerName: submittalHeaderMap?.submitter?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submitter?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "submitter_user_id",
            firstName: "submitter_first_name",
            lastName: "submitter_last_name",
            org: "submitter_org",
            unregistered_user: "submitter_unregistered",
            unregistered_user_org: "responsible_contractor_name",
            statusKey: "submitter_status"
          }
        },
        cellEditor: SubmitterSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          projectId,
          notFoundContent: (
            <SelectNotFoundContent
              notFoundMsg="User not found. Go to Project Settings to add users"
              onAddClick={() => {}}
              showAddBtn={false}
            />
          )
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "linked_tasks",
        field: "tasks",
        headerName: submittalHeaderMap?.linked_activities?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.linked_activities?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        width: 300,
        maxWidth: 500,
        cellRenderer: TaskLinksCellRenderer,
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "linked_materials",
        field: "materials",
        headerName: submittalHeaderMap?.linked_materials?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.linked_materials?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        width: 300,
        maxWidth: 500,
        cellRenderer: MaterialLinksCellRenderer,
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "submittal_schedule_links",
        field: "submittal_schedule_links",
        headerName: "Action",
        headerTooltip: "ACTION",
        pinned: "right",
        width: 80,
        filter: false,
        menuTabs: [],
        onCellClicked({ data }: any) {
          history.push(
            `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${data.id}`
          );
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: () => <LinkOutlined />,
        suppressColumnsToolPanel: true,
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "planned_submittal_distributed_date",
        field: "planned_submittal_distributed_date",
        headerName: "SUBMITTAL DISTRIBUTED DATE (PLANNED)",
        headerTooltip: "Submittal Distributed Date (Planned)",
        hide: true,
        suppressColumnsToolPanel: true,
        width: 150,
        minWidth: 70,
        maxWidth: 250,
        editable: false,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "SUBMITTAL DISTRIBUTED DATE (PLANNED)",
          columnData: {
            field: "planned_submittal_distributed_date",
            header: "SUBMITTAL DISTRIBUTED DATE (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "planned_trade_partner_submitted_date",
        field: "planned_trade_partner_submitted_date",
        headerName: "SUBMITTED BY SUBCONTRACTOR (PLANNED)",
        headerTooltip: "Submitted By SUBCONTRACTOR (Planned)",
        width: 150,
        minWidth: 70,
        maxWidth: 350,
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "SUBMITTED BY SUBCONTRACTOR (ACTUAL)",
          columnData: {
            field: "planned_trade_partner_submitted_date",
            header: "SUBMITTED BY SUBCONTRACTOR (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "planned_ext_review_completed_date",
        field: "planned_ext_review_completed_date",
        headerName: "EXTERNAL REVIEW COMPLETE (PLANNED)",
        headerTooltip: "External Review Complete (Planned)",
        width: 150,
        minWidth: 70,
        maxWidth: 300,
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "EXTERNAL REVIEW COMPLETE (PLANNED)",
          columnData: {
            field: "actual_ext_review_completed_date",
            header: "EXTERNAL REVIEW COMPLETE (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "submittal_created_by",
        headerName: "CREATED BY",
        headerTooltip: "CREATED BY",
        hide: true,
        suppressColumnsToolPanel: true,
        width: 150,
        minWidth: 70,
        maxWidth: 300,
        editable: false,
        valueGetter: ({ data }: any) => {
          try {
            return `${data.created_by_first_name} ${data.created_by_last_name}`;
          } catch (ex) {
            return "";
          }
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: submittalHeaderMap?.risk_level?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.risk_level?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "risk_level",
        cellRenderer: riskCellRenderer
      },
      {
        colId: "priority",
        field: "priority",
        headerName: submittalHeaderMap?.priority?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.priority?.toUpperCase() || "",
        width: 180,
        minWidth: 60,
        maxWidth: 200,
        tooltipField: "priority",
        editable: isGridCellEditable,
        cellRenderer: dropDownCellRendererWithCustomEdit,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "priorities",
          valueVar: "value",
          labelVar: "label",
          showSearch: false,
          isSorting: false
        },
        cellRendererParams: {
          dataMapVar: "priorities",
          valueVar: "value",
          labelVar: "label"
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "watchers",
        field: "watchers",
        headerName: submittalHeaderMap?.watchers?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.watchers?.toUpperCase() || "",
        width: 120,
        minWidth: 80,
        tooltipField: "watchers",
        suppressColumnsToolPanel: false,
        editable: isGridCellEditable,
        cellRenderer: CellValueRendererWithEditIcon,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        getQuickFilterText() {
          return "";
        }
      },
      {
        colId: "created_at",
        field: "created_at",
        headerName: submittalHeaderMap?.created_at?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.created_at?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.created_at?.toUpperCase() || "",
          columnData: {
            field: "created_at",
            header: submittalHeaderMap?.created_at?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText() {
          return "";
        },
        tooltipValueGetter: (params: any) => {
          return DateUtils.format(params.value);
        },
        tooltipField: "created_at",
        suppressColumnsToolPanel: true,
        hide: true
      },

      ...milestonesColumns.groupColDefMilestone,
      ...milestonesColumns.mileStoneColumns
    ];
  }, [
    history,
    isIntegrationMode,
    projectId,
    canChangeSubmittalDBFloat,
    projectDetails?.spec_section,
    submittalHeaderMap,
    milestonesColumns.groupColDefMilestone,
    milestonesColumns.mileStoneColumns
  ]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => {
      return params.data.id;
    };
  }, []);

  const resetFilters = () => {
    setGridSearchtext("");
    gridRef?.current?.api?.setFilterModel(null);
    clearFilterRef.current = true;
    setTimeout(() => {
      // To destroy all filters applied on chip
      clearFilterRef.current = false;
      onSaveColumnState();
    }, 2000);
  };

  const onFiltersApplied = (event: any) => {
    if (event.columns?.length === 0) {
      updateStats();
      return;
    }
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs.filter((x) => x.colId === key)[0];
          const { values, range } = filtersApplied[key];

          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values,
            range
          });
        }
      });

      setFilters(items);

      if (clearFilterRef.current === false && isFirstDataRendered === true) {
        if (!submittalListFilter) {
          onSaveColumnState();
        }
      }
    }

    updateStats();
  };

  const onCustomSpecSectionCellEdit = useCallback(
    async (event: any) => {
      if (!event.newValue.specIdValue) return;

      const specNo = event?.newValue?.specNoValue?.trim() || null;
      const specName = event?.newValue?.specNameValue?.trim() || null;

      const variables: any = {
        id: event.data.id,
        submittalValues: {
          spec_no: specNo,
          spec_name: specName,
          spec_section_id: event.newValue.specIdValue
        }
      };

      try {
        const updateResponse: any = await updateSubmittalById({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
        } else {
          message.success("Updated successfully");
          refetchDataForPage();
        }
      } catch (ex) {
        message.error({ content: "An error occured" });
      }
    },
    [refetchDataForPage, updateSubmittalById]
  );

  const cellEditRequest = useCallback(
    async (event: any) => {
      if (event.column.colId === "custom_spec_section") {
        onCustomSpecSectionCellEdit(event);
        return;
      }
      if (event.column.colId === "float") {
        onSDBFloatChange(event);
        return;
      }
      const skipNullFields = ["watchers"];

      if (
        !skipNullFields.some((x) => x === event.column.colId) &&
        !event.newValue
      )
        return;

      const existingRowData = { ...event.data };
      const changedData: any = {};

      const variables: any = {
        id: event.data.id,
        submittalValues: {}
      };

      switch (event.colDef.colId) {
        case "design_reviewer": {
          const designReviewer = getSubmittalUserById(
            event.newValue,
            projectParticipants.designReviewers
          );

          if (designReviewer.type === "actual") {
            variables.submittalValues.design_reviewer = event.newValue;
            variables.submittalValues.design_reviewer_unregistered = "";

            changedData.design_reviewer_unregistered = "";
            changedData.design_reviewer_unregistered_org = "";

            changedData.design_reviewer_user_id = event.newValue;
            changedData.design_reviewer_first_name = designReviewer.first_name;
            changedData.design_reviewer_last_name = designReviewer.last_name;
            changedData.design_reviewer_org = designReviewer.company.name;
          } else if (designReviewer.type === "poc") {
            variables.submittalValues.design_reviewer_unregistered =
              event.newValue;
            variables.submittalValues.design_reviewer_vendor_unregistered =
              designReviewer.company.name;
            variables.submittalValues.design_reviewer = null;

            changedData.design_reviewer_user_id = null;
            changedData.design_reviewer_unregistered = event.newValue;
            changedData.design_reviewer_unregistered_org =
              designReviewer.company.name;
          }
          break;
        }

        case "submitter": {
          const submitter = getSubmittalUserById(
            event.newValue,
            projectParticipants.submitterUsers
          );

          if (submitter.type === "actual") {
            variables.submittalValues.submitter = event.newValue;
            variables.submittalValues.submitter_unregistered = "";

            changedData.submitter_user_id = event.newValue;
            changedData.submitter_first_name = submitter.first_name;
            changedData.submitter_last_name = submitter.last_name;
            changedData.submitter_org = submitter.company?.name;
          } else if (submitter.type === "poc") {
            variables.submittalValues.submitter_unregistered = event.newValue;
            variables.submittalValues.submitter = null;

            changedData.submitter_user_id = null;
            changedData.submitter_unregistered = event.newValue;
          }
          break;
        }

        case "responsible_contractor": {
          variables.submittalValues.responsible_contractor = event.newValue;
          variables.submittalValues.submitter = null;
          variables.submittalValues.submitter_unregistered = "";

          const responsibleContractor = getSubmittalCompanyById(
            event.newValue,
            projectParticipants.responsibleContractors
          );
          changedData.responsible_contractor_id = event.newValue;
          changedData.responsible_contractor_name =
            responsibleContractor.subscription_vendor?.name;
          changedData.submitter_user_id = null;
          changedData.submitter_unregistered = "";
          break;
        }

        case "gc_reviewer": {
          variables.submittalValues.gc_reviewer = event.newValue;
          const gcReviewer = getSubmittalUserById(
            event.newValue,
            projectParticipants.gcReviewers
          );
          changedData.gc_reviewer_user_id = event.newValue;
          changedData.gc_reviewer_first_name = gcReviewer.first_name;
          changedData.gc_reviewer_last_name = gcReviewer.last_name;
          changedData.gc_reviewer_org = gcReviewer.company.name;
          break;
        }
        case "type": {
          const typeObj: any = Object.values(event.context.typesMap).find(
            (type: any) => type.id === event.newValue
          );

          if (typeObj) {
            changedData.type = typeObj?.value || "";
            variables.submittalValues.type = event.newValue;
            variables.submittalValues.raw_type = typeObj?.value || "";
          }
          break;
        }

        case "submittal_number":
          variables.submittalValues.submittal_number = event.newValue;
          changedData.submittal_number = event.newValue;
          break;

        default:
          variables.submittalValues[event.column.colId as any] = event.newValue;
          break;
      }

      event.node.setData({ ...existingRowData, ...changedData });

      try {
        const updateResponse: any = await updateSubmittalById({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
          event.node.setData(existingRowData);
        } else {
          message.success("Updated successfully");
          refetchDataForPage();
        }
      } catch (ex) {
        message.error({ content: "An error occured" });
        event.node.setData(existingRowData);
      }
    },
    [
      onCustomSpecSectionCellEdit,
      projectParticipants,
      updateSubmittalById,
      onSDBFloatChange,
      refetchDataForPage
    ]
  );

  const selectionChanged = (grid: any) => {
    setSelectedRows(grid.api.getSelectedRows());
  };

  const onSubmittalsEdit = (panel: "details" | "duration") => {
    if (panel === "details") {
      setShowSubmittalBulkEditDetails(true);
    }
    if (panel === "duration") {
      setShowSubmittalBulkEditDurations(true);
    }
  };

  const gridContext = {
    typesMap,
    statusesMap: gridData.statusesMap,
    specSections,
    tokenContents,
    projectDetails,
    projectParticipants,
    isIntegrationMode,
    onEditCell,
    onViewSpecSection,
    isCurrentUserGC,
    isDesignTabEnabled,
    submittalHeaderMap,
    priorities
  };

  useEffect(() => {
    if (gridData.submittals && gridData.submittals.length && typesMap) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: [
            "type",
            "design_reviewer",
            "assignee",
            "gc_reviewer",
            "design_reviewer",
            // "responsible_contractor",
            "submitter"
          ]
        });
      }, 0);
    }
  }, [gridData.submittals, typesMap]);

  const getFormattedValues = (params: any) => {
    const { value, column, node, context } = params;

    switch (true) {
      case column.colId === "submittal_sequence_id":
        try {
          const { data } = node;
          return context.projectDetails?.spec_section && data.spec_no
            ? `${data.spec_no} - ${value}`
            : `${value}`;
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "title":
        try {
          return node.data.title || "";
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "type" || column.colId === "status":
        if (column.colId === "type" && isIntegrationMode) {
          return value;
        }
        try {
          const dataMap = column.colDef.cellRendererParams.dataMapVar;
          const fieldLabel = column.colDef.cellRendererParams.labelVar;
          return context[dataMap][value][fieldLabel];
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "responsible_contractor":
        try {
          const { data } = node;
          return data.responsible_contractor_name;
        } catch (ex) {
          return "";
        }
        break;
      case column.colDef.cellRenderer === dateCellRenderer:
        try {
          return value ? DateUtils.format(value) : "";
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "assignee" ||
        column.colId === "gc_reviewer" ||
        column.colId === "design_reviewer" ||
        column.colId === "submitter":
        try {
          const { data } = node;
          const userData = userOrgCellRenderer({
            data,
            userInput: column.userProvidedColDef.cellRendererParams.userInput
          });
          if (!userData.fullName || !userData.fullName.trim()) return "";
          return `${userData.fullName} (${userData.userOrg})`;
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "linked_tasks":
        try {
          const tasks = value || [];

          const formattedItems = tasks.map((item: any) => {
            const name = item.activity_id
              ? `${item.activity_id} - ${item.task_name}`
              : item.task_name;
            return name;
          });

          return formattedItems.join("\n");
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "linked_materials":
        try {
          const { data } = node;
          const materials = value || [];

          if (materials.length === 1 && materials[0].implicit) {
            const submittalRefrenceId =
              projectDetails?.spec_section && data.spec_no
                ? `${data.spec_no} - ${data.submittal_sequence_id}`
                : data.submittal_sequence_id;

            return `Material for Submittal ${submittalRefrenceId}`;
          }

          const formattedItems = materials
            .filter((material: any) => !material.implict)
            .map((item: any) => {
              return item.name;
            });

          return formattedItems.join("\n");
        } catch (ex) {
          return "";
        }
        break;
      case column.colId === "next_planned_deadline":
        try {
          if (!params.value) return "";
          return DateUtils.format(params.value);
        } catch (ex) {
          return "";
        }
        break;
      default:
        return value ?? "";
    }
  };

  const getParams: () => CsvExportParams = () => ({
    columnKeys: gridRef.current?.columnApi
      ?.getAllGridColumns()
      ?.filter((col) => {
        const colId = col.getColId();
        if (colId === "description" || colId === "next_action") {
          return true;
        }
        if (colId === "submittal_schedule_links") {
          return false;
        }
        if (
          milestonesColumns.groupColDefMilestone.some((x) => x.colId === colId)
        ) {
          return false;
        }
        return col.isVisible() && !hiddenColumns?.includes(colId);
      })
      .map((col) => col.getColId()),
    processCellCallback(params: any): string {
      return getFormattedValues(params);
    },
    fileName: `Submittal log_${projectDetails?.name}.csv`
  });

  const exportLogAsCSV = () => {
    gridRef.current?.api?.exportDataAsCsv(getParams());
  };

  const onCellClick = (params: any) => {
    switch (params.colDef.colId) {
      case "revision":
        history.push(`${match.url}/${params.data.id}`);
        break;
      case "status":
        history.push(`${match.url}/${params.data.id}`);
        break;
      case "attachment":
        history.push(`${match.url}/${params.data.id}?tab=2`);
        break;
      default:
    }
  };

  const resetGridFilters = useCallback(() => {
    try {
      const currentFilters: any = gridRef.current?.api?.getFilterModel();
      const currentFilterCols = Object.keys(currentFilters);
      currentFilterCols.forEach((col: string) => {
        gridRef.current!.api.destroyFilter(col);
      });

      setTimeout(() => {
        setSubmittalListFilter(null);
      });
    } catch (ex) {
      //
    }
  }, [setSubmittalListFilter]);

  const showSubmittalList = useMemo(() => {
    if (projectDetails) {
      if (isIntegrationMode) {
        if (projectIntegrationQueryData) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }, [projectDetails, isIntegrationMode, projectIntegrationQueryData]);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true
          }
        }
      ]
    };
  }, []);

  const specFileModalHeader = () => {
    return (
      <div className="w-full grid grid-cols-3 gap-x-6">
        <div className="col-span-2 truncate">
          {specFileModalData?.spec_no && (
            <span>{specFileModalData?.spec_no} -</span>
          )}{" "}
          {specFileModalData?.spec_name || ""}
        </div>
      </div>
    );
  };

  const openNotification = (
    placement: NotificationPlacement,
    id: string,
    title: string
  ) => {
    api.open({
      message: (
        <div className="notifications space-x-1">
          <span className="notification-highlight-title ">{title}</span>
          <span className="notification-title">successfully created.</span>
        </div>
      ),
      description: (
        <div className="notifications space-x-1">
          <span className="notification-subTitle">Click</span>
          <a
            href={`/project/${projectId}/submittals/${id}`}
            className="underline notification-highlight-subTitle "
          >
            here
          </a>
          <span className="notification-subTitle">to view details.</span>
        </div>
      ),
      placement
    });
  };

  const currentSourceType = useMemo(() => {
    if (!projectIntegrationConfig?.length) return "";

    return projectIntegrationConfig[0]?.integration?.system;
  }, [projectIntegrationConfig]);

  return (
    <>
      {contextHolder}
      {showSubmittalList ? (
        <>
          <div className="submittal-list-page">
            <SubmittalListFilterComponent
              gridRef={gridRef}
              items={filters}
              customDateFilter={customDateFilter}
              setCustomDateFilter={setCustomDateFilter}
              setItems={setFilters}
              onCreateLogClick={() => {
                setshowNewLogDrawer(true);
              }}
              onImportLogClick={() => {
                if (!submittalList) return;
                existingSubmittalsCountRef.current = submittalList;
                setshowImportLogDrawer(true);
              }}
              onSearch={(text: string) => {
                gridRef.current?.api?.setQuickFilter(text);
              }}
              stats={stats}
              showFiterChips={!submittalListFilter}
              onAllSubmittalsClick={resetGridFilters}
              selectedRow={selectedRows}
              onExportCSV={exportLogAsCSV}
              onSubmittalEditClick={onSubmittalsEdit}
              isIntegrationMode={isIntegrationMode}
              displayRowCount={displayRowCount}
              isIntegrationConfigured={isIntegrationConfigured}
              resetFilters={resetFilters}
              getColumnStateFromGrid={getColumnStateFromGrid}
              setFilterStateFromData={setFilterStateFromData}
              isGridDataRendered={!submittalListData?.length}
              onSubmittalDeleted={() => {
                refetchDataForPage();
              }}
            />
          </div>
          <div className="submittal-listing-grid grow ag-theme-alpine px-2 relative">
            <GridStatusMessage
              {...{
                isIntegrationMode,
                isIntegrationConfigured,
                submittalListLoading,
                submittalListData,
                submittalListLoadedOnce,
                projectId
              }}
            />
            <ErrorBoundary>
              <AgGridReact<SubmittalLog>
                tooltipMouseTrack
                ref={gridRef}
                rowData={gridData.submittals}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                headerHeight={36}
                rowSelection="multiple"
                groupSelectsChildren
                suppressRowClickSelection
                suppressAggFuncInHeader
                suppressDragLeaveHidesColumns
                maintainColumnOrder
                readOnlyEdit
                animateRows={false}
                debounceVerticalScrollbar
                sideBar={sideBar}
                onSelectionChanged={selectionChanged}
                getRowId={getRowId}
                onCellEditRequest={cellEditRequest}
                tooltipShowDelay={700}
                tooltipHideDelay={2000}
                onFilterChanged={onFiltersApplied}
                onSortChanged={onFiltersApplied}
                onGridReady={() => {
                  setGridReady(true);
                }}
                rowHeight={62}
                suppressLoadingOverlay
                context={gridContext}
                suppressContextMenu
                enableCellTextSelection
                singleClickEdit
                stopEditingWhenCellsLoseFocus
                onCellClicked={onCellClick}
                suppressNoRowsOverlay
                onFirstDataRendered={onFirstDataRendered}
                onColumnVisible={(e) => {
                  const hiddenCols =
                    gridRef.current?.columnApi
                      .getColumns()
                      ?.filter((column: any) => !column.visible)
                      .map((col: any) => col.colId) || [];

                  setHiddenColumns([...hiddenCols, "submittal_schedule_links"]);

                  if (e.columnApi && e.source === "toolPanelUi") {
                    onSaveColumnState();
                    if (e.column) {
                      milestonesColumns.onColumnVisible(
                        e,
                        maxNumberOfMilestone
                      );
                    } else {
                      milestonesColumns.updateAllMileStoneInitialVisibility(
                        e.columnApi,
                        maxNumberOfMilestone
                      );
                    }
                  }
                }}
                onDragStopped={(e) => {
                  const classNames = [
                    "ag-header-cell-resize",
                    "ag-tool-panel-horizontal-resize"
                  ];
                  if (e.columnApi && !classNames.includes(e.target.className)) {
                    onSaveColumnState();
                  }
                }}
                suppressClickEdit
                cacheQuickFilter
              />
            </ErrorBoundary>
          </div>
        </>
      ) : (
        <div className="h-[80vh] flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
      {showNewLogDrawer && (
        <SubmittalLogCreateComponent
          onCancelClick={() => {
            setshowNewLogDrawer(false);
          }}
          onSubmittalCreated={(id: string, title: string) => {
            setshowNewLogDrawer(false);
            openNotification("top", id, title);
            refetchDataForPage();
          }}
          gqlClient={gqlClientForProject}
          showCreateSubModel={showNewLogDrawer}
          setDrawerOpen={setshowNewLogDrawer}
        />
      )}
      {showImportLogDrawer &&
        (isIntegrationMode ? (
          <ImportACCSubmittalLogs
            tokenRetrievalState={tokenRetrievalState}
            gqlClientForProject={gqlClientForProject}
            showImportLogDrawer={showImportLogDrawer}
            setDrawerOpen={setshowImportLogDrawer}
            onCancel={() => {
              setshowImportLogDrawer(false);
              refetchDataForPage();
            }}
            currentSourceType={currentSourceType}
          />
        ) : (
          <ImportSubmittalLogs
            submittalHeaderMap={submittalHeaderMap}
            tokenRetrievalState={tokenRetrievalState}
            gqlClientForProject={gqlClientForProject}
            existingSubmittalsCount={existingSubmittalsCountRef.current}
            showImportLogDrawer={showImportLogDrawer}
            setDrawerOpen={setshowImportLogDrawer}
            onCancel={() => {
              setshowImportLogDrawer(false);
              refetchDataForPage();
            }}
          />
        ))}

      {showSubmittalBulkEditDetails && (
        <BulkEditSubmittalDetails
          onCancelClick={() => {
            setShowSubmittalBulkEditDetails(false);
          }}
          onUpdateDone={() => {
            setShowSubmittalBulkEditDetails(false);
            gridRef.current!.api.deselectAll();
            setTimeout(() => {
              refetchDataForPage();
            }, 1000);
          }}
          selectedRows={selectedRows}
          gqlClient={gqlClientForProject}
          setShowSubmittalEdit={setShowSubmittalBulkEditDetails}
          showSubmittalEdit={showSubmittalBulkEditDetails}
          projectId={projectId}
        />
      )}

      {showSubmittalBulkEditDurations && (
        <ErrorBoundary
          onError={() => {
            setShowSubmittalBulkEditDurations(false);
          }}
        >
          <BulkEditSubmittalDurations
            setShowEdit={setShowSubmittalBulkEditDurations}
            showEdit={showSubmittalBulkEditDurations}
            selectedRows={selectedRows}
            submittals={gridData.submittals}
          />
        </ErrorBoundary>
      )}

      {specFileModalData?.file_key && (
        <FileViewerModal
          fileKey={specFileModalData?.file_key}
          pageNumber={specFileModalData?.pageid || 0}
          showModal={!!specFileModalData?.file_key}
          hideModal={() => {
            setSpecFileModalData(null);
          }}
          headerComponent={specFileModalHeader()}
        />
      )}
    </>
  );
}

export default withRouter(SubmittalList);
