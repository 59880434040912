import "./style.scss";
import {
  TEntityMilestone,
  TFeatureWorkflowInstance,
  TFetureDetailUpdateAction
} from "entity-app/models";
import RenamePopup, {
  InputEditDataType
} from "popups/rename-model/rename-popup";
import { useState } from "react";
import TimelineDurations from "./components/one-timeline-durations";
import { TWidthCalculation } from "./models";
import TimelineIconsOnly from "./components/two-timeline-icons";
import TimelineMilestones from "./components/three-timeline-milestones";

function EntityDateblock(props: {
  workflowInstanceMilestones: Array<TEntityMilestone>;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  actions: TFetureDetailUpdateAction;
  showFloat: boolean;
  disabled?: boolean;
}) {
  const {
    workflowInstanceMilestones,
    featureWorkflowInstance,
    actions,
    showFloat = true,
    disabled = false
  } = props;
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameDataInput, setRenameDataInput] = useState<InputEditDataType>({
    value: "",
    id: ""
  });

  if (!workflowInstanceMilestones || !featureWorkflowInstance) return null;

  // Sort by sequence_no
  const milestones = workflowInstanceMilestones.sort(
    (a, b) => a.sequence_no - b.sequence_no
  );

  const { length: offsetCount } = milestones.filter(
    (m) => m.feature_workflow_instance_offsets_as_src.length
  );

  const blockMargin = 24;
  const milestonesWidth = 144;
  const leftpaddingIconTimeline = 65;
  const timelineWidth = milestonesWidth + blockMargin;
  const totalDateBlockWidth = showFloat
    ? (offsetCount + 1.7) * timelineWidth
    : (offsetCount + 1) * timelineWidth;

  const widthCal = {
    blockMargin,
    milestonesWidth,
    leftpaddingIconTimeline,
    timelineWidth
  } as TWidthCalculation;

  const editOffsetDateBlock = async (newOffset: string, id: string) => {
    const set = {} as any;
    if (renameDataInput.identityName === "WorkflowInstanceOffset") {
      set.duration = newOffset;
      actions.updateFeatureWorkflowInstanceOffset({
        id,
        set,
        workflowInstanceId: featureWorkflowInstance.id
      });
    }

    if (renameDataInput.identityName === "WorkflowInstanceFloat") {
      set.float = newOffset;
      set.template_value_overidden = true; // For override the template value in BE
      actions.updateFeatureWorkflowInstance({ id, set });
    }
  };

  return (
    <div
      style={{ width: totalDateBlockWidth }}
      className="flex flex-col shrink-0 h-[394px] justify-center entity-date-block px-5"
    >
      <TimelineDurations
        disabled={disabled}
        milestones={milestones}
        widthCal={widthCal}
        featureWorkflowInstance={featureWorkflowInstance}
        setIsRenameModalOpen={setIsRenameModalOpen}
        setRenameDataInput={setRenameDataInput}
        showFloat={showFloat}
      />
      <TimelineIconsOnly
        disabled={disabled}
        actions={actions}
        milestones={milestones}
        widthCal={widthCal}
        featureWorkflowInstance={featureWorkflowInstance}
        showFloat={showFloat}
      />
      <TimelineMilestones
        disabled={disabled}
        actions={actions}
        milestones={milestones}
        widthCal={widthCal}
      />

      {isRenameModalOpen && (
        <RenamePopup
          isModalOpen={isRenameModalOpen}
          setIsModalOpen={setIsRenameModalOpen}
          inputData={renameDataInput}
          onOk={editOffsetDateBlock}
          title="Edit duration"
          okName="Save"
        />
      )}
    </div>
  );
}
export default EntityDateblock;
